import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Input, Button, message, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './listaDevoluciones.css';
import moment from 'moment';
import { Html5Qrcode } from 'html5-qrcode';

const ListaDevoluciones = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [enlistmentData, setEnlistmentData] = useState([]);
  const [isScanningSKU, setIsScanningSKU] = useState(false);
  const [scannedNumber, setScannedNumber] = useState('');
  const [barcodeData, setBarcodeData] = useState(null);
  const [expectedBarcode, setExpectedBarcode] = useState('');
  const [scannedBarcodes, setScannedBarcodes] = useState(new Map()); // Almacenará {código_barras => {preenvio, id_orden}}
  const [currentItem, setCurrentItem] = useState(null); // Nuevo estado para el item actual
  const [isDevolucionScanning, setIsDevolucionScanning] = useState(false);
  const [isDevolucionProcessModalVisible, setIsDevolucionProcessModalVisible] = useState(false);
  const [devolucionData, setDevolucionData] = useState({
    id_enlistment: null,
    codigos_barra: [],
    motivo_devolucion: '',
    observaciones: '',
    sumar_cantidad: 1,
    adjunto1: null,
    adjunto2: null
  });
  const usuario_id = localStorage.getItem('usuario_id');
  const [scannedBarcodesArray, setScannedBarcodesArray] = useState([]);

  const isMobile = window.innerWidth <= 768;

  const fetchEnlistmentData = async () => {
    try {
      const response = await axios.get(`https://99envios.app/api/picking/devolucion/listar-devoluciones-enlistment/${usuario_id}`);
      const formattedData = response.data.map((item) => {
        if (item.origen_externo === 0) {
          // Para órdenes internas
          return item.orden.detalles.map((detalle) => {
            const inventarioBodega = detalle.producto.inventarios_bodega.find(
              inv => inv.id_sucursal_bodega === item.id_sucursal_bodega
            );
            
            return {
              key: `${item.id}-${detalle.id}`,
              id: item.id,
              id_orden: detalle.id_orden,
              id_sucursal_bodega: item.id_sucursal_bodega,
              numero_preenvio: item.numero_preenvio,
              nombre_producto: detalle.nombre_producto,
              id_producto: detalle.id_producto,
              codigo_barras: inventarioBodega?.codigo_barras || null,
              id_variacion: detalle.id_variacion,
              variacion_data: null,
              cantidad: detalle.cantidad,
              tipo_enlistment: item.tipo_enlistment,
              estado_enlistment: item.estado_enlistment,
              created_at: item.created_at,
              updated_at: item.updated_at,
              origen_externo: item.origen_externo,
              transportadora: null,
              nombre_destinatario: null,
              direccion_destinatario: null,
              telefonos_destinatario: null,
              observaciones: null
            };
          });
        } else {
          // Para órdenes externas
          return item.orden_externa.detalles.map((detalle) => {
            const inventarioBodega = detalle.producto_externo.inventario.inventarios_bodega.find(
              inv => inv.id_sucursal_bodega === item.id_sucursal_bodega
            );
  
            return {
              key: `${item.id}-${detalle.id}`,
              id: item.id,
              id_orden: `Externa: ${item.id_orden_externa}`,
              id_sucursal_bodega: item.id_sucursal_bodega,
              numero_preenvio: item.numero_preenvio,
              nombre_producto: detalle.producto_externo.inventario.nombre_producto,
              id_producto: detalle.producto_externo.id_producto,
              codigo_barras: inventarioBodega?.codigo_barras || null,
              id_variacion: detalle.id_variacion_externa,
              variacion_data: detalle.variacion_externa?.variacion || null,
              cantidad: detalle.cantidad,
              tipo_enlistment: item.tipo_enlistment,
              estado_enlistment: item.estado_enlistment,
              created_at: item.created_at,
              updated_at: item.updated_at,
              origen_externo: item.origen_externo,
              transportadora: item.orden_externa.transportadora,
              nombre_destinatario: item.orden_externa.nombre_destinatario,
              direccion_destinatario: item.orden_externa.direccion_destinatario,
              telefonos_destinatario: item.orden_externa.telefonos_destinatario,
              observaciones: item.orden_externa.observaciones
            };
          });
        }
      }).flat();
      setEnlistmentData(formattedData);
    } catch (error) {
      console.error('Error fetching enlistment data:', error);
      message.error('Error al cargar los datos');
    }
  };

  useEffect(() => {
    fetchEnlistmentData();
  }, [usuario_id]);

  useEffect(() => {
    const filtered = enlistmentData.filter(item =>
      Object.values(item).some(value =>
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchText, enlistmentData]);

  const handleScanSKU = async (codigo_barras, item) => {
    if (!item) {
      message.error('Item no encontrado');
      return;
    }

    // Si hay un código de barras de variación, usamos ese en lugar del código principal
    const codigoAEscanear = item.codigo_barras_variacion || codigo_barras;
    
    setCurrentItem(item);
    setExpectedBarcode(codigoAEscanear);
    setIsScanningSKU(true);
  };

  const startScanningSKU = () => {
    const html5QrCode = new Html5Qrcode("qr-reader-sku");
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    html5QrCode.start(
      { facingMode: "environment" }, 
      config,
      (decodedText) => {
        html5QrCode.stop().then(() => {
          setScannedNumber(decodedText);
          setIsScanningSKU(false);
          sendScannedBarcode(decodedText);
        }).catch((err) => {
          console.error('Error deteniendo el escaneo:', err);
          message.error('Error al detener el escaneo');
        });
      },
      (errorMessage) => {
        console.warn('Error en el escaneo:', errorMessage);
      }
    ).catch((err) => {
      console.error('Error inicializando el escaneo:', err);
      message.error('Error al iniciar el escaneo');
    });
  };

  const sendScannedBarcode = async (scannedBarcode) => {
    try {
      if (scannedBarcode !== expectedBarcode) {
        message.error('Por favor escanee el producto correcto');
        return;
      }

      // Usar el código que se está escaneando para la clave
      const uniqueKey = createBarcodeKey(scannedBarcode, currentItem.numero_preenvio, currentItem.id_orden);

      setScannedBarcodesArray(prev => {
        const existingBarcodeIndex = prev.findIndex(item => 
          createBarcodeKey(item.barcode, item.preenvio, item.id_orden) === uniqueKey
        );

        if (existingBarcodeIndex === -1) {
          message.success('Código de barras escaneado correctamente');
          setIsScanningSKU(false);
          setScannedBarcodes(prevMap => new Map(prevMap).set(uniqueKey, true));
          return [...prev, {
            barcode: scannedBarcode, // Usar el código escaneado en lugar del principal
            preenvio: currentItem.numero_preenvio,
            id_orden: currentItem.id_orden
          }];
        }
        message.warning('Este código ya ha sido escaneado para este ítem');
        return prev;
      });

      setBarcodeData({
        id: currentItem.id,
        id_sucursal_bodega: currentItem.id_sucursal_bodega,
        id_producto: currentItem.id_producto,
        nombre_producto: currentItem.nombre_producto,
        codigo_barras: currentItem.codigo_barras,
        created_at: currentItem.created_at,
        updated_at: currentItem.updated_at
      });

    } catch (error) {
      message.error('Error al procesar el código de barras');
      console.error('Error:', error);
    }
  };

  // Agregar una función helper para crear una clave única
  const createBarcodeKey = (codigo_barras, numero_preenvio, id_orden) => {
    return `${codigo_barras}-${numero_preenvio}-${id_orden}`;
  };

  // Modificar la función que verifica si un código está escaneado
  const isCodeScannedForPreenvio = (codigo_barras, numero_preenvio, id_orden) => {
    const uniqueKey = createBarcodeKey(codigo_barras, numero_preenvio, id_orden);
    return scannedBarcodes.get(uniqueKey) !== undefined;
  };

  // Modificar la función que verifica todos los códigos de un preenvío
  const areAllBarcodesScannedForPreenvio = (numero_preenvio, id) => {
    const itemsWithPreenvio = enlistmentData.filter(item => 
      item.numero_preenvio === numero_preenvio && 
      item.id === id
    );
  
    return itemsWithPreenvio.every(item => {
      const codigoAVerificar = item.codigo_barras_variacion || item.codigo_barras;
      return isCodeScannedForPreenvio(codigoAVerificar, item.numero_preenvio, item.id_orden);
    });
  };
  

  // Modificar la función que maneja la aceptación del escaneo
  const handleAcceptBarcodeScan = () => {
    if (barcodeData && expectedBarcode && currentItem) {
      const barcodeKey = createBarcodeKey(
        expectedBarcode, 
        currentItem.numero_preenvio, 
        currentItem.id_orden
      );
      setScannedBarcodes(prev => new Map(prev).set(barcodeKey, true));
      setBarcodeData(null);
      setIsScanningSKU(false);
      setCurrentItem(null);
      message.success('Código de barras registrado exitosamente');
    }
  };

  const handleDevolucionScan = async () => {
    setIsDevolucionScanning(true);
  };

  const startDevolucionScanning = () => {
    const html5QrCode = new Html5Qrcode("qr-reader-devolucion");
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    html5QrCode.start(
      { facingMode: "environment" },
      config,
      (decodedText) => {
        html5QrCode.stop().then(() => {
          setIsDevolucionScanning(false);
          sendDevolucionScannedNumber(decodedText);
        }).catch((err) => {
          console.error('Error deteniendo el escaneo:', err);
          message.error('Error al detener el escaneo');
        });
      },
      (errorMessage) => {
        console.warn('Error en el escaneo:', errorMessage);
      }
    ).catch((err) => {
      console.error('Error inicializando el escaneo:', err);
      message.error('Error al iniciar el escaneo');
    });
  };

  const sendDevolucionScannedNumber = async (number) => {
    try {
      await axios.post(`https://99envios.app/api/picking/devolucion/devolucion-tipo-enlistment/${usuario_id}`, {
        numero_preenvio: parseInt(number, 10)
      });
      message.success('Devolución registrada exitosamente');
      fetchEnlistmentData();
    } catch (error) {
      message.error('Error al registrar la devolución');
      console.error('Error:', error);
    }
  };

  const handleOpenDevolucionProcessModal = (id_enlistment) => {
    const enlistmentItems = enlistmentData.filter(item => item.id === id_enlistment);
    
    // Obtener todos los códigos de barras principales de los items relacionados
    const relevantBarcodes = enlistmentItems.map(item => item.codigo_barras);

    setDevolucionData(prev => ({
      ...prev,
      id_enlistment: id_enlistment,
      codigos_barra: relevantBarcodes
    }));
    setIsDevolucionProcessModalVisible(true);
  };

  const handleDevolucionProcessChange = (field, value) => {
    setDevolucionData(prev => ({
      ...prev,
      [field]: value,
      sumar_cantidad: ['No recibido', 'Cliente no encontrado', 'Cancelacion pedido'].includes(value) ? 1 : 0
    }));
  };

  const handleImageChange = (field, event) => {
    const file = event.target.files[0];
    setDevolucionData(prev => ({
      ...prev,
      [field]: file
    }));
  };

  const adjunto1Ref = useRef(null);
  const adjunto2Ref = useRef(null);

  const resetModalData = () => {
    setBarcodeData(null);
    setScannedNumber('');
    setExpectedBarcode('');
    setCurrentItem(null);
    setDevolucionData({
      id_enlistment: null,
      codigos_barra: [],
      motivo_devolucion: '',
      observaciones: '',
      sumar_cantidad: 1,
      adjunto1: null,
      adjunto2: null
    });
    if (adjunto1Ref.current) adjunto1Ref.current.value = '';
    if (adjunto2Ref.current) adjunto2Ref.current.value = '';
    setScannedBarcodesArray([]); // Reset the scanned barcodes array
  };

  const handleDevolucionProcessSubmit = async () => {
    if (!devolucionData.motivo_devolucion || !devolucionData.observaciones) {
      message.error('Todos los campos son obligatorios, excepto los adjuntos');
      return;
    }

    const formData = new FormData();
    formData.append('id_enlistment', devolucionData.id_enlistment);
    
    // Simplify to only send barcodes
    devolucionData.codigos_barra.forEach((barcode, index) => {
      formData.append(`codigos_barra[${index}]`, barcode);
    });

    formData.append('motivo_devolucion', devolucionData.motivo_devolucion);
    formData.append('observaciones', devolucionData.observaciones);
    formData.append('sumar_cantidad', devolucionData.sumar_cantidad);

    if (devolucionData.adjunto1) {
      formData.append('adjunto1', devolucionData.adjunto1);
    }
    if (devolucionData.adjunto2) {
      formData.append('adjunto2', devolucionData.adjunto2);
    }

    try {
      await axios.post(`https://99envios.app/api/picking/devolucion/aplicar-devolucion/${usuario_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      message.success('Devolución realizada exitosamente');
      setIsDevolucionProcessModalVisible(false);
      resetModalData();
      fetchEnlistmentData();
    } catch (error) {
      message.error('Error al realizar la devolución');
      console.error('Error:', error);
    }
  };

  // Modificar la sección del render en columnsEnlistment
  const columnsEnlistment = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Orden',
      dataIndex: 'id_orden',
      key: 'id_orden',
    },
    {
      title: 'Nombre del Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Número Preenvío',
      dataIndex: 'numero_preenvio',
      key: 'numero_preenvio',
    },
    {
      title: 'Código de Barras',
      dataIndex: 'codigo_barras',
      key: 'codigo_barras',
    },
    {
      title: 'ID Producto',
      dataIndex: 'id_producto',
      key: 'id_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion_data',
      key: 'has_variation',
      render: (variacion_data, record) => {
        if (record.origen_externo === 1) {
          return variacion_data ? `Talla: ${variacion_data.talla}, Color: ${variacion_data.color}` : 'Sin variación';
        } else {
          return record.id_variacion ? 'Con variación' : 'Sin variación';
        }
      },
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Tipo Enlistamiento',
      dataIndex: 'tipo_enlistment',
      key: 'tipo_enlistment',
      render: (value) => {
        switch (value) {
          case 1:
            return 'Pre Alistamiento';
          case 2:
            return 'Alistamiento';
          case 3:
            return 'Empaque';
          case 4:
            return 'Despacho';
          case 5:
            return 'Salida';
          case 6:
            return 'Devolución';
          case 7:
            return 'Devolución Aplicada';
          default:
            return value;
        }
      },
    },
    {
      title: 'Estado Enlistamiento',
      dataIndex: 'estado_enlistment',
      key: 'estado_enlistment',
      render: (value) => {
        switch (value) {
          case 0:
            return 'En Proceso';
          case 1:
            return 'Completado';
          default:
            return value;
        }
      },
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Fecha de Actualización',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record, index) => {
        const isFirstItem = enlistmentData.findIndex(item => item.numero_preenvio === record.numero_preenvio && item.id === record.id) === index;

        // Si tiene código de variación
        if (record.codigo_barras_variacion) {
          return !isCodeScannedForPreenvio(record.codigo_barras_variacion, record.numero_preenvio, record.id_orden) ? (
            <Button 
              onClick={() => handleScanSKU(record.codigo_barras_variacion, record)} 
              style={{ width: '100%' }}
            >
              Escanear Código de Barras Variación
            </Button>
          ) : isFirstItem && areAllBarcodesScannedForPreenvio(record.numero_preenvio, record.id) ? (
            <Button 
              type="primary"
              onClick={() => handleOpenDevolucionProcessModal(record.id)}
              style={{ marginLeft: '8px', width: '100%' }}
            >
              Realizar Proceso de Devolución
            </Button>
          ) : null;
        }

        // Si no tiene código de variación
        return !isCodeScannedForPreenvio(record.codigo_barras, record.numero_preenvio, record.id_orden) ? (
          <Button 
            onClick={() => handleScanSKU(record.codigo_barras, record)} 
            style={{ width: '100%' }}
          >
            Escanear Código de Barras
          </Button>
        ) : isFirstItem && areAllBarcodesScannedForPreenvio(record.numero_preenvio, record.id) ? (
          <Button 
            type="primary"
            onClick={() => handleOpenDevolucionProcessModal(record.id)}
            style={{ marginLeft: '8px', width: '100%' }}
          >
            Realizar Proceso de Devolución
          </Button>
        ) : null;
      },
    }
  ];

  return (
    <>
      <NavbarUsuarioBodega title="Lista Devoluciones" />
      <div className="misPedidos-e">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <div>
            <Button 
              type="primary"
              onClick={() => handleDevolucionScan()}
              style={{ width: '300px', height: '40px', fontSize: '16px' }}
            >
              Escanear Preenvío Devolución
            </Button>
          </div>
        </div>
        <Input
          placeholder="Buscar"
          prefix={<SearchOutlined />}
          style={{ width: 200, marginBottom: '16px' }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="misPedidos-tabla" style={{ overflowX: 'auto', marginTop: '16px' }}>
          <h2>Enlistamientos</h2>
          {isMobile ? (
            filteredData.reduce((acc, item) => {
              const existingGroup = acc.find(group => group.numero_preenvio === item.numero_preenvio);
              if (existingGroup) {
                existingGroup.items.push(item);
              } else {
                acc.push({ numero_preenvio: item.numero_preenvio, items: [item] });
              }
              return acc;
            }, []).map((group, index) => (
              <div key={index} className="card-pedidos-e">
                <div className="card-header-pedidos-e">
                  <strong>Número Preenvío:</strong> <span>{group.numero_preenvio}</span>
                </div>
                {group.items.map((item, itemIndex) => (
                  <div key={item.key} className="subcard-pedidos-e">
                    <div className="card-item-pedidos-e" style={{ textAlign: 'center' }}><strong><span>{item.nombre_producto}</span></strong></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Código de Barras:</strong> <span>{item.codigo_barras}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>ID Orden:</strong> <span>{item.id_orden}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>ID Producto:</strong> <span>{item.id_producto}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Cantidad:</strong> <span>{item.cantidad}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Variación:</strong> <span>{item.origen_externo === 1 
          ? (item.variacion_data 
              ? `Talla: ${item.variacion_data.talla}, Color: ${item.variacion_data.color}`
              : 'Sin variación')
          : (item.id_variacion ? 'Con variación' : 'Sin variación')}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Tipo Enlistamiento:</strong> <span>{
                      (() => {
                        switch (item.tipo_enlistment) {
                          case 1:
                            return 'Pre Alistamiento';
                          case 2:
                            return 'Alistamiento';
                          case 3:
                            return 'Empaque';
                          case 4:
                            return 'Despacho';
                          case 6:
                            return 'Proceso Devolución';
                          default:
                            return item.tipo_enlistment;
                        }
                      })()
                    }</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Estado Enlistamiento:</strong> <span>{
                      (() => {
                        switch (item.estado_enlistment) {
                          case 0:
                            return 'En Proceso';
                          case 1:
                            return 'Completado';
                          default:
                            return item.estado_enlistment;
                          }
                        })()
                      }</span></div>
                      <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Fecha de Creación:</strong> <span>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</span></div>
                      <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Fecha de Actualización:</strong> <span>{moment(item.updated_at).format('DD/MM/YYYY HH:mm')}</span></div>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                        {!isCodeScannedForPreenvio(item.codigo_barras, item.numero_preenvio, item.id_orden) && (
                          <Button onClick={() => handleScanSKU(item.codigo_barras, item)} style={{ width: '100%' }}>
                            Escanear Código de Barras
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                    {areAllBarcodesScannedForPreenvio(group.numero_preenvio, group.items[0].id) && (
                      <Button 
                        type="primary"
                        onClick={() => handleOpenDevolucionProcessModal(group.items[0].id)}
                        style={{ width: '100%' }}
                      >
                        Realizar Proceso de Devolución
                      </Button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <Table
                columns={columnsEnlistment}
                dataSource={filteredData}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                  showQuickJumper: false,
                }}
                scroll={{ x: 1500 }}
                locale={{ emptyText: 'No hay datos disponibles' }}
              />
            )}
          </div>
        </div>
        <Modal
          title="Escanear Código de Barras"
          visible={isScanningSKU}
          onCancel={() => setIsScanningSKU(false)}
          footer={null}
        >
          <div id="qr-reader-sku" style={{ width: "100%" }} />
          <Button onClick={startScanningSKU} type="primary" block style={{ marginBottom: '10px' }}>
            Iniciar Escaneo
          </Button>
          <Button 
            onClick={() => {
              setIsScanningSKU(false);
              sendScannedBarcode(expectedBarcode);
            }} 
            block
          >
            Prueba Escaneo
          </Button>
        </Modal>
        <Modal
          title="Escanear Preenvío Devolución"
          visible={isDevolucionScanning}
          onCancel={() => setIsDevolucionScanning(false)}
          footer={null}
        >
          <div id="qr-reader-devolucion" style={{ width: "100%" }} />
          <Button onClick={startDevolucionScanning} type="primary" block>
            Iniciar Escaneo
          </Button>
        </Modal>
        <Modal
          title="Realizar Proceso de Devolución"
          visible={isDevolucionProcessModalVisible}
          onCancel={() => {
            setIsDevolucionProcessModalVisible(false);
            resetModalData();
          }}
          footer={[
            <Button key="cancel" onClick={() => {
              setIsDevolucionProcessModalVisible(false);
              resetModalData();
            }}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={handleDevolucionProcessSubmit}>
              Realizar Devolución
            </Button>
          ]}
        >
          <div>
            <p><strong>ID Enlistment:</strong> {devolucionData.id_enlistment}</p>
            <p><strong>Códigos de Barra Escaneados:</strong></p>
            <ul>
              {devolucionData.codigos_barra.map((barcode, index) => (
                <li key={index}>{barcode}</li>
              ))}
            </ul>
            <div>
              <label>Motivo de Devolución:</label>
              <select
                value={devolucionData.motivo_devolucion}
                onChange={(e) => handleDevolucionProcessChange('motivo_devolucion', e.target.value)}
                required
              >
                <option value="">Seleccione un motivo</option>
                <option value="Producto defectuoso">Producto defectuoso</option>
                <option value="Producto incompleto">Producto incompleto</option>
                <option value="Producto dañado">Producto dañado</option>
                <option value="No recibido">No recibido</option>
                <option value="Cliente no encontrado">Cliente no encontrado</option>
                <option value="Cancelacion pedido">Cancelacion pedido</option>
              </select>
            </div>
            <div>
              <label>Observaciones:</label>
              <textarea
                value={devolucionData.observaciones}
                onChange={(e) => handleDevolucionProcessChange('observaciones', e.target.value)}
                rows="4"
                style={{ width: '100%' }}
                required
              />
            </div>
            <div>
              <label>Adjunto 1:</label>
              <input 
                type="file" 
                ref={adjunto1Ref}
                onChange={(e) => handleImageChange('adjunto1', e)} 
              />
            </div>
            <div>
              <label>Adjunto 2:</label>
              <input 
                type="file" 
                ref={adjunto2Ref}
                onChange={(e) => handleImageChange('adjunto2', e)} 
              />
            </div>
          </div>
        </Modal>
      </>
    );
  };

  export default ListaDevoluciones;