import React, { useState } from 'react';

const ExpandableText = ({ text, maxLength = 100 }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!text || text.length <= maxLength) {
        return <span>{text}</span>;
    }

    const displayText = isExpanded ? text : text.substr(0, maxLength);

    return (
        <span style={{ display: 'inline' }}>
            {displayText}
            {!isExpanded && '... '}
            <a
                onClick={() => setIsExpanded(!isExpanded)}
                style={{ 
                    cursor: 'pointer',
                    color: '#1890ff',
                    marginLeft: '4px',
                    display: 'inline'
                }}
            >
                {isExpanded ? 'Ver menos' : 'Ver más'}
            </a>
        </span>
    );
};

export default ExpandableText;
