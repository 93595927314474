import React, { useState, useEffect, axios } from 'react';
import { message, DatePicker, Card, Statistic, Table, Modal, Alert } from 'antd';
import { WhatsAppOutlined, DollarOutlined, WarningOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import NavbarMex from '../newInterface/components/navbarMex';
import './whatsapp.css';
import moment from 'moment';

const { RangePicker } = DatePicker;

const WhatsappComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [facturacionData, setFacturacionData] = useState(null);
  const [sucursalId, setSucursalId] = useState(null);
  const [mensajesMensuales, setMensajesMensuales] = useState([]);
  const [totalMensajes, setTotalMensajes] = useState(0);
  const [whatsappStatus, setWhatsappStatus] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const userId = localStorage.getItem('usuario_id');
  const [messageValues, setMessageValues] = useState({
    valor_mensaje_envio: 0,
    valor_mensaje_respuesta: 0
  });

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const id = localStorage.getItem('usuario_id');
        if (!id) return;

        // Get message values first
        try {
          const messageValuesResponse = await fetch('https://99envios.app/api/online/whatsapp/valor-mensaje-consultar/view', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const messageValuesData = await messageValuesResponse.json();
          setMessageValues(messageValuesData.data);
        } catch (err) {
          console.error('Error fetching message values:', err);
        }

        // Existing API calls in parallel
        const [sucursalResponse, sucursalDetailsResponse] = await Promise.all([
          fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          }),
          fetch(`https://99envios.app/api/online/sucursal/${id}`)
        ]);

        if (!sucursalResponse.ok) {
          throw new Error('Error fetching sucursal ID');
        }

        const sucursalId = await sucursalResponse.json();
        setSucursalId(sucursalId);

        if (!sucursalDetailsResponse.ok) {
          throw new Error('Error fetching sucursal details');
        }

        const sucursalDetails = await sucursalDetailsResponse.json();
        setWhatsappStatus(sucursalDetails.status_whatsapp);

        // Remaining API calls
        const [mensajesResponse, totalResponse] = await Promise.all([
          fetch(`https://99envios.app/api/online/whatsapp/total_mensajes_mes_sucursal/${sucursalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          }),
          fetch(`https://99envios.app/api/online/whatsapp/total_mensajes_sucursal/${sucursalId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            }
          })
        ]);

        const mensajesData = await mensajesResponse.json();
        const totalData = await totalResponse.json();

        setMensajesMensuales(mensajesData);
        setTotalMensajes(totalData);

      } catch (error) {
        console.error('Error fetching data:', error);
        message.error('Error al cargar los datos');
      }
    };

    fetchAllData();
  }, []); // Solo se ejecuta al montar el componente

  const handleWhatsappAction = async (action) => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://99envios.app/api/online/whatsapp/message_template_${action}/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || `Error al ${action === 'activar' ? 'activar' : 'desactivar'} WhatsApp`);
      }

      // Update WhatsApp status after successful action
      const newStatus = action === 'activar' ? 1 : 0;
      setWhatsappStatus(newStatus);

      message.success({
        content: data.success || `WhatsApp ${action === 'activar' ? 'activado' : 'desactivado'} exitosamente.`,
        className: 'custom-message'
      });
    } catch (error) {
      message.error({
        content: error.message,
        className: 'custom-message'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Modificar handleFechasChange para usar sucursalId
  const handleFechasChange = async (dates) => {
    if (!dates || !dates[0] || !dates[1]) return;
    if (!sucursalId) {
      message.error('Código de sucursal no disponible');
      return;
    }

    try {
      const response = await fetch('https://99envios.app/api/online/whatsapp/facturacion', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          codigoSucursal: String(sucursalId), // Convertir a string
          fechaInicio: dates[0].format('YYYY-MM-DD HH:mm:ss'),
          fechaFin: dates[1].format('YYYY-MM-DD HH:mm:ss')
        })
      });

      const data = await response.json();
      setFacturacionData(data);
    } catch (error) {
      message.error('Error al obtener datos de facturación');
    }
  };

  const showActivationModal = () => {
    setIsModalVisible(true);
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    handleWhatsappAction('activar');
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Mes',
      dataIndex: 'mes',
      key: 'mes',
    },
    {
      title: 'Mensajes',
      dataIndex: 'cantidad_mensajes',
      key: 'cantidad_mensajes',
    },
    {
      title: 'Valor Total',
      dataIndex: 'total_valor_mensajes',
      key: 'total_valor_mensajes',
      render: (value) => `$${value}`,
    },
  ];

  return (
    <>
      <NavbarMex title="Gestión de WhatsApp" />
      <div className="page-wha">
        <div className="whatsapp-layout">
          <div className="promo-banner">
            <div className="promo-banner-content">
              <div className="promo-banner-header">
                <span className="emoji">💡</span> ¡Servicio GRATIS hasta marzo! <span className="emoji">💡</span>
              </div>
              <div className="price-info-banner">
                <div className="price-details-banner">
                  <span className="price-label-banner">Mensaje de Envío:</span>
                  <span className="price-value-banner">${messageValues.valor_mensaje_envio} COP</span>
                </div>
                <div className="price-details-banner">
                  <span className="price-label-banner">Mensaje de Respuesta:</span>
                  <span className="price-value-banner">${messageValues.valor_mensaje_respuesta} COP</span>
                </div>
              </div>
              <p className="promo-description">
                Prueba nuestro servicio sin costo. Los valores mostrados son referenciales para que conozcas tu ahorro.
                Después de marzo, decides si continúas con el servicio.
              </p>
              <div className="promo-benefits-compact">
                <span className="benefit"><span className="check">✅</span> Sin costos ocultos</span>
                <span className="benefit"><span className="check">✅</span> Conoce tu ahorro real</span>
                <span className="benefit"><span className="check">✅</span> Sin compromiso de permanencia</span>
              </div>
            </div>
          </div>

          <div className="cards-container-wha">
            {/* Primera tarjeta - WhatsApp */}
            <Card className="card-wha" bordered={false}>
              <div className="content-wha">
                <WhatsAppOutlined className="icon-wha" />
                <h2 className="title-wha">Notificaciones</h2>
                <div className="total-mensajes-wha">
                  <Statistic 
                    title="Total Mensajes Enviados"
                    value={totalMensajes}
                    prefix={<WhatsAppOutlined />}
                  />
                </div>
                <div className="buttons-container-wha">
                  <button
                    onClick={showActivationModal}
                    className={`button-wha activate-wha ${isLoading ? 'loading-wha' : ''}`}
                    disabled={isLoading || whatsappStatus === 1}
                  >
                    Activar
                  </button>
                  <button
                    onClick={() => handleWhatsappAction('desactivar')}
                    className={`button-wha deactivate-wha ${isLoading ? 'loading-wha' : ''}`}
                    disabled={isLoading || whatsappStatus === 0}
                  >
                    Desactivar
                  </button>
                </div>
              </div>
            </Card>

            {/* Segunda tarjeta - Facturación por rango de fechas */}
            <Card className="facturacion-card" bordered={false}>
              <div className="facturacion-content">
                <DollarOutlined className="facturacion-icon" />
                <h2 className="facturacion-title">Facturación por Fecha</h2>
                <RangePicker
                  className="date-picker"
                  onChange={handleFechasChange}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                />
                {facturacionData && (
                  <div className="facturacion-stats">
                    <Statistic 
                      title="Sucursal"
                      value={facturacionData.codigo_sucursal}
                    />
                    <Statistic
                      title="Registros"
                      value={facturacionData.total_registros}
                    />
                    <Statistic
                      title="Total"
                      value={facturacionData.suma_total || 0}
                      prefix="$"
                    />
                  </div>
                )}
              </div>
            </Card>

            {/* Tercera tarjeta - Historial Mensual (ahora ocupa todo el ancho) */}
            <Card className="facturacion-card" bordered={false}>
              <div className="facturacion-content">
                <DollarOutlined className="facturacion-icon" />
                <h2 className="facturacion-title">Historial Mensual de Mensajes</h2>
                <Table
                  dataSource={mensajesMensuales}
                  columns={columns}
                  pagination={false}
                  className="mensajes-table"
                  summary={data => {
                    const totalMensajes = data.reduce((sum, item) => sum + item.cantidad_mensajes, 0);
                    const totalValor = data.reduce((sum, item) => sum + parseFloat(item.total_valor_mensajes || 0), 0);
                    
                    return (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                        <Table.Summary.Cell>{totalMensajes}</Table.Summary.Cell>
                        <Table.Summary.Cell>${totalValor.toFixed(0)}</Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                />
              </div>
            </Card>
          </div>
        </div>

        <Modal
          title={
            <div className="modal-title-wha">
              <WhatsAppOutlined className="modal-icon-wha" />
              <span>Activar Notificaciones WhatsApp</span>
            </div>
          }
          open={isModalVisible}
          onOk={handleModalConfirm}
          onCancel={handleModalCancel}
          className="modal-wha"
          okText="Aceptar"
          cancelText="Cancelar"
        >
          <div className="modal-content-wha">
            <div className="modal-info-wha">
              <DollarOutlined className="price-icon-wha" />
              <div className="price-info-wha">
                <div className="price-details">
                  <span className="price-label">Mensaje de Envío:</span>
                  <span className="price-wha">${messageValues.valor_mensaje_envio} COP</span>
                </div>
                <div className="price-details">
                  <span className="price-label">Mensaje de Respuesta:</span>
                  <span className="price-wha">${messageValues.valor_mensaje_respuesta} COP</span>
                </div>
              </div>
            </div>
            <div className="modal-benefits-wha">
              <div className="benefit-item-wha">
                <InfoCircleOutlined /> Facturación mensual
              </div>
              <div className="benefit-item-wha">
                <WhatsAppOutlined /> Notificaciones al usuario
              </div>
              <div className="benefit-item-wha">
                <CheckCircleOutlined /> Mayor satisfacción del cliente
              </div>
              <div className="benefit-item-wha">
                <WarningOutlined /> Solo se cobra por mensajes enviados
              </div>
            </div>
            <div className="free-service-modal">
              <Alert
                message="¡Servicio Gratis hasta Marzo!"
                description="Hasta Marzo, el uso de este servicio será completamente gratis. ¡No pierdas esta oportunidad!"
                type="success"
                showIcon
              />
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default WhatsappComponent;

