import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, message, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NavbarUsuarioEnlistment from '../navbarUsuarioEnlistment';
import './pedidosEnlistment.css';
import moment from 'moment';
import { Html5Qrcode } from 'html5-qrcode';

const PedidosEnlistment = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [enlistmentData, setEnlistmentData] = useState([]);
  const [isScanning, setIsScanning] = useState(false);
  const [isScanningSKU, setIsScanningSKU] = useState(false);
  const [scannedNumber, setScannedNumber] = useState('');
  const [barcodeData, setBarcodeData] = useState(null);
  const [expectedBarcode, setExpectedBarcode] = useState('');
  const [expectedPreenvio, setExpectedPreenvio] = useState('');
  const [scannedBarcodes, setScannedBarcodes] = useState(new Map()); // Almacenará {código_barras => {preenvio, id_orden}}
  const [currentItem, setCurrentItem] = useState(null); // Nuevo estado para el item actual
  const [scanCount, setScanCount] = useState(0); // Nuevo estado para el contador de escaneos
  const [requiredScans, setRequiredScans] = useState(0); // Nuevo estado para la cantidad requerida de escaneos
  const usuario_id = localStorage.getItem('usuario_id');

  const isMobile = window.innerWidth <= 768;

  const fetchEnlistmentData = async () => {
    try {
      const response = await axios.get(`https://99envios.app/api/picking/enlistment/listar-tipo-enlistment/${usuario_id}`);
      const formattedData = response.data.map((item, index) => {
        // Handle internal orders (orden property is present)
        if (item.orden && item.orden.detalles) {
          return item.orden.detalles.map((detalle, detalleIndex) => {
            const inventario = detalle.producto?.inventarios_bodega?.find(
              inv => inv.id_sucursal_bodega === item.id_sucursal_bodega
            );
            
            return {
              key: `${index}-${detalleIndex}`,
              id: item.id,
              id_orden: item.id_orden,
              id_sucursal_bodega: item.id_sucursal_bodega,
              numero_preenvio: item.numero_preenvio,
              nombre_producto: detalle.nombre_producto,
              id_producto: detalle.id_producto,
              codigo_barras: inventario?.codigo_barras || null,
              id_variacion: detalle.id_variacion || 'Sin variación',
              cantidad: detalle.cantidad,
              tipo_enlistment: item.tipo_enlistment,
              estado_enlistment: item.estado_enlistment,
              created_at: item.created_at,
              updated_at: item.updated_at,
              origen_externo: 0
            };
          });
        }
        // Handle external orders (orden_externa property is present)
        else if (item.orden_externa && item.orden_externa.detalles) {
          return item.orden_externa.detalles.map((detalle, detalleIndex) => {
            const inventarioBodega = detalle.producto_externo?.inventario?.inventarios_bodega?.find(
              inv => inv.id_sucursal_bodega === item.id_sucursal_bodega
            );
  
            return {
              key: `${index}-${detalleIndex}`,
              id: item.id,
              id_orden: `Externa: ${item.id_orden_externa}`,
              id_sucursal_bodega: item.id_sucursal_bodega,
              numero_preenvio: item.numero_preenvio,
              nombre_producto: detalle.producto_externo?.inventario?.nombre_producto,
              id_producto: detalle.producto_externo?.id_producto,
              codigo_barras: inventarioBodega?.codigo_barras || null,
              id_variacion: detalle.id_variacion_externa || 'Sin variación',
              cantidad: detalle.cantidad,
              tipo_enlistment: item.tipo_enlistment,
              estado_enlistment: item.estado_enlistment,
              created_at: item.created_at,
              updated_at: item.updated_at,
              nombre_destinatario: item.orden_externa.nombre_destinatario,
              direccion_destinatario: item.orden_externa.direccion_destinatario,
              telefonos_destinatario: item.orden_externa.telefonos_destinatario,
              observaciones: item.orden_externa.observaciones,
              transportadora: item.orden_externa.transportadora,
              tipo_producto_externo: item.orden_externa.tipo_producto_externo,
              origen_externo: 1
            };
          });
        }
        // Handle single items without details
        return [{
          key: index,
          id: item.id,
          id_orden: item.origen_externo ? `Externa: ${item.id_orden_externa}` : item.id_orden,
          id_sucursal_bodega: item.id_sucursal_bodega,
          numero_preenvio: item.numero_preenvio,
          nombre_producto: item.nombre_producto,
          id_producto: item.id_producto,
          codigo_barras: item.codigo_barras,
          id_variacion: item.id_variacion || 'Sin variación',
          cantidad: item.cantidad,
          tipo_enlistment: item.tipo_enlistment,
          estado_enlistment: item.estado_enlistment,
          created_at: item.created_at,
          updated_at: item.updated_at,
          origen_externo: item.origen_externo
        }];
      }).flat();
      setEnlistmentData(formattedData);
    } catch (error) {
      console.error('Error fetching enlistment data:', error);
      message.error('Error al cargar los datos de enlistamiento');
    }
  };

  useEffect(() => {
    fetchEnlistmentData();
  }, [usuario_id]);

  useEffect(() => {
    const filtered = enlistmentData.filter(item =>
      Object.values(item).some(value =>
        value && value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchText, enlistmentData]);

  const handleScan = async (numero_preenvio) => {
    const item = enlistmentData.find((item) => item.numero_preenvio === numero_preenvio);
    if (!item) {
      message.error('Item no encontrado');
      return;
    }

    setExpectedPreenvio(item.numero_preenvio);
    setIsScanning(true);
  };

  const handleScanSKU = async (codigo_barras, item) => {
    if (!item) {
      message.error('Item no encontrado');
      return;
    }

    setCurrentItem(item);
    setExpectedBarcode(codigo_barras);
    setRequiredScans(item.cantidad); // Establecer la cantidad requerida de escaneos
    setScanCount(0); // Reiniciar el contador de escaneos
    setIsScanningSKU(true);
  };

  const startScanning = () => {
    const html5QrCode = new Html5Qrcode("qr-reader");
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    html5QrCode.start(
      { facingMode: "environment" }, 
      config,
      (decodedText) => {
        html5QrCode.stop().then(() => {
          setScannedNumber(decodedText);
          setIsScanning(false);
          sendScannedNumber(decodedText);
        }).catch((err) => {
          console.error('Error deteniendo el escaneo:', err);
          message.error('Error al detener el escaneo');
        });
      },
      (errorMessage) => {
        console.warn('Error en el escaneo:', errorMessage);
      }
    ).catch((err) => {
      console.error('Error inicializando el escaneo:', err);
      message.error('Error al iniciar el escaneo');
    });
  };

  const startScanningSKU = () => {
    const html5QrCode = new Html5Qrcode("qr-reader-sku");
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    html5QrCode.start(
      { facingMode: "environment" }, 
      config,
      (decodedText) => {
        if (decodedText === expectedBarcode) {
          html5QrCode.stop().then(() => {
            setScannedNumber(decodedText);
            sendScannedBarcode(decodedText);
          }).catch((err) => {
            console.error('Error deteniendo el escaneo:', err);
            message.error('Error al detener el escaneo');
          });
        } else {
          message.error('Código de barras incorrecto. Por favor escanee el producto correcto.');
        }
      },
      (errorMessage) => {
        console.warn('Error en el escaneo:', errorMessage);
      }
    ).catch((err) => {
      console.error('Error inicializando el escaneo:', err);
      message.error('Error al iniciar el escaneo');
    });
  };

  const sendScannedNumber = async (number) => {
    try {
      if (number !== expectedPreenvio) {
        message.error('Por favor escanee el preenvío correcto');
        return;
      }

      const item = enlistmentData.find((item) => item.numero_preenvio === number);
      if (!item) {
        message.error('Número de preenvío no encontrado');
        return;
      }

      // Get the first part of id_orden to determine if it's external
      const isExternal = item.id_orden.toString().toLowerCase().includes('externa');
      const origen_externo = isExternal ? 1 : 0;

      // Si es tipo despacho (4), automáticamente marcar los códigos de barras como escaneados
      if (item.tipo_enlistment === 4) {
        const itemsWithSamePreenvio = enlistmentData.filter(i => i.numero_preenvio === number);
        itemsWithSamePreenvio.forEach(item => {
          const barcodeKey = createBarcodeKey(
            item.codigo_barras,
            item.numero_preenvio,
            item.id_orden
          );
          setScannedBarcodes(prev => new Map(prev).set(barcodeKey, true));
        });
      }

      await axios.post(`https://99envios.app/api/picking/enlistment/actualizar-tipo-enlistment/${usuario_id}`, {
        numero_preenvio: parseInt(number, 10),
        origen_externo: origen_externo,
      });
      message.success('Escaneo de preenvío exitoso');
      fetchEnlistmentData();
    } catch (error) {
      message.error('Error al enviar el número escaneado');
      console.error('Error al enviar el número escaneado:', error);
    }
  };

  const sendScannedBarcode = async (scannedBarcode) => {
    try {
      if (scannedBarcode !== expectedBarcode) {
        message.error('Por favor escanee el producto correcto');
        return;
      }

      const hasVariation = currentItem?.id_variacion === 'Tiene variación';

      const response = await axios.get(`https://99envios.app/api/picking/enlistment/obtener-detalle-inventario/${usuario_id}`, {
        params: { 
          codigo_barras: scannedBarcode,
          variacion: hasVariation ? 1 : 0
        },
      });
      message.success('Escaneo de código de barras exitoso');
      setBarcodeData(response.data);
      setScanCount(prevCount => prevCount + 1);

      if (scanCount + 1 >= requiredScans) {
        setIsScanningSKU(false);
        message.success('Todos los productos escaneados correctamente');
      }
    } catch (error) {
      message.error('Error al enviar el código de barras escaneado');
      console.error('Error al enviar el código de barras escaneado:', error);
    }
  };

  // Agregar una función helper para crear una clave única
  const createBarcodeKey = (codigo_barras, numero_preenvio, id_orden) => {
    return `${codigo_barras}-${numero_preenvio}-${id_orden}`;
  };

  // Modificar la función que verifica si un código está escaneado
  const isCodeScannedForPreenvio = (codigo_barras, numero_preenvio, id_orden) => {
    const scannedInfo = scannedBarcodes.get(createBarcodeKey(codigo_barras, numero_preenvio, id_orden));
    return scannedInfo !== undefined;
  };

  // Modificar la función que verifica todos los códigos de un preenvío
  const areAllBarcodesScannedForPreenvio = (numero_preenvio) => {
    const itemsWithPreenvio = enlistmentData.filter(item => item.numero_preenvio === numero_preenvio);
    return itemsWithPreenvio.every(item => 
      isCodeScannedForPreenvio(item.codigo_barras, item.numero_preenvio, item.id_orden)
    );
  };

  // Modificar la función que maneja la aceptación del escaneo
  const handleAcceptBarcodeScan = () => {
    if (currentItem) {
      const newScanCount = scanCount ;
      setScanCount(newScanCount);
      
      // Cerrar solo el modal de detalles
      setBarcodeData(null);
      
      if (newScanCount >= requiredScans) {
        // Si completamos todos los escaneos
        const barcodeKey = createBarcodeKey(
          expectedBarcode, 
          currentItem.numero_preenvio, 
          currentItem.id_orden
        );
        setScannedBarcodes(prev => new Map(prev).set(barcodeKey, true));
        setIsScanningSKU(false);
        setCurrentItem(null);
        message.success('Todos los escaneos completados exitosamente');
      } else {
        // Si faltan escaneos, mantener el escáner abierto y mostrar progreso
        message.info(`Escaneo ${newScanCount} completado. Por favor escanee el siguiente producto (${requiredScans - newScanCount} restantes)`);
        // Reiniciar el escáner para el siguiente producto
        startScanningSKU();
      }
    }
  };

  // Modificar la sección del render en columnsEnlistment
  const columnsEnlistment = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Orden',
      dataIndex: 'id_orden',
      key: 'id_orden',
    },
    {
      title: 'Nombre del Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Número Preenvío',
      dataIndex: 'numero_preenvio',
      key: 'numero_preenvio',
    },
    {
      title: 'Código de Barras',
      dataIndex: 'codigo_barras',
      key: 'codigo_barras',
    },
    {
      title: 'ID Producto',
      dataIndex: 'id_producto',
      key: 'id_producto',
    },
    {
      title: 'Variación',
      dataIndex: 'id_variacion',
      key: 'id_variacion',
      render: (value) => value ? value : 'Sin variación',
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Tipo Enlistamiento',
      dataIndex: 'tipo_enlistment',
      key: 'tipo_enlistment',
      render: (value) => {
        switch (value) {
          case 1:
            return 'Pre Alistamiento';
          case 2:
            return 'Alistamiento';
          case 3:
            return 'Empaque';
          case 4:
            return 'Despacho';
          case 5:
            return 'Devoluciones';
          default:
            return value;
        }
      },
    },
    {
      title: 'Estado Enlistamiento',
      dataIndex: 'estado_enlistment',
      key: 'estado_enlistment',
      render: (value) => {
        switch (value) {
          case 0:
            return 'En Proceso';
          case 1:
            return 'Completado';
          default:
            return value;
        }
      },
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Fecha de Actualización',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Acciones',
      key: 'acciones',
      render: (_, record) => {
        if (!record) return null;  // <- Agregué esta verificación

        // Agregué verificación de nulos y valor por defecto []
        const itemsWithSamePreenvio = enlistmentData.filter(
          item => item?.numero_preenvio === record.numero_preenvio
        ) || [];
        
        // Agregué verificaciones de seguridad con ?. y length
        const isFirstInGroup = itemsWithSamePreenvio.length > 0 && 
          itemsWithSamePreenvio[0]?.id === record?.id;
        
        // Agregué verificaciones adicionales de propiedades
        const allBarcodesScanned = itemsWithSamePreenvio.length > 0 &&
          itemsWithSamePreenvio.every(item =>
            item && item.codigo_barras && item.numero_preenvio && item.id_orden &&
            isCodeScannedForPreenvio(item.codigo_barras, item.numero_preenvio, item.id_orden)
          );

        // Agregué verificaciones de propiedades con ?.
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            {isFirstInGroup && (record?.tipo_enlistment === 4 || allBarcodesScanned) && (
              <Button 
                type="primary"
                onClick={() => handleScan(record.numero_preenvio)}
              >
                Escanear Preenvío
              </Button>
            )}
            
            {record?.tipo_enlistment !== 4 && 
             record?.codigo_barras && 
             !isCodeScannedForPreenvio(record.codigo_barras, record.numero_preenvio, record.id_orden) && (
              <Button
                onClick={() => handleScanSKU(record.codigo_barras, record)}
              >
                Escanear Código de Barras ({
                  isCodeScannedForPreenvio(record.codigo_barras, record.numero_preenvio, record.id_orden) 
                    ? record.cantidad 
                    : '0'
                }/{record.cantidad || 0})
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <NavbarUsuarioEnlistment title="Pedidos Picking" />
      <div className="misPedidos-e">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="misPedidos-tabla" style={{ overflowX: 'auto', marginTop: '16px' }}>
          <h2>Enlistamientos</h2>
          {isMobile ? (
            filteredData.reduce((acc, item) => {
              const existingGroup = acc.find(group => group.numero_preenvio === item.numero_preenvio);
              if (existingGroup) {
                existingGroup.items.push(item);
              } else {
                acc.push({ numero_preenvio: item.numero_preenvio, items: [item] });
              }
              return acc;
            }, []).map((group, index) => (
              <div key={index} className="card-pedidos-e">
                <div className="card-header-pedidos-e">
                  <strong>Número Preenvío:</strong> <span>{group.numero_preenvio}</span>
                </div>
                {group.items.map((item) => (
                  <div key={item.key} className="subcard-pedidos-e">
                    <div className="card-item-pedidos-e" style={{ textAlign: 'center' }}><strong><span>{item.nombre_producto}</span></strong></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Código de Barras:</strong> <span>{item.codigo_barras}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>ID Orden:</strong> <span>{item.id_orden}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>ID Producto:</strong> <span>{item.id_producto}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Cantidad:</strong> <span>{item.cantidad}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>ID Variación:</strong> <span>{item.id_variacion ? item.id_variacion : 'Sin variación'}</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Tipo Enlistamiento:</strong> <span>{
                      (() => {
                        switch (item.tipo_enlistment) {
                          case 1:
                            return 'Pre Alistamiento';
                          case 2:
                            return 'Alistamiento';
                          case 3:
                            return 'Empaque';
                          case 4:
                            return 'Despacho';
                          case 5:
                            return 'Devoluciones';
                          default:
                            return item.tipo_enlistment;
                        }
                      })()
                    }</span></div>
                    <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Estado Enlistamiento:</strong> <span>{
                      (() => {
                        switch (item.estado_enlistment) {
                          case 0:
                            return 'En Proceso';
                          case 1:
                            return 'Completado';
                          default:
                            return item.estado_enlistment;
                          }
                        })()
                      }</span></div>
                      <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Fecha de Creación:</strong> <span>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</span></div>
                      <div className="card-item-pedidos-e" style={{ textAlign: 'left' }}><strong>Fecha de Actualización:</strong> <span>{moment(item.updated_at).format('DD/MM/YYYY HH:mm')}</span></div>
                      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                        {item.tipo_enlistment !== 4 && !isCodeScannedForPreenvio(item.codigo_barras, item.numero_preenvio, item.id_orden) && (
                          <Button onClick={() => handleScanSKU(item.codigo_barras, item)}>
                            Escanear Código de Barras
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                  {(group.items[0].tipo_enlistment === 4 || areAllBarcodesScannedForPreenvio(group.items[0].numero_preenvio)) && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Button 
                        onClick={() => handleScan(group.items[0].numero_preenvio)} 
                        style={{ marginBottom: '8px' }}
                      >
                        Escanear Preenvio
                      </Button>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <Table
                columns={columnsEnlistment}
                dataSource={filteredData}
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                  showQuickJumper: false,
                }}
                scroll={{ x: 1500 }}
                locale={{ emptyText: 'No hay datos disponibles' }}
              />
            )}
          </div>
        </div>
        <Modal
          title="Escanear Preenvío"
          visible={isScanning}
          onCancel={() => setIsScanning(false)}
          footer={null}
        >
          <div id="qr-reader" style={{ width: "100%" }} />
          <Button onClick={startScanning} type="primary" block>
            Iniciar Escaneo
          </Button>
        </Modal>
        <Modal
          title="Escanear Código de Barras"
          visible={isScanningSKU}
          onCancel={() => {
            if (window.confirm('¿Está seguro de que desea cancelar el escaneo? Se perderá el progreso actual.')) {
              setIsScanningSKU(false);
              setScanCount(0);
              setCurrentItem(null);
            }
          }}
          footer={null}
        >
          <div id="qr-reader-sku" style={{ width: "100%" }} />
          <Button onClick={startScanningSKU} type="primary" block style={{ marginBottom: '10px' }}>
            {scanCount === 0 ? 'Iniciar Escaneo' : 'Escanear Siguiente'}
          </Button>
          <p>Escaneos completados: {scanCount}/{requiredScans}</p>
          <p>Por favor escanee el producto {scanCount + 1} de {requiredScans}</p>
        </Modal>
        <Modal
          title="Detalles del Código de Barras"
          visible={!!barcodeData}
          onCancel={() => setBarcodeData(null)}
          footer={[
            <Button 
              key="accept" 
              type="primary" 
              onClick={handleAcceptBarcodeScan}
            >
              Aceptar
            </Button>
          ]}
        >
          {barcodeData && (
            <div>
              <p><strong>ID:</strong> {barcodeData.id}</p>
              <p><strong>ID Sucursal Bodega:</strong> {barcodeData.id_sucursal_bodega}</p>
              <p><strong>ID Producto:</strong> {barcodeData.id_producto}</p>
              <p><strong>Nombre del Producto:</strong> {barcodeData.nombre_producto}</p>
              <p><strong>Confirmación Bodega:</strong> {barcodeData.confirmacion_bodega}</p>
              <p><strong>Estado Variación:</strong> {barcodeData.estado_variacion}</p>
              <p><strong>Fecha de Creación:</strong> {moment(barcodeData.created_at).format('DD/MM/YYYY HH:mm')}</p>
              <p><strong>Fecha de Actualización:</strong> {moment(barcodeData.updated_at).format('DD/MM/YYYY HH:mm')}</p>
              <p><strong>Cantidad Disponible:</strong> {barcodeData.cantidad_disponible}</p>
              <p><strong>Origen Externo:</strong> {barcodeData.origen_externo}</p>
              <p><strong>SKU:</strong> {barcodeData.sku}</p>
              <p><strong>Código de Barras:</strong> {barcodeData.codigo_barras}</p>
              {barcodeData.variaciones_bodega && barcodeData.variaciones_bodega.length > 0 && (
                <div>
                  <h3>Variaciones Bodega:</h3>
                  {barcodeData.variaciones_bodega.map((variacion) => (
                    <div key={variacion.id}>
                      <p><strong>ID Variación:</strong> {variacion.id_variacion}</p>
                      <p><strong>Cantidad Disponible:</strong> {variacion.cantidad_disponible}</p>
                      <p><strong>SKU:</strong> {variacion.sku}</p>
                      <p><strong>Código de Barras:</strong> {variacion.codigo_barras}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </Modal>
      </>
    );
  };

export default PedidosEnlistment;