import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, message, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './pedidosExternos.css';
import moment from 'moment';
import { ArchivosExternos } from '../../archivosExternos/archivosExternos';
import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';

const PedidosExternos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedAvailableItems, setSelectedAvailableItems] = useState([]); // Changed from selectedItems
  const [availableData, setAvailableData] = useState([]);
  const [unavailableData, setUnavailableData] = useState([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [selectedUnavailableItems, setSelectedUnavailableItems] = useState([]);
  const usuario_id = localStorage.getItem('usuario_id');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.99envios.app/api/picking/bodega/listar-ordenes-externas/${usuario_id}`);
      const formattedData = response.data
        .filter(item => item.estado_enlistment === 0)
        .map((item, index) => ({
          key: index,
          id: item.id,
          id_variacion: item.id_variacion_inventario_bodega,
          talla: item.nombre_variacion ? item.nombre_variacion : 'Sin talla',
          color: item.nombre_variacion ? item.nombre_variacion : 'Sin color',
          nombre_producto: item.detalles?.[0]?.producto_externo?.inventario?.nombre_producto || item.nombre_producto,
          fecha_estado: `${item.fecha_reporte} ${item.hora_reporte}`,
          estado_envio: item.estatus,
          valor_proveedor: parseFloat(item.precio_proveedor),
          valor_total: parseFloat(item.total_orden),
          valor_utilidad: parseFloat(item.total_orden) - parseFloat(item.precio_proveedor) - parseFloat(item.precio_flete),
          numero_guia: item.numero_preenvio,
          nombre_destinatario: item.nombre_destinatario ? item.nombre_destinatario : 'Sin destinatario',
          telefono_destinatario: item.telefonos_destinatario ? item.telefonos_destinatario : 'Sin teléfono',
          direccion_destinatario: item.direccion_destinatario ? item.direccion_destinatario : 'Sin dirección',
          peso: item.detalles?.[0]?.producto_externo?.inventario?.peso_producto || 'N/A',
          largo: item.largo ? item.largo : 'N/A',
          ancho: item.ancho ? item.ancho : 'N/A',
          alto: item.alto ? item.alto : 'N/A',
          valor_declarado: item.valor_declarado ? item.valor_declarado : 'N/A',
          cantidad: item.cantidad,
          no_disponible: item.no_disponible || 0,
          selected: false,
          codigo_barras: item.detalles?.[0]?.producto_externo?.inventario?.inventarios_bodega?.[0]?.codigo_barras || 
                        item.detalles?.[0]?.variacion_externa?.variacion?.variacion_bodega?.[0]?.codigo_barras || 
                        'Sin código',
          transportadora: item.transportadora.includes('INTER RAPIDÍSIMO') ? 'INTERRAPIDISIMO' : item.transportadora || 'N/A',
        }))
        .sort((a, b) => new Date(b.fecha_estado) - new Date(a.fecha_estado)); // Sort from latest to earliest
      setData(formattedData);
      setFilteredData(formattedData);
      setAvailableData(formattedData.filter(item => item.no_disponible === 0));
      setUnavailableData(formattedData.filter(item => item.no_disponible === 1));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [usuario_id]);

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Fecha de Estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
      sorter: (a, b) => a.estado_envio.localeCompare(b.estado_envio),
    },
    {
      title: 'Valor del Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Número de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Destinatario',
      dataIndex: 'nombre_destinatario',
      key: 'nombre_destinatario',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono_destinatario',
      key: 'telefono_destinatario',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion_destinatario',
      key: 'direccion_destinatario',
    },
    {
      title: 'Peso',
      dataIndex: 'peso',
      key: 'peso',
    },
    {
      title: 'Disponibilidad',
      dataIndex: 'no_disponible',
      key: 'no_disponible',
      render: (value) => value === 1 ? 'No disponible' : 'Disponible',
    },
    {
      title: 'Código de Barras',
      dataIndex: 'codigo_barras',
      key: 'codigo_barras',
      render: (barcode) => barcode
    },
  ];

  const columnsUnavailable = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: (_, record) => (
        <input
          type="checkbox"
          checked={record.selected}
          onChange={() => handleUnavailableCheckboxChange(record.key)}
        />
      ),
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Variación',
      dataIndex: 'variacion',
      key: 'variacion',
      render: (_, record) => {
        const idVariacion = record.id_variacion ? `ID: ${record.id_variacion}` : 'Sin variación';
        const talla = record.talla ? `Talla: ${record.talla}` : 'Sin talla';
        const color = record.color ? `Color: ${record.color}` : 'Sin color';
  
        return (
          <div>
            <p>{idVariacion}</p>
            <p>{talla}</p>
            <p>{color}</p>
          </div>
        );
      },
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Fecha de Estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
      sorter: (a, b) => a.estado_envio.localeCompare(b.estado_envio),
    },
    {
      title: 'Valor del Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => value ? `$${value.toFixed(2)}` : 'N/A',
    },
    {
      title: 'Número de Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      render: (value) => value ? value : 'Sin guía',
    },
    {
      title: 'Destinatario',
      dataIndex: 'nombre_destinatario',
      key: 'nombre_destinatario',
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono_destinatario',
      key: 'telefono_destinatario',
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion_destinatario',
      key: 'direccion_destinatario',
    },
    {
      title: 'Peso',
      dataIndex: 'peso',
      key: 'peso',
    },
    {
      title: 'Disponibilidad',
      dataIndex: 'no_disponible',
      key: 'no_disponible',
      render: (value) => value === 1 ? 'No disponible' : 'Disponible',
    },
    {
      title: 'Código de Barras',
      dataIndex: 'codigo_barras',
      key: 'codigo_barras',
      render: (barcode) => barcode
    },
  ];

  const formatDate = (dateString) => moment(dateString).format('DD/MM/YYYY HH:mm');

  const handleCheckboxChange = (key) => {
    const updatedData = availableData.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setAvailableData(updatedData);
    setSelectedAvailableItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleUnavailableCheckboxChange = (key) => {
    const updatedData = unavailableData.map(item => 
      item.key === key ? { ...item, selected: !item.selected } : item
    );
    setUnavailableData(updatedData);
    setSelectedUnavailableItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSelectAll = () => {
    const allSelected = availableData.every(item => item.selected);
    const updatedData = availableData.map(item => ({ ...item, selected: !allSelected }));
    setAvailableData(updatedData);
    setSelectedAvailableItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSelectAllUnavailable = () => {
    const allSelected = unavailableData.every(item => item.selected);
    const updatedData = unavailableData.map(item => ({ ...item, selected: !allSelected }));
    setUnavailableData(updatedData);
    setSelectedUnavailableItems(updatedData.filter(item => item.selected).map(item => item.key));
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredData(result);
  }, [searchText, data]);

  const downloadPDF = (base64String, fileName) => {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadCombinedPDF = async () => {
    const selectedData = data.filter(item => selectedAvailableItems.includes(item.key));
    const pdfDocs = await Promise.all(
      selectedData.map(async (item) => {
        const pdfBytes = await fetch(`data:application/pdf;base64,${item.pdf}`).then(res => res.arrayBuffer());
        return pdfBytes;
      })
    );

    const mergedPdf = await PDFDocument.create();
    for (const pdfBytes of pdfDocs) {
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const mergedPdfBytes = await mergedPdf.save();
    const blob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'combined.pdf';
    a.click();
  };

  const generateOrderListPDF = async (selectedData) => {
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'letter'
    });
  
    // Set initial position
    let y = 20;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
  
    // Add title and date
    pdf.setFontSize(16);
    pdf.setFont('helvetica', 'bold');
    pdf.text('Lista de Productos para Pedidos Externos', pageWidth/2, y, { align: 'center' });
    
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'normal');
    pdf.text(`Fecha: ${new Date().toLocaleDateString()}`, pageWidth - 20, y, { align: 'right' });
  
    y += 15;
  
    // Define column widths (total should equal pageWidth - 20)
    const columnWidths = [
      90, // Producto (más ancho)
      25, // Variación
      25, // Color
      25, // Talla
      20, // Cantidad
      30, // Fecha
      40  // Código de Barras
    ];
  
    // Headers
    const headers = ['Producto', 'Variación', 'Color', 'Talla', 'Cantidad', 'Fecha', 'Código de Barras'];
    
    let xOffset = 10;
    pdf.setFontSize(10);
    pdf.setFont('helvetica', 'bold');
    headers.forEach((header, i) => {
      pdf.text(header, xOffset, y);
      xOffset += columnWidths[i];
    });
  
    y += 5;
    pdf.line(10, y, pageWidth - 10, y);
    y += 5;
  
    // Data rows
    pdf.setFont('helvetica', 'normal');
    
    // Reducir el espacio entre filas
    const baseRowHeight = 20; // Reducido de 25 a 20
    const doubleRowHeight = 25; // Reducido de 30 a 25
  
    for (const item of selectedData) {
      if (y > pageHeight - 50) {
        pdf.addPage();
        y = 20;
      }
  
      xOffset = 10;
      
      // Ajustar la posición y para texto (2px más abajo)
      const textY = y + 2;
  
      // Producto (dividido en dos líneas)
      const productName = item.nombre_producto;
      const maxChars = 45;
      const usesTwoLines = productName.length > maxChars;
      
      if (usesTwoLines) {
        const line1 = productName.substring(0, maxChars);
        const line2 = productName.substring(maxChars);
        pdf.text(line1, xOffset, textY);
        pdf.text(line2, xOffset, textY + 3); // Reducido de 4 a 3
      } else {
        pdf.text(productName, xOffset, textY);
      }
      xOffset += columnWidths[0];
  
      // Resto de columnas con la misma posición y
      const variacionText = item.id_variacion ? 'Con Variación' : 'Sin Variación';
      pdf.text(variacionText, xOffset, textY);
      xOffset += columnWidths[1];
  
      pdf.text(item.color || 'N/A', xOffset, textY);
      xOffset += columnWidths[2];
  
      pdf.text(item.talla || 'N/A', xOffset, textY);
      xOffset += columnWidths[3];
  
      pdf.text(item.cantidad.toString(), xOffset, textY);
      xOffset += columnWidths[4];
  
      pdf.text(formatDate(item.fecha_estado), xOffset, textY);
      xOffset += columnWidths[5];
  
      // Mantener configuración original del código de barras
      if (item.codigo_barras && item.codigo_barras !== 'Sin código') {
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, item.codigo_barras, {
          format: "CODE128",
          width: 1,
          height: 15,
          displayValue: true,
          fontSize: 8,
          marginTop: 2,
          marginBottom: 2
        });
        
        const imgData = canvas.toDataURL('image/png');
        // Cambiado y - 7 a y + 3 para mover 10px hacia abajo
        pdf.addImage(imgData, 'PNG', xOffset, y - 3, 35, 10);
      } else {
        pdf.text('Sin código', xOffset, textY);
      }
  
      // Incrementar y con el nuevo espaciado
      y += usesTwoLines ? doubleRowHeight : baseRowHeight;
    }
  
    // Signature lines
    y = pageHeight - 30;
    pdf.line(20, y, 100, y);
    pdf.line(pageWidth - 100, y, pageWidth - 20, y);
    
    y += 5;
    pdf.text('Autoriza', 50, y, { align: 'center' });
    pdf.text('Entrega', pageWidth - 60, y, { align: 'center' });
  
    return pdf;
  };

  const handleStartProcess = async () => {
    // Use selectedAvailableItems instead of selectedItems
    const selectedData = availableData.filter(item => selectedAvailableItems.includes(item.key));
    const idOrdenes = selectedData.map(item => item.id);

    try {
      // Generate and open PDF in new tab
      const pdf = await generateOrderListPDF(selectedData);
      pdf.output('dataurlnewwindow');

      // Continue with the original process
      await axios.post(`https://99envios.app/api/picking/enlistment/ordenes-enlistment-externa/${usuario_id}`, {
        id_ordenes: idOrdenes
      });
      
      // Disparar evento de actualización
      window.dispatchEvent(new Event('ordersUpdated'));
      
      message.success('Proceso iniciado con éxito');
      setSelectedAvailableItems([]); // Clear selected available items
      await fetchData(); // Refresh the data
    } catch (error) {
      message.error('Error al iniciar el proceso');
      console.error('Error al iniciar el proceso:', error);
    }
  };

  // Agregar dispatch de evento cuando se cargan nuevos pedidos
  const onUploadSuccess = () => {
    window.dispatchEvent(new Event('ordersUpdated'));
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalVisible(false);
  };

  return (
    <>
      <NavbarUsuarioBodega title="Pedidos Externos" />
      <div className='misPedidos-picking'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          
          <Button 
            onClick={handleOpenUploadModal}
            style={{ 
              backgroundColor: '#217346', // Color verde de Excel
              color: 'white', // Texto en blanco para mejor contraste
              borderColor: '#217346' // Borde del mismo color
            }}
            hover={{
              backgroundColor: '#1e6b3e' // Un tono más oscuro para el hover
            }}
          >
            Cargar Pedidos Externos
          </Button>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="misPedidos-picking-tabla" style={{ overflowX: 'auto' }}>
          <h2>Pedidos Disponibles</h2>
          <Button onClick={handleSelectAll}>
            {availableData.every(item => item.selected) ? 'Deseleccionar Todos' : 'Seleccionar Todos'}
          </Button>
          <Button onClick={handleStartProcess}>
            Iniciar Proceso
          </Button>
          <Table
            columns={columns}
            dataSource={availableData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
        <div className="misPedidos-picking-tabla" style={{ overflowX: 'auto', marginTop: '16px' }}>
          <h2>Pedidos No Disponibles</h2>
          <Button onClick={handleSelectAllUnavailable} style={{ marginBottom: '16px' }}>
            {unavailableData.every(item => item.selected) ? 'Deseleccionar Todos' : 'Seleccionar Todos'}
          </Button>
          <Button onClick={handleStartProcess}>
            Iniciar Proceso
          </Button>
          <Table
            columns={columnsUnavailable}
            dataSource={unavailableData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
      <Modal
        title="Cargar Pedidos Externos"
        open={isUploadModalVisible}
        onCancel={handleCloseUploadModal}
        footer={null}
        width={800}
      >
        <ArchivosExternos />
      </Modal>
    </>
  );
};

export default PedidosExternos;