import React, { useState } from 'react';
import axios from 'axios';
import Navbar from '../../components/navbarOut';
import Footer from '../../components/footer';
import { Form, Input, Button, message, Col, Row, Select, Radio, Checkbox } from 'antd';
import 'antd/dist/antd.css'; // Importar el stylesheet de Ant Design
import './crearSucursal.css'; // Importar tu archivo CSS para estilos personalizados
import { Link } from 'react-router-dom';
import Ciudades from '../../ciudades/ciudades'; // Importar el archivo de ciudades

const { Option } = Select;

/**
 * Componente para el registro de sucursales.
 * Maneja un formulario multi-paso para el registro de nuevas sucursales comerciales.
 * @component
 */
const FormularioRegistroSucursal = () => {
    const [form] = Form.useForm();
    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] = useState({ id_rol: 3, codigo_pais: 1 }); // Estado inicial con el rol fijo

    /**
     * Avanza al siguiente paso del formulario y guarda los datos del paso actual.
     */
    const handleNext = () => {
        const stepData = form.getFieldsValue();
        if (stepData.pais === 'colombia') {
            stepData.pais = 1;
        } else if (stepData.pais === 'mexico') {
            stepData.pais = 2;
        }
        setUserData({ ...userData, ...stepData }); // Consolidar los datos de cada paso

        if (currentStep < 4) {
            setCurrentStep(currentStep + 1);
        } else {
            handleSubmit();
        }
    };

    /**
     * Maneja el envío final del formulario y registro de la sucursal.
     * @async
     */
    const handleSubmit = async () => {
        try {
            console.log('Datos del usuario:', userData);
            const response = await axios.post('https://api.99envios.app/api/auth/register_sucursal', userData);
            message.success('Usuario creado exitosamente!');
        } catch (error) {
            message.error('Error al crear el usuario.');
            console.error('Error:', error.response ? error.response.data : error);
        }
    };

    /**
     * Lista de productos/categorías disponibles para la sucursal.
     * @type {Array<{name: string}>}
     */
    const productos = [

        { name: '1. Bisuteria' },
        { name: '2. Ropa Deportiva' },
        { name: '3. Vaporizadores' },
        { name: '4. Mascotas' },
        { name: '5. Moda' },
        { name: '6. Tecnologia' },
        { name: '7. Cocina' },
        { name: '8. Belleza' },
        { name: '9. Salud' },
        { name: '10. Hogar' },
        { name: '11. Natural Home' },
        { name: '12. Deportes' },
        { name: '13. Sex Shop' },
        { name: '14. Bebé' },
        { name: '15. Aseo' },
        { name: '16. Bienestar' },
        { name: '17. Camping' },
        { name: '18. Pesca' },
        { name: '19. Defensa Personal' },
        { name: '20. Vehiculos' },
        { name: '21. Jugueteria' },
        { name: '22. Otros' },



    ];
    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Navbar />
            <div style={{ flex: 1, backgroundColor: 'rgba(132,139,200,0.18)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={require("../../components/img/99envios.png")} alt="Logo" style={{ width: '10%', height: 'auto' }} />
                {currentStep === 1 && (
                    <>
                        <div style={{ width: '40%', height: '15px', backgroundColor: '#d9d9d9', borderRadius: '5px', marginTop: '20px' }}>
                            <div style={{ width: '25%', height: '100%', backgroundColor: '#6C63FF', borderRadius: '5px' }}></div>
                        </div>
                        <br></br>
                        <h2 style={{ color: 'black', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Nos puedes contar más de ti</h2>
                        <Row style={{ width: '50%', marginTop: '20px' }}>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    name="registro"
                                    onFinish={handleSubmit}  // Aquí vinculamos handleSubmit al evento onFinish
                                    layout="vertical"
                                >
                                    <br></br>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Selecciona tu país</span>}
                                                name="pais"
                                                rules={[{ required: true, message: 'Por favor selecciona un país!' }]}
                                            >
                                                <Select
                                                    placeholder="Selecciona tu país"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.border = '1px solid black'}
                                                    onBlur={(e) => e.target.style.border = 'none'}
                                                >
                                                    <Option value="colombia">Colombia</Option>
                                                    <Option value="mexico">Mexico</Option>
                                                    <Option value="otro">Otro</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Nombre de tu negocio</span>}
                                                name="nombre_sucursal"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Por favor ingresa el nombre de tu negocio!'
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Ingresa el nombre de tu negocio"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Dirección de tu negocio</span>}
                                                name="direccion"
                                                rules={[{ required: true, message: 'Por favor ingresa la dirección de tu negocio!' }]}
                                            >
                                                <Input
                                                    placeholder="Ingresa la dirección de tu negocio"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Ciudad</span>}
                                                name="ciudad"
                                                rules={[{ required: true, message: 'Por favor ingresa la ciudad!' }]}
                                            >
                                                <Select
                                                    placeholder="Selecciona la ciudad"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.border = '1px solid black'}
                                                    onBlur={(e) => e.target.style.border = 'none'}
                                                >
                                                    {Ciudades.map(ciudad => (
                                                        <Option key={ciudad.value} value={ciudad.value}>{ciudad.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Tu Nombre</span>}
                                                name="nombre"
                                                rules={[{ required: true, message: 'Por favor ingresa tu nombre!' }]}
                                            >
                                                <Input
                                                    placeholder="Ingresa tu nombre"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Tu Apellido</span>}
                                                name="apellido"
                                                rules={[{ required: true, message: 'Por favor ingresa tu apellido!' }]}
                                            >
                                                <Input
                                                    placeholder="Ingresa tu apellido"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Tipo de Identificación"
                                                name="tipo_identificacion"
                                                rules={[{ required: true, message: 'Por favor selecciona tu tipo de identificación!' }]}
                                            >
                                                <Select>
                                                    <Option value="CC">Cédula de Ciudadanía</Option>
                                                    <Option value="CE">Cédula de Extranjería</Option>
                                                    <Option value="TI">Tarjeta de Identidad</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Número de Identificación"
                                                name="num_identificacion"
                                                rules={[{ required: true, message: 'Por favor ingresa tu número de identificación!' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Email</span>}
                                                name="email"
                                                rules={[
                                                    { required: true, message: 'Por favor ingresa tu email!' },
                                                    { type: 'email', message: '¡Por favor ingresa un email válido!' } // Regla para validar el formato del email
                                                ]}
                                            >
                                                <Input
                                                    type="email" // Establecer el tipo de entrada como email
                                                    placeholder="Ingresa tu email"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>

                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Número de Teléfono</span>}
                                                name="telefono"
                                                rules={[
                                                    { required: true, message: 'Por favor ingresa tu teléfono!' },
                                                    { pattern: /^\d{10}$/, message: '¡Ingresa un número de teléfono válido!' } // Regla para validar números de teléfono de 10 dígitos
                                                ]}
                                            >
                                                <Input
                                                    type="tel" // Establecer el tipo de entrada como teléfono
                                                    placeholder="Ingresa tu teléfono"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>

                        <Button type="primary" className="btnSigAnt" onClick={handleNext}>
                            Siguiente
                        </Button>
                        <br></br>


                        <br></br>
                    </>
                )}
                {currentStep === 2 && (
                    <>
                        <div style={{ width: '40%', height: '15px', backgroundColor: '#d9d9d9', borderRadius: '5px', marginTop: '20px' }}>
                            <div style={{ width: '50%', height: '100%', backgroundColor: '#6C63FF', borderRadius: '5px' }}></div>
                        </div>
                        <br></br>
                        <h2 style={{ color: 'black', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Nos puedes contar más de ti</h2>

                        <Row style={{ width: '50%', marginTop: '20px' }}>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    name="registro"
                                    onFinish={handleSubmit}
                                    layout="vertical"
                                >
                                    <br></br>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                        <Form.Item
                                                name="producto"
                                                label="Selecciona un producto"
                                                rules={[{ required: true, message: 'Por favor selecciona un producto!' }]}
                                            >
                                                <Select placeholder="Selecciona un producto">
                                                    {productos.map(producto => (
                                                        <Option key={producto.name} value={producto.name}>{producto.name}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={24}>

                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>¿Cuántos paquetes estimas que trabajemos contigo mensualmente?</span>}
                                                name="cantidad"
                                                rules={[
                                                    { required: true, message: 'Por favor selecciona el número de paquetes mensuales.' },
                                                    { type: 'integer', message: 'La cantidad debe ser un número entero.' }
                                                ]}
                                            >
                                                <Radio.Group>
                                                    <div className="form-check">
                                                        <Radio className="form-check-input" value={1} id="flexRadioDefault1">
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                1 a 100
                                                            </label>
                                                        </Radio>
                                                    </div>
                                                    <div className="form-check">
                                                        <Radio className="form-check-input" value={2} id="flexRadioDefault2">
                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                Entre 100 y 4.000
                                                            </label>
                                                        </Radio>
                                                    </div>
                                                    <div className="form-check">
                                                        <Radio className="form-check-input" value={3} id="flexRadioDefault3">
                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                Más de 4.000
                                                            </label>
                                                        </Radio>
                                                    </div>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <br></br>
                                </Form>
                            </Col>
                        </Row>



                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="default" className="btnSigAnt white-button" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Anterior
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" className="btnSigAnt" onClick={handleNext}>
                                    Siguiente
                                </Button>
                            </Col>
                        </Row>
                        <br></br>
                        <Link to="/">
                            {/* <Button type="primary" className="white-button" onClick={handleNext}>
                                Ya tengo cuenta
                            </Button> */}
                        </Link>
                        <br></br>
                    </>
                )}
                {currentStep === 3 && (
                    <>
                        <div style={{ width: '40%', height: '15px', backgroundColor: '#d9d9d9', borderRadius: '5px', marginTop: '20px' }}>
                            <div style={{ width: '75%', height: '100%', backgroundColor: '#6C63FF', borderRadius: '5px' }}></div>
                        </div>
                        <br></br>
                        <h2 style={{ color: 'black', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Ya estamos finalizando. Por favor, ingresa una contraseña para crear tu cuenta</h2>


                        <Row style={{ width: '50%', marginTop: '20px' }}>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    name="registro"
                                    onFinish={handleSubmit}
                                    layout="vertical"
                                >
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Contraseña</span>}
                                                name="password"
                                                rules={[
                                                    { required: true, message: 'Por favor ingresa tu contraseña!' },
                                                    { min: 8, message: '*8 caracteres' },
                                                    {
                                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                                                        message: [
                                                            "*Una minúscula",
                                                            "*Una mayúscula",
                                                            "*Un número",
                                                            "*Un cáracter especial"
                                                        ],
                                                    },
                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder="Ingresa tu contraseña"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={<span style={{ color: 'black', fontWeight: 'bold' }}>Repetir Contraseña</span>}
                                                name="password_confirmation"
                                                rules={[
                                                    { required: true, message: 'Por favor ingresa tu contraseña!' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Las contraseñas no coinciden.'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    placeholder="Ingresa tu contraseña"
                                                    className="dark-placeholder"
                                                    style={{
                                                        borderBottom: '1px solid black',
                                                        borderRadius: '0',
                                                        transition: 'border 0.3s',
                                                    }}
                                                    onFocus={(e) => e.target.style.borderRadius = '8px'}
                                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Form.Item
                                                name="terminosCondiciones"
                                                rules={[
                                                    { required: true, message: 'Debes aceptar los términos y condiciones.' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Debes aceptar los términos y condiciones.'));
                                                        },
                                                    }),
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    He leído y acepto los términos y condiciones
                                                </Checkbox>
                                            </Form.Item>

                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="default" className="btnSigAnt white-button" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Anterior
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button type="primary" className="btnSigAnt" onClick={handleNext}>
                                    Siguiente
                                </Button>
                            </Col>
                        </Row>
                        <br></br>
                        <Link to="/">
                            {/* <Button type="primary" className="white-button" onClick={handleNext}>
                                Ya tengo cuenta
                            </Button> */}
                        </Link>
                        <br></br>

                    </>
                )}

                {currentStep === 4 && (
                    <>

                        <div style={{ width: '40%', height: '15px', backgroundColor: '#d9d9d9', borderRadius: '5px', marginTop: '20px' }}>
                            <div style={{ width: '100%', height: '100%', backgroundColor: '#6C63FF', borderRadius: '5px' }}></div>
                        </div>
                        <br></br>
                        <h2 style={{ color: '#6C63FF', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>¡Enhorabuena!</h2>

                        <Row style={{ width: '50%', marginTop: '20px' }}>
                            <Col span={24}>
                                <Form
                                    form={form}
                                    name="registro"
                                    onFinish={handleSubmit}
                                    layout="vertical"
                                >
                                    <br></br>
                                    <div className="textoFinal">
                                        <h4>Has conseguido exitosamente tu registro</h4>
                                        <br />
                                        <h4>Pronto recibirás un mensaje de bienvenida con sugerencias útiles. Te invitamos a iniciar sesión para comenzar a disfrutar de la excepcional experiencia de <span className="resaltado">99Envios</span> y descubrir todas las ventajas que tenemos preparadas para ti. <span className="resaltado">¡Bienvenido!</span></h4>
                                    </div>

                                    <br></br>
                                </Form>
                            </Col>
                        </Row>


                        <Row gutter={16}>
                            <Col span={12}>
                                <Button type="default" className="btnSigAnt white-button" onClick={() => setCurrentStep(currentStep - 1)}>
                                    Anterior
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Link to="/">
                                    <Button type="primary" className="btnSigAnt" onClick={handleSubmit}>
                                        Enviar
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <br></br>
                        <Link to="/">
                            {/* <Button type="primary" className="white-button" onClick={handleNext}>
                                Ya tengo cuenta
                            </Button> */}
                        </Link>
                        <br></br>

                    </>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default FormularioRegistroSucursal;
