import React, { useState, useEffect } from 'react';
import { Spin, Alert } from 'antd';
import axios from 'axios';
import Chart from 'chart.js/auto';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import './DashboardMex.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [idsucursal, setIdsucursal] = useState('');
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [novedadesDiarias, setNovedadesDiarias] = useState([]);
  const [showGraphs, setShowGraphs] = useState(false);
  const [charts, setCharts] = useState([]); // Add this state to store chart instances
  const codigo_sucursal = localStorage.getItem('codigoSucursal');

  const ordenDeseado = [
    "Entregada",
    "Devolución ratificada",
    "Devolucion Regional",
    "Creado",
    "Transito nacional",
    "Centro acopio",
    "Admitida",
    "Mercancía no despachada por el"
  ];

  const estadosOrdenados = dashboardData?.data.estados_envios_cartera
    ? dashboardData.data.estados_envios_cartera.sort((a, b) =>
      ordenDeseado.indexOf(a.estado_del_envio) - ordenDeseado.indexOf(b.estado_del_envio)
    )
    : [];

  const usuario_id = localStorage.getItem('usuario_id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  useEffect(() => {
    if (idsucursal) {
      fetchData();
    }
  }, [idsucursal, startDate, endDate]);

  const fetchData = async () => {
    setLoading(true); // Inicia el loader
    const desde = startDate || moment().subtract(1, 'year').format('YYYY-MM-DD');
    const hasta = endDate || moment().format('YYYY-MM-DD');

    try {
      const usuario_id = localStorage.getItem('usuario_id');
      const response = await axios.get(`https://99envios.app/api/online/home/${usuario_id} `, {
        params: {
          fecha_desde: desde,
          fecha_hasta: hasta,
        },
        headers: {
          'Accept': 'application/json'
        }
      });
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    } finally {
      setLoading(false); // Detiene el loader independientemente del resultado
    }
  };

  const estadosUnificados = dashboardData?.data.estados_envios_cartera.reduce((acumulador, estado) => {
    const estadoNombre = estado.estado_del_envio;

    if (estadoNombre === "Entregada" || estadoNombre === "Entregado") {
      const entregadoExistente = acumulador.find(e => e.estado_del_envio === "Entregado");
      if (entregadoExistente) {
        entregadoExistente.total_envios += estado.total_envios;
      } else {
        acumulador.push({ estado_del_envio: "Entregado", total_envios: estado.total_envios });
      }
    } else {
      // Mantiene el resto de los estados tal como están
      acumulador.push(estado);
    }

    return acumulador;
  }, []);


  const totalEnvios = dashboardData?.data.estados_envios_cartera
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  const totalEntregado = dashboardData?.data.estados_envios_cartera
    .filter((estado) => ["Entregada", "Entregado"].includes(estado.estado_del_envio))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  const devoluciones = dashboardData?.data.estados_envios_cartera
    .filter((estado) => ["Devolución ratificada", "Devolucion Regional"].includes(estado.estado_del_envio))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;

  // Nuevo cálculo de tasas
  const tasaDevolucion = totalEnvios > 0 ? (devoluciones / totalEnvios) * 100 : 0;
  const tasaEntrega = totalEnvios > 0 ? 100 - tasaDevolucion : 0;

  // Formatear las tasas para mostrar
  const tasaDevolucionFormateada = tasaDevolucion.toFixed(2) + '%';
  const tasaEntregaFormateada = tasaEntrega.toFixed(2) + '%';

  const estadoEnvios = dashboardData?.data.estados_envios_cartera || [];
  const destinosUsados = dashboardData?.data.destinos_mas_usados || [];
  const devolucionesFrecuentes = dashboardData?.data.resultado_en_movimiento || [];
  const enviosCompleto = dashboardData?.datalist.ordenes_recientes || [];
  const carteraCompleta = dashboardData?.datalist.cartera_recientes || [];
  const consolidadoConsignaciones = dashboardData?.datalist.consolidado_cartera || [];

  const fetchModalMessage = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/online/modals');
      if (response.data && response.data.activo) {
        setModalMessage(response.data);
      }
    } catch (error) {
      console.error('Error al obtener el mensaje del modal', error);
    }
  };

  const fetchNovedadesDiarias = async () => {
    try {
      const response = await axios.get(`https://api.99envios.app/public/api/online/novedades-beta/${codigo_sucursal}`);
      setNovedadesDiarias(response.data);
    } catch (error) {
      console.error('Error al obtener las novedades diarias', error);
    }
  };

  useEffect(() => {
    fetchModalMessage();
    fetchNovedadesDiarias();
  }, []);

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  // Add this useEffect for chart initialization
  useEffect(() => {
    if (showGraphs && dashboardData && !loading) {
      // Destroy previous charts if they exist
      charts.forEach(chart => chart.destroy());
      
      // Initialize estados envíos chart
      const estadosCtx = document.getElementById('estadosEnviosChart');
      const estadosChart = new Chart(estadosCtx, {
        type: 'bar',
        data: {
          labels: estadosUnificados.map(estado => estado.estado_del_envio),
          datasets: [{
            label: 'Total de envíos',
            data: estadosUnificados.map(estado => estado.total_envios),
            backgroundColor: '#7380ec',
            borderColor: '#7380ec',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1
              }
            }
          }
        }
      });

      // Initialize destinos chart
      const destinosCtx = document.getElementById('destinosChart');
      const destinosChart = new Chart(destinosCtx, {
        type: 'bar',
        data: {
          labels: destinosUsados.map(destino => destino.ciudad_destino),
          datasets: [{
            label: 'Total de envíos por destino',
            data: destinosUsados.map(destino => destino.total_envios),
            backgroundColor: '#41f1b6',
            borderColor: '#41f1b6',
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1
              }
            }
          }
        }
      });

      // Initialize tasas chart
      const tasasCtx = document.getElementById('tasasChart');
      const tasasChart = new Chart(tasasCtx, {
        type: 'bar',
        data: {
          labels: ['Tasa de Entrega', 'Tasa de Devolución'],
          datasets: [{
            label: 'Porcentaje',
            data: [
              tasaEntrega,
              tasaDevolucion
            ],
            backgroundColor: [
              '#4caf50',
              '#f44336'
            ],
            borderColor: [
              '#4caf50',
              '#f44336'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              max: 100,
              ticks: {
                callback: function(value) {
                  return value + '%';
                }
              }
            }
          }
        }
      });

      // Store chart instances
      setCharts([estadosChart, destinosChart, tasasChart]);
    }

    // Cleanup function
    return () => {
      charts.forEach(chart => chart.destroy());
    };
  }, [showGraphs, dashboardData, loading]);

  return (
    <>
      <NavbarMex title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <span>Dashboard</span>
          <button 
            onClick={() => setShowGraphs(!showGraphs)}
            className="graph-button"
            style={{
              padding: '8px 16px',
              backgroundColor: '#1890ff',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            {showGraphs ? 'Ver Dashboard' : 'Ver Gráficos'}
          </button>
        </div>
      } />
      <div className="dashboard-containerM">
      {modalMessage && modalMessage.activo && (
        <Alert 
          message={modalMessage.mensaje} 
          type="info" 
          closable 
          style={{ marginBottom: '16px' }}
        />
      )}
        <div className="date-pickerM-containerM">
          <div className="date-pickerM-row">
            <div className="date-pickerM">
              <label>Fecha inicio:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-pickerM">
              <label>Fecha de corte:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="button-container">
            <button onClick={fetchData} className="search-buttonM">Buscar Resultados</button>
          </div>
        </div>

        {showGraphs ? (
          <div className="graphs-container">
            {loading ? (
              <div className="loading-container">
                <Spin size="large" />
              </div>
            ) : dashboardData && (
              <div className="graphs-layout">
                {/* Destinos más Usados Gráfico - Arriba */}
                <div className="graph-card destinos">
                  <h3>Destinos más Usados</h3>
                  <div className="graph-content">
                    <canvas id="destinosChart"></canvas>
                  </div>
                </div>

                <div className="graphs-bottom">
                  {/* Estado de Envíos Gráfico - Abajo Izquierda */}
                  <div className="graph-card estados">
                    <h3>Estado de Envíos</h3>
                    <div className="graph-content">
                      <canvas id="estadosEnviosChart"></canvas>
                    </div>
                  </div>

                  {/* Tasa de Entrega vs Devolución - Abajo Derecha */}
                  <div className="graph-card tasas">
                    <h3>Tasa de Entrega vs Devolución</h3>
                    <div className="graph-content">
                      <canvas id="tasasChart"></canvas>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          loading ? (
            <div className="loading-container">
              <Spin size="large" /> {/* Rueda de carga de Ant Design */}
            </div>
          ) : (
            dashboardData && (
              <div className="cards-containerDM">
                {/* Tarjetas principales */}
                <div className="rowDM">
                  <div className="cardDM">
                    <div className="icon-circle-DM local-shipping-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>local_shipping</span>
                    </div>
                    <h3>Total Envíos</h3>
                    <p className="main-number">{totalEnvios}</p>
                  </div>
                  <div className="cardDM">
                    <div className="icon-circle-DM check-circle-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>trending_up</span>
                    </div>
                    <h3>Total Entregado</h3>
                    <p className="main-number">{totalEntregado}</p>
                    {/* <div className="rate-container">
                      <span className="material-icons success-icon">check_circle</span>
                      <p className="rate">Tasa de Entrega</p>
                    </div>
                    <p className="success" style={{ color: "#4caf50", textAlign: "left", fontSize: "18px" }}>
                      {tasaEntrega}
                    </p> */}
                  </div>
                  <div className="cardDM">
                    <div className="icon-circle-DM report-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>repartition</span>
                    </div>
                    <h3>Devoluciones</h3>
                    <p className="main-number">{devoluciones}</p>
                    <div className="rate-container">
                      <span className="material-icons error-icon">cancel</span>
                      <p className="rate">Tasa de Devolución</p>
                    </div>
                    <p className="success" style={{ color: "#F44336", textAlign: "left", fontSize: "18px" }}>
                      {tasaDevolucionFormateada}
                    </p>
                  </div>
                </div>

                <div className="rowDM">
                  {/* Tarjeta: Estado envíos */}
                  <div className="cardDM">
                    <div className="icon-circle-DM local-shipping-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>stacked_line_chart</span>
                    </div>
                    <h3>Estado Envios</h3>
                    <table className="card-table-DM">
                      <tbody>
                        {estadosUnificados.map((estado, index) => (
                          <tr key={index}>
                            <td>{estado.estado_del_envio || "Desconocido"}</td>
                            <td className="number-cell-DM">{estado.total_envios}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* Tarjeta: Destinos más usados */}

                  <div className="cardDM">
                    <div className="icon-circle-DM check-circle-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>scale</span>
                    </div>
                    <h3>Destinos Más Usados</h3>
                    <table className="card-table-DM">
                      <tbody>
                        {(showMore ? destinosUsados : destinosUsados.slice(0, 5)).map((destino, index) => (
                          <tr key={index}>
                            <td>{destino.ciudad_destino}</td>
                            <td className="number-cell-DM">{destino.total_envios}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {destinosUsados.length > 5 && (
                      <span
                        onClick={() => setShowMore(!showMore)}
                        style={{ color: "green", cursor: "pointer", textDecoration: "underline", marginLeft: "40%", marginTop: "2%" }}
                      >
                        {showMore ? "Ver menos" : "Ver más"}
                      </span>
                    )}
                  </div>



                  {/* Tarjeta: Devoluciones frecuentes */}
                  <div className="cardDM">
                    <div className="icon-circle-DM report-icon">
                      <span className="material-icons-sharp" style={{ fontSize: '28px' }}>warning</span>
                    </div>
                    <h3>Devoluciones Frecuentes</h3>
                    <table className="card-table-DM">
                      <tbody>
                        {devolucionesFrecuentes.map((movimiento, index) => (
                          <tr key={index}>
                            <td>{movimiento.telefono}</td>
                            <td className="number-cell-DM">{movimiento.num_ocurrencias}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Tabla de Novedades Diarias */}
                <div className="dashboardMex-container">
                  <h3>Novedades Diarias</h3>
                  <table className="dashboardMex-table">
                    <thead>
                      <tr>
                        <th>Número de Guía</th>
                        <th>Novedad Sucursal</th>
                        <th>Tipo de Novedad</th>
                        <th>Ciudad</th>
                        <th>Fecha de Actualización</th>
                      </tr>
                    </thead>
                    <tbody>
                      {novedadesDiarias.length > 0 ? (
                        novedadesDiarias.map((novedad, index) => (
                          <tr key={index}>
                            <td>{novedad.numero_preenvio}</td>
                            <td>{novedad.novedad_sucursal}</td>
                            <td>{novedad.tipo_novedad}</td>
                            <td>{novedad.ciudad_destinatario}</td>
                            <td>{formatDateForDisplay(novedad.updated_at)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No hay novedades diarias disponibles</td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                </div>
                <button
                  className="dashboardMex-button"
                  onClick={() => navigate('/novedades')}
                >
                  Gestionar Novedades
                </button>

                {/* Tabla de Envíos Completo */}
                <div className="dashboardMex-container">
                  <h3>Envíos Completos</h3>
                  <table className="dashboardMex-table">
                    <thead>
                      <tr>
                        <th>Número de Guía</th>
                        <th>Fecha de envio</th>
                        <th>Valor comercial</th>
                        <th>Ciudad</th>
                        <th>Producto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enviosCompleto.map((envios, index) => (
                        <tr key={index}>
                          <td>{envios.numero_de_guia}</td>
                          <td>{envios.fecha_envio}</td>
                          <td>{envios.valor_comercial}</td>
                          <td>{envios.ciudad_destino}</td>
                          <td>{envios.Producto}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <button
                  className="dashboardMex-button"
                  onClick={() => navigate('/envios-completos')}
                >
                  Ver más
                </button>

                {/* Tabla de Cartera Completa */}
                <div className="dashboardMex-container">
                  <h3>Cartera Completa</h3>
                  <table className="dashboardMex-table">
                    <thead>
                      <tr>
                        <th>Número de Guía</th>
                        <th>Valor Comercial</th>
                        <th>Valor Flete</th>
                        <th>Valor Consignado</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {carteraCompleta.map((cartera, index) => (
                        <tr key={index}>
                          <td>{cartera.numero_de_guia}</td>
                          <td>{cartera.valor_comercial}</td>
                          <td>{cartera.valor_flete}</td>
                          <td>{cartera.valor_consignado}</td>
                          <td>{cartera.estado_del_envio}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>
                <button
                  className="dashboardMex-button"
                  onClick={() => navigate('/cartera-completa')}
                >
                  Ver más
                </button>

                {/* Tabla de Consolidado Consignaciones */}
                <div className="dashboardMex-container">
                  <h3>Consolidado Consignaciones</h3>
                  <table className="dashboardMex-table">
                    <thead>
                      <tr>
                        <th>Fecha de Consignación</th>
                        <th>Valor Consignado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consolidadoConsignaciones.map((consolidadoC, index) => (
                        <tr key={index}>
                          <td>{consolidadoC.fecha_de_pago}</td>
                          <td>{consolidadoC.total_por_dia}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            )
          )
        )}
      </div>
      <BotonWhatsapp />
    </>
  );

};

export default Dashboard;