import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Spin, Select, Upload, Row, Col, Checkbox } from 'antd';
import { UploadOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import './inventarioCrud.css';

const { Option } = Select;

const InventoryDrop = () => {
    const [productos, setProductos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [newProductData, setNewProductData] = useState({
        id_categoria: '',
        nombre_producto: '',
        descripcion_producto: '',
        cantidad_disponible: '',
        peso_producto: '',
        color: '',
        talla: '',
        precio_sugerido: '',
        sku: '',
        fotoproducto: [],
        variaciones: [],
        estado_variacion: false
    });
    const [form] = Form.useForm();

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            const url = `https://api.99envios.app/api/inventarios`;
            try {
                const { data } = await axios.get(url);
                setProductos(data);
            } catch (error) {
                message.error('Error al cargar productos');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const resetNewProductData = () => {
        setNewProductData({
            id_categoria: '',
            nombre_producto: '',
            descripcion_producto: '',
            cantidad_disponible: '',
            peso_producto: '',
            color: '',
            talla: '',
            precio_sugerido: '',
            sku: '',
            fotoproducto: [],
            variaciones: [],
            estado_variacion: false
        });
        form.resetFields();
    };

    const showModal = (product) => {
        const tieneVariaciones = product.variaciones && product.variaciones.length > 0;

        setEditingProduct(product);
        setNewProductData({
            id_categoria: product.id_categoria,
            nombre_producto: product.nombre_producto,
            descripcion_producto: product.descripcion_producto,
            cantidad_disponible: product.cantidad_disponible,
            peso_producto: product.peso_producto,
            color: '',
            talla: '',
            precio_sugerido: product.precio_sugerido,
            sku: product.sku,
            fotoproducto: [],
            variaciones: product.variaciones.map(variation => ({
                id_variacion: variation.id_variacion || '',
                id_producto: variation.id_producto || product.id_producto,
                talla: variation.talla || '',
                color: variation.color || '',
                cantidad_disponible: variation.cantidad_disponible || '',
                sku: variation.sku || '',
            })),
            estado_variacion: tieneVariaciones,
        });
        setIsModalVisible(true);
        form.setFieldsValue({
            id_categoria: product.id_categoria,
            nombre_producto: product.nombre_producto,
            descripcion_producto: product.descripcion_producto,
            cantidad_disponible: product.cantidad_disponible,
            peso_producto: product.peso_producto,
            precio_sugerido: product.precio_sugerido,
            sku: product.sku,
            estado_variacion: tieneVariaciones,
        });
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const updatedProduct = { ...newProductData, ...values };

            // Convertir el peso de gramos a kilogramos
            const pesoEnKilogramos = updatedProduct.peso_producto ? 
                Number((updatedProduct.peso_producto / 1000).toFixed(2)) : 0;

            const dataToSend = new FormData();

            // Agregar campos básicos con el peso convertido
            Object.keys(updatedProduct).forEach(key => {
                if (key !== 'imagenes' && key !== 'variaciones') {
                    const value = key === 'peso_producto' ? pesoEnKilogramos : updatedProduct[key];
                    dataToSend.append(key, value);
                }
            });

            const formattedVariations = updatedProduct.variaciones.map(variation => ({
                id_variacion: variation.id_variacion || '',
                id_producto: editingProduct.id_producto,
                talla: variation.talla,
                color: variation.color,
                cantidad_disponible: parseInt(variation.cantidad_disponible, 10), // Asegurando que sea un entero
                sku: variation.sku,
            }));

            dataToSend.append('variaciones', JSON.stringify(formattedVariations));

            const url = `https://api.99envios.app/api/inventarios/${editingProduct.id_producto}`;
            try {
                await axios.put(url, dataToSend);
                setProductos(prev => prev.map(product => product.id_producto === editingProduct.id_producto ? { ...product, ...updatedProduct } : product));
                message.success('Producto actualizado correctamente');
            } catch (error) {
                message.error('Error al actualizar el producto');
            } finally {
                setIsModalVisible(false);
                resetNewProductData();
            }
        } catch (error) {
            console.error('Error al actualizar el producto:', error);
            message.error('Error al actualizar el producto');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsNewProductModalVisible(false);
        resetNewProductData();
    };

    const handleDelete = async (id_producto) => {
        const url = `https://api.99envios.app/api/inventarios/${id_producto}`;
        try {
            await axios.delete(url);
            setProductos(prev => prev.filter(product => product.id_producto !== id_producto));
            message.success('Producto eliminado correctamente');
        } catch (error) {
            message.error('Error al eliminar el producto');
        }
    };

    const showDeleteConfirm = (product) => {
        Modal.confirm({
            title: '¿Estás seguro de eliminar este producto?',
            content: `Nombre del producto: ${product.nombre_producto}`,
            okText: 'Sí, eliminar',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleDelete(product.id_producto);
            },
            onCancel() {
                console.log('Cancelado');
            },
        });
    };

    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProductData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = ({ fileList }) => {
        setNewProductData(prev => ({
            ...prev,
            fotoproducto: fileList.map(file => file.originFileObj)
        }));
    };

    const handleVariationsChange = (field, value, index) => {
        const updatedVariations = [...newProductData.variaciones];
        updatedVariations[index][field] = value;
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleAddVariation = () => {
        setNewProductData(prev => ({
            ...prev,
            variaciones: [...prev.variaciones, { talla: '', color: '', cantidad_disponible: '', sku: '' }]
        }));
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = newProductData.variaciones.filter((_, i) => i !== index);
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleNewProductSave = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();

            // Convertir el peso de gramos a kilogramos y redondear a 2 decimales
            const pesoEnKilogramos = values.peso_producto ? Number((values.peso_producto / 1000).toFixed(2)) : 0;

            // Agregar campos básicos
            Object.keys(values).forEach(key => {
                if (key !== 'fotoproducto' && key !== 'variaciones') {
                    // Si es el campo de peso, usar el valor convertido
                    const value = key === 'peso_producto' ? pesoEnKilogramos : values[key];
                    formData.append(key, value);
                }
            });
    
            // Verificar y agregar imágenes
            if (newProductData.fotoproducto.length > 0) {
                newProductData.fotoproducto.forEach((file, index) => {
                    formData.append(`fotoproducto[${index}]`, file.originFileObj);
                });
            } else {
                console.error('fotoproducto no es una matriz:', newProductData.fotoproducto);
            }
    
            // Agregar variaciones si existen
            if (newProductData.estado_variacion === 1) {
                newProductData.variaciones.forEach((variation, index) => {
                    Object.keys(variation).forEach(key => {
                        formData.append(`variaciones[${index}][${key}]`, variation[key]);
                    });
                });
            }
    
            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            const response = await axios.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
    
            setProductos(prev => [...prev, response.data]);
            message.success('Nuevo producto creado con éxito');
            setIsNewProductModalVisible(false);
            resetNewProductData();
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al crear el producto: ' + error.message);
        }
    };
    
    const sizes = [
        { value: 'S', label: 'S' },
        { value: 'M', label: 'M' },
        { value: 'L', label: 'L' },
        { value: 'XL', label: 'XL' },
        { value: 'XXL', label: 'XXL' },
        // otras
      ];
      const colors = [
        { value: 'Negro', label: 'Negro' },
        { value: 'Blanco', label: 'Blanco' },
        { value: 'Rojo', label: 'Rojo' },
        { value: 'Verde', label: 'Verde' },
        { value: 'Azul', label: 'Azul' },
        { value: 'Amarillo', label: 'Amarillo' },
        { value: 'Naranja', label: 'Naranja' },
        { value: 'Morado', label: 'Morado' },
        { value: 'Rosa', label: 'Rosa' },
        { value: 'Marrón', label: 'Marrón' },
        { value: 'Gris', label: 'Gris' },
        { value: 'Turquesa', label: 'Turquesa' },
        { value: 'Dorado', label: 'Dorado' },
        { value: 'Plata', label: 'Plata' },
        { value: 'Rojo oscuro', label: 'Rojo oscuro' },
        { value: 'Cian', label: 'Cian' },
        { value: 'Índigo', label: 'Índigo' },
        { value: 'DeepPink', label: 'DeepPink' },
        { value: 'Rojo claro', label: 'Rojo claro' },
        { value: 'Gris claro', label: 'Gris claro' },
        { value: 'Verde amarillento', label: 'Verde amarillento' }
      ];

    const handleCheckboxChange = (e) => {
        setNewProductData(prev => ({
            ...prev,
            estado_variacion: e.target.checked
        }));
    };

    const renderVariations = (variaciones) => {
        if (!variaciones.length) return 'Sin variaciones';
        return variaciones.map((variation, index) => (
            <div key={index}>
                <strong>Talla:</strong> {variation.talla} &nbsp;
                <strong>Color:</strong> {variation.color} &nbsp;
                <strong>Cantidad:</strong> {variation.cantidad_disponible} &nbsp;
                <strong>SKU:</strong> {variation.sku}
            </div>
        ));
    };

    const columns = [
        { title: 'ID', dataIndex: 'id_producto', key: 'id_producto' },
        { title: 'Nombre', dataIndex: 'nombre_producto', key: 'nombre_producto' },
        { title: 'Descripción', dataIndex: 'descripcion_producto', key: 'descripcion_producto' },
        { title: 'Precio Sugerido', dataIndex: 'precio_sugerido', key: 'precio_sugerido' },
        { title: 'Cantidad Disponible', dataIndex: 'cantidad_disponible', key: 'cantidad_disponible' },
        { title: 'SKU', dataIndex: 'sku', key: 'sku' },
        {
            title: 'Variaciones',
            key: 'variaciones',
            render: (text, record) => renderVariations(record.variaciones),
        },
        {
            title: 'Acciones',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Button type="link" onClick={() => showModal(record)}>Editar</Button>
                    <Button type="link" onClick={() => showDeleteConfirm(record)} danger>Eliminar</Button>
                </div>
            ),
        },
    ];

    if (isLoading) return <Spin size="large" center />;

    return (
        <>
            <Navbar />
            <div className="table-containerC">
                <Button type="primary" onClick={() => { setIsNewProductModalVisible(true); resetNewProductData(); }}>
                    Agregar Producto
                </Button>
                <Table dataSource={productos} columns={columns} rowKey="id_producto" pagination={{ pageSize: 5 }} />
            </div>

            <Modal
                title={editingProduct ? "Editar producto" : "Agregar nuevo producto"}
                visible={isModalVisible || isNewProductModalVisible}
                onOk={editingProduct ? handleOk : handleNewProductSave}
                onCancel={handleCancel}
                centered
                width={1100}
                heigth={1400}
                style={{ fontSize: "13px" }}
                footer={[
                    <Button style={{height: "44px"}} key="back" onClick={handleCancel}>
                        Cancelar
                    </Button>,
                    <Button style={{height: "44px"}} key="submit" type="primary" onClick={editingProduct ? handleOk : handleNewProductSave}>
                        {editingProduct ? "Guardar" : "Agregar"}
                    </Button>,
                ]}
            >
                <div className="ant-modal-body" style={{ fontSize: "13px" }}>
                    <Form form={form} layout="vertical" style={{ width: '100%', fontSize: "13px" }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Categoría del producto" name="id_categoria" style={{ textAlign: 'left' }}>
                                    <Select
                                        onChange={(value) => handleNewProductChange({ target: { name: 'id_categoria', value } })}
                                        value={newProductData.id_categoria}
                                        placeholder="Escoge una categoría"
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="">Escoge una categoría</Option>
                                        <option value="1">Bisuteria</option>
                                        <option value="2">Ropa Deportiva</option>
                                        <option value="3">Vaporizadores</option>
                                        <option value="4">Mascotas</option>
                                        <option value="5">Moda</option>
                                        <option value="6">Tecnologia</option>
                                        <option value="7">Cocina</option>
                                        <option value="8">Belleza</option>
                                        <option value="9">Salud</option>
                                        <option value="10">Hogar</option>
                                        <option value="11">Natural Home</option>
                                        <option value="12">Deportes</option>
                                        <option value="13">Sex Shop</option>
                                        <option value="14">Bebé</option>
                                        <option value="15">Aseo</option>
                                        <option value="16">Bienestar</option>
                                        <option value="17">Camping</option>
                                        <option value="18">Pesca</option>
                                        <option value="19">Defensa Personal</option>
                                        <option value="20">Vehiculos</option>
                                        <option value="21">Jugueteria</option>
                                        <option value="22">Otros</option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Nombre del producto" name="nombre_producto" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="Nombre del producto"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Descripción del producto" name="descripcion_producto" style={{ textAlign: 'left' }}>
                                    <Input.TextArea
                                        onChange={handleNewProductChange}
                                        placeholder="Descripción"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Cantidad disponible" name="cantidad_disponible" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Cantidad"
                                        min={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Proveedor" name="precio_proveedor" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio Proveedor"
                                        min={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="SKU" name="sku" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="SKU"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item label="Precio Sugerido" name="precio_sugerido" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio sugerido"
                                        min={1}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Peso (en gramos)" name="peso_producto" style={{ textAlign: 'left' }}>
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Peso (en gramos)"
                                        min={1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item label="Fotografía del producto" name="fotoproducto">
                                    <Upload
                                        name="fotoproducto"
                                        listType="picture-card"
                                        fileList={newProductData.fotoproducto}
                                        onChange={handleFileChange}
                                        beforeUpload={() => false}
                                        multiple
                                    >
                                        {newProductData.fotoproducto.length >= 8 ? null : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item name="estado_variacion" valuePropName="checked">
                                    <CustomCheckbox
                                        checked={newProductData.estado_variacion}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {newProductData.estado_variacion && (
                            <>
                                {newProductData.variaciones.map((variation, index) => (
                                    <Row gutter={[16, 16]} key={index}>
                                        <Col span={6}>
                                            <Form.Item label="Talla" name={['variaciones', index, 'talla']}>
                                                <Select
                                                    value={variation.talla}
                                                    onChange={(value) => handleVariationsChange('talla', value, index)}
                                                    placeholder="Selecciona una talla"
                                                    style={{ width: '80px' }}
                                                >
                                                    {sizes.map(size => (
                                                        <Option key={size.value} value={size.value}>
                                                            {size.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Color" name={['variaciones', index, 'color']}>
                                                <Select
                                                    value={variation.color}
                                                    onChange={(value) => handleVariationsChange('color', value, index)}
                                                    placeholder="Selecciona un color"
                                                    style={{ width: '120px' }}
                                                >
                                                    {colors.map(color => (
                                                        <Option key={color.value} value={color.value}>
                                                            {color.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="Cantidad Disponible" name={['variaciones', index, 'cantidad_disponible']}>
                                                <Input
                                                    type="number"
                                                    value={variation.cantidad_disponible}
                                                    onChange={(e) => handleVariationsChange('cantidad_disponible', e.target.value, index)}
                                                    placeholder="Cantidad"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="SKU" name={['variaciones', index, 'sku']}>
                                                <Input
                                                    value={variation.sku}
                                                    onChange={(e) => handleVariationsChange('sku', e.target.value, index)}
                                                    placeholder="SKU"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Button
                                            type="danger"
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => handleRemoveVariation(index)}
                                        >
                                            Eliminar
                                        </Button>
                                    </Row>
                                ))}
                                <Button
                                    type="dashed"
                                    onClick={handleAddVariation}
                                    icon={<PlusOutlined />}
                                    style={{ width: '100%', marginTop: '16px' }}
                                >
                                    Añadir variación
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            </Modal>
            <BotonWhatsapp />                           
            <Footer />
        </>
    );
};

const CustomCheckbox = ({ checked, onChange }) => (
    <div 
        className="custom-checkbox-container"
        onClick={() => onChange({ target: { checked: !checked } })}
        style={{
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',
            padding: '8px 16px',
            border: '2px solid #1890ff',
            borderRadius: '6px',
            backgroundColor: checked ? '#1890ff' : 'white',
            color: checked ? 'white' : '#1890ff',
            transition: 'all 0.3s',
            fontWeight: '500',
            marginBottom: '20px'
        }}
    >
        <div style={{
            width: '20px',
            height: '20px',
            border: checked ? '2px solid white' : '2px solid #1890ff',
            borderRadius: '4px',
            marginRight: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: checked ? '#1890ff' : 'white'
        }}>
            {checked && (
                <svg 
                    viewBox="0 0 24 24" 
                    width="14" 
                    height="14"
                    style={{ fill: 'white' }}
                >
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                </svg>
            )}
        </div>
        Añadir variaciones
    </div>
);

export default InventoryDrop;
