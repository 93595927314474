import axios from 'axios'; 
import React, { useState, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import NavbarMex from '../components/navbarMex';
import BotonWhatsapp from '../botonWhatsapp/botonWhatsapp';
import { message, Select, Modal, Alert, Button } from 'antd';
import { useParams } from 'react-router-dom'; // Importa useParams
import Ciudades from '../../ciudades/ciudades';
import './ShippingForm.css';

const { Option } = Select;

const ShippingForm = () => {
  const { id } = useParams(); // Obtiene el id desde la URL
  const [cotizacionResult, setCotizacionResult] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [volumetricWeight, setVolumetricWeight] = useState(0);
  const [finalWeight, setFinalWeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedTransportadora, setSelectedTransportadora] = useState(null);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);
  const [sucursalId, setSucursalId] = useState(null);
  const [formattedValue, setFormattedValue] = useState('');
  const [cotizarDisabled, setCotizarDisabled] = useState(false);
  const [generarGuiaDisabled, setGenerarGuiaDisabled] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [numeroPreenvio, setNumeroPreenvio] = useState('');
  const confirmationRef = useRef(null);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);
  const [isTransportadoraModalOpen, setIsTransportadoraModalOpen] = useState(false);
  const [offices, setOffices] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState('');
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [statusWhatsapp, setStatusWhatsapp] = useState(false);
  const [isWhatsappModalVisible, setIsWhatsappModalVisible] = useState(false);
  const [whatsappNumber, setWhatsappNumber] = useState('');

  const showErrorModal = (message) => {
    // Cerrar el modal de cotización si está abierto
    if (modalIsOpen) {
      setModalIsOpen(false);
    }
    setErrorModalMessage(message);
    setIsErrorModalVisible(true);
  };

  const handleErrorModalCancel = () => {
    setIsErrorModalVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (confirmationRef.current && !confirmationRef.current.contains(event.target)) {
        setConfirmationMessage('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Limpieza del evento
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const initialFormDataGuia = {
    fecha: '',
    AplicaContrapago: false,
    Peso: '',
    Largo: '',
    Ancho: '',
    Alto: '',
    DiceContener: '',
    ValorDeclarado: '',
    tipoDocumento: '',
    numeroDocumento: '',
    nombre: '',
    primerApellido: '',
    segundoApellido: '',
    telefono: '',
    direccion: '',
    correo: '',
    idLocalidad: '',
    Observaciones: ''
  };

  const [formDataGuia, setFormDataGuia] = useState(initialFormDataGuia);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    if (name === 'DiceContener') {
      setFormDataGuia({
        ...formDataGuia,
        [name]: updatedValue,
        Observaciones: updatedValue // Actualiza también Observaciones
      });
    } else {
      setFormDataGuia({
        ...formDataGuia,
        [name]: updatedValue,
      });
    }

    if (name === 'Peso' || name === 'ValorDeclarado') {
      const peso = name === 'Peso' ? parseFloat(updatedValue) : parseFloat(formDataGuia.Peso);
      const valorDeclarado = name === 'ValorDeclarado' ? parseFloat(updatedValue) : parseFloat(formDataGuia.ValorDeclarado);

      let valorDeclaradoMinimo;
      if (peso >= 1 && peso <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (peso >= 3 && peso <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (peso >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        setErrorMessage(`El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${peso} kg.`);
      } else {
        setErrorMessage('');
      }
    }
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem('id', id);
    }
  }, [id]);

  useEffect(() => {
    setFormData({
      destinationCity: '',
      declaredValue: '50000',
      deliveryType: 'direccion',
      shipmentType: 'paquete',
      grossWeight: '1',
      length: '10',
      width: '10',
      height: '10',
      contentDescription: '',
      observations: '',
      cashOnDelivery: true,
    });
  }, []);

  const [formData, setFormData] = useState({
    destinationCity: '',
    declaredValue: '50000',
    deliveryType: '',
    shipmentType: '',
    grossWeight: '',
    length: '',
    width: '',
    height: '',
    contentDescription: '',
    observations: '',
    cashOnDelivery: true,
  });

  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, ""));
  };

  const [formattedDeclaredValue, setFormattedDeclaredValue] = useState('');

  useEffect(() => {
    // Formatear el valor declarado inicial
    setFormattedDeclaredValue(formatCurrency(formData.declaredValue));
  }, []);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'declaredValue') {
      // Eliminar todos los caracteres no numEricos
      const numericValue = value.replace(/[^0-9]/g, '');

      // Actualizar el estado con el valor numErico
      setFormData(prevState => ({
        ...prevState,
        [name]: numericValue ? parseInt(numericValue, 10) : 0
      }));

      // Actualizar el valor formateado
      setFormattedDeclaredValue(formatCurrency(numericValue ? parseInt(numericValue, 10) : 0));
    } else if (name === 'cashOnDelivery') {
      // Show alert when unchecking cashOnDelivery
      if (!checked) {
        Modal.confirm({
          title: 'Aviso Importante',
          content: 'Si decides realizar la guía sin contra pago, ten en cuenta que se generará un cobro directo a tu saldo. Para proceder con la anulación y la devolución del pago realizado, este proceso puede tomar entre 7 y 15 días hábiles. Es importante que estés completamente seguro de realizar esta acción, ya que el proceso involucra varios pasos y lleva un tiempo para ser completado.',
          okText: 'Entendido',
          cancelText: 'Cancelar',
          okButtonProps: { style: { height: '44px' } },
          cancelButtonProps: { style: { height: '44px' } },
          onOk() {
            setFormData(prevState => ({
              ...prevState,
              [name]: checked
            }));
          },
          onCancel() {
            // If user cancels, keep checkbox checked
            setFormData(prevState => ({
              ...prevState,
              [name]: true
            }));
          }
        });
      } else {
        setFormData(prevState => ({
          ...prevState,
          [name]: checked
        }));
      }
    } else {
      // Para otros campos, mantener la lOgica existente
      const updatedValue = type === 'checkbox' ? checked : value;
      setFormData(prevState => ({
        ...prevState,
        [name]: updatedValue
      }));
    }

    if (name === 'deliveryType') {
      setFormDataGuia(prevState => ({
        ...prevState,
        numeroDocumento: ''
      }));
    }
  };

  useEffect(() => {
    if (formData.declaredValue) {
      const numValue = parseInt(formData.declaredValue, 10);
      if (!isNaN(numValue)) {
        const formattedBalance = numValue.toLocaleString('es-CO', {
          style: 'currency',
          currency: 'COP',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
        setFormattedValue(formattedBalance);
      } else {
        setFormattedValue('');
      }
    } else {
      setFormattedValue('');
    }
  }, [formData.declaredValue]);

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'declaredValue') {
      const parsedValue = parseCurrency(value);
      setFormData({
        ...formData,
        declaredValue: parsedValue,
        declaredValueFormatted: formatCurrency(parsedValue)
      });
    }
  };

  useEffect(() => {
    const largo = parseFloat(formData.length) || 0;
    const ancho = parseFloat(formData.width) || 0;
    const alto = parseFloat(formData.height) || 0;
    const pesoVolumetrico = (largo * ancho * alto) / 6000;
    setVolumetricWeight(pesoVolumetrico.toFixed(2));
    console.log('Peso volumEtrico:', pesoVolumetrico);
  }, [formData.length, formData.width, formData.height]);

  useEffect(() => {
    const peso = parseFloat(formData.grossWeight);
    const PesoVolumetrico = parseFloat(volumetricWeight);
    const valorDeclarado = parseFloat(formData.declaredValue);
    let valorDeclaradoMinimo;

    if (peso > PesoVolumetrico) {
      if (peso >= 1 && peso <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (peso >= 3 && peso <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (peso >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        const errorMsg = `El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${peso} kg.`;
        setErrorMessage(errorMsg);

      } else {
        setErrorMessage('');
      }
    } else {
      if (PesoVolumetrico >= 1 && PesoVolumetrico <= 2) {
        valorDeclaradoMinimo = 25000;
      } else if (PesoVolumetrico >= 3 && PesoVolumetrico <= 5) {
        valorDeclaradoMinimo = 40000;
      } else if (PesoVolumetrico >= 6) {
        valorDeclaradoMinimo = 50000;
      }

      if (valorDeclarado < valorDeclaradoMinimo) {
        const errorMsg = `El valor declarado debe ser al menos ${valorDeclaradoMinimo} para un peso de ${PesoVolumetrico} kg.`;
        setErrorMessage(errorMsg);

      } else {
        setErrorMessage('');
      }
    }
  }, [formData.grossWeight, formData.declaredValue, volumetricWeight, attemptedSubmit]);

  const pesoBruto = parseFloat(formDataGuia.Peso);
  const pesoVolumetrico = parseFloat(volumetricWeight);
  const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;
  console.log('Peso final:', pesoFinal);
  console.log('PesoVolumetrico:', pesoVolumetrico);

  const handleGuiaSubmit = async (e) => {
    e.preventDefault();
    if (generarGuiaDisabled) return; // Evita clicks durante el cooldown
    setGenerarGuiaDisabled(true);

    // Antes de validar el saldo, asegura cerrar el modal de cotización si está abierto
    if (modalIsOpen) {
      setModalIsOpen(false);
    }

    // Validar que hay suficiente saldo si no es contrapago
    if (!formDataGuia.AplicaContrapago) {
      // Obtener el valor de la cotización
      const transportadoraSeleccionada = cotizacionResult[selectedTransportadora];
      if (transportadoraSeleccionada) {
        const valorTotal = (transportadoraSeleccionada.valor || 0) +
          (transportadoraSeleccionada.comision_interna || 0) +
          (transportadoraSeleccionada.sobreflete || 0);

        // Convertir el saldo disponible a número
        const saldoDisponibleNum = parseFloat(availableBalance);

        if (valorTotal > saldoDisponibleNum) {
          showErrorModal('El saldo disponible no es suficiente para generar la guía sin contrapago.');
          setGenerarGuiaDisabled(false);
          return;
        }
      }
    }

    const requiredFields = [
      'nombre',
      'primerApellido',
      'telefono',
      'direccion',
      'correo',
      'DiceContener'
    ];

    if (formData.deliveryType === 'centroAcopio') {
      requiredFields.push('numeroDocumento');
    }

    const missingFields = requiredFields.filter(field => !formDataGuia[field]);

    if (missingFields.length > 0) {
      message.error(`Los siguientes campos son obligatorios: ${missingFields.join(', ')}`);
      setGenerarGuiaDisabled(false);
      return;
    }

    const idLocalidad = formDataGuia.idLocalidad;
    if (!idLocalidad) {
      alert('El valor de ciudad no es válido');
      setGenerarGuiaDisabled(false);
      return;
    }

    const guiaData = {
      IdTipoEntrega: formData.deliveryType === 'direccion' ? 1 : 2,
      IdServicio: formData.shipmentType === 'paquete' ? 1 : 2,
      AplicaContrapago: formDataGuia.AplicaContrapago || false,
      peso: pesoFinal,
      largo: parseFloat(formDataGuia.Largo) || 1,
      ancho: parseFloat(formDataGuia.Ancho) || 1,
      alto: parseFloat(formDataGuia.Alto) || 1,
      diceContener: formDataGuia.DiceContener || " ",
      valorDeclarado: parseFloat(formDataGuia.ValorDeclarado) || 50000,
      Destinatario: {
        tipoDocumento: formDataGuia.tipoDocumento || "CC",
        numeroDocumento: formDataGuia.numeroDocumento || " ",
        nombre: formDataGuia.nombre || "  ",
        primerApellido: formDataGuia.primerApellido || " ",
        segundoApellido: formDataGuia.segundoApellido || " ",
        telefono: formDataGuia.telefono || " ",
        direccion: formDataGuia.direccion || " ",
        correo: formDataGuia.correo || " ",
        idLocalidad: formDataGuia.idLocalidad || " "
      },
      Observaciones: formDataGuia.DiceContener || " ",
      transportadora: {
        pais: "colombia",
        nombre: selectedTransportadora,
        aplicaContrapago: formDataGuia.AplicaContrapago
      },
      origenCreacion: 1,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/preenvio/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : ''
        },
        body: JSON.stringify(guiaData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.mensaje_completo ? errorData.mensaje_completo.join(' ') : errorData.mensaje;
        alert(`Error: ${errorMessage}`);
        throw new Error('Error en la respuesta del servidor');
      }

      const data = await response.json();
      const numeroPreenvio = data.numeroPreenvio.toString();
      setNumeroPreenvio(numeroPreenvio);

      const pdfData = {
        transportadora: {
          pais: "colombia",
          nombre: selectedTransportadora
        },
        guia: numeroPreenvio,
        AplicaContrapago: formDataGuia.AplicaContrapago,
        origenCreacion: 1,
      };

      const pdfResponse = await fetch(`https://integration.99envios.app/api/sucursal/pdf/${sucursalId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token ? `Bearer ${token}` : '', // Si usas autenticación
        },
        body: JSON.stringify({
          transportadora: {
            pais: "colombia",
            nombre: selectedTransportadora
          },
          guia: numeroPreenvio,
          AplicaContrapago: formDataGuia.AplicaContrapago,
          origenCreacion: 1,
        }),
      });

      if (pdfResponse.ok) {
        const pdfUrl = await pdfResponse.text(); // O usa json() si responde con JSON
        if (pdfUrl) {
          window.open(pdfUrl.trim(), '_blank'); // Asegúrate de abrir correctamente
        } else {
          console.error('No se pudo obtener una URL válida del PDF.');
          alert('Error: No se pudo generar el PDF.');
        }
      } else {
        const errorData = await pdfResponse.json();
        const errorMessage = errorData?.mensaje || 'Error desconocido al generar el PDF';
        console.error('Error al generar el PDF:', errorMessage);
        alert(`Error: ${errorMessage}`);
      }

      setConfirmationMessage('Tu guía se ha generado con éxito.');
      setModalIsOpen(false); // Close the modal
      console.log("estado de whatsapp", statusWhatsapp)

      if (statusWhatsapp) {
        console.log("intentando", numeroPreenvio)
        try {
          const whatsappResponse = await axios.post(`https://99envios.app/api/online/whatsapp/message_template_envios/${numeroPreenvio}`, {});
          if (whatsappResponse.data.messaging_product === "whatsapp" && 
              whatsappResponse.data.contacts && 
              whatsappResponse.data.contacts.length > 0 &&
              whatsappResponse.data.messages &&
              whatsappResponse.data.messages[0].message_status === "accepted") {
            const whatsappNumber = whatsappResponse.data.contacts[0].input;
            setConfirmationMessage(`Tu guía número ${numeroPreenvio} de ${selectedTransportadora} se ha generado con éxito. Puedes consultar en Envíos Completo.\n\nSe ha enviado un mensaje de WhatsApp de confirmación al número: ${whatsappNumber}`);
          } else {
            setConfirmationMessage(`Tu guía número ${numeroPreenvio} de ${selectedTransportadora} se ha generado con éxito. Puedes consultar en Envíos Completo.`);
          }
        } catch (whatsAppError) {
          console.error('Error enviando WhatsApp:', whatsAppError);
          setConfirmationMessage(`Tu guía número ${numeroPreenvio} de ${selectedTransportadora} se ha generado con éxito. Puedes consultar en Envíos Completo.`);
        }
      } else {
        setConfirmationMessage(`Tu guía número ${numeroPreenvio} de ${selectedTransportadora} se ha generado con éxito. Puedes consultar en Envíos Completo.`);
      }

    } catch (error) {
      console.error('Error:', error);
      message.error('Error al generar la guía');
    } finally {
      setTimeout(() => {
        setGenerarGuiaDisabled(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal-codigo-sucursal/${id}`);
        if (!response.ok) {
          throw new Error('Error fetching sucursal ID');
        }
        const data = await response.json();
        console.log('Sucursal ID:', data);
        setSucursalId(data);
      } catch (error) {
        console.error('Error fetching sucursal ID:', error);
        return null;
      }
    };

   
    fetchSucursalId();
  }, [id]);

  useEffect(() => {
    const fetchSucursalData = async () => {
      try {
  
        const sucursalResponse = await axios.get(`https://99envios.app/api/online/sucursal/${id}`);
        setStatusWhatsapp(sucursalResponse.data.status_whatsapp === 1);
        console.log('Status WhatsApp:', sucursalResponse.data.status_whatsapp);
      } catch (error) {
        console.error('Error fetching sucursal data:', error);
      }
    };

    fetchSucursalData();
    console.log("estado wha",statusWhatsapp)
  }, [sucursalId]);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cotizarDisabled) return;
    if (modalIsOpen) {
      setModalIsOpen(false);
    }// Prevent clicks during cooldown
    setCotizarDisabled(true);
    setLoading(true);
    setAttemptedSubmit(true);

    const pesoBruto = parseFloat(formData.grossWeight);
    const pesoVolumetrico = parseFloat(volumetricWeight);
    const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;

    if (errorMessage) {
      message.error(errorMessage);
      setLoading(false);
      return;
    }

    const formatDateForDisplay = (dateString) => {
      // Obtiene la fecha actual
      const currentDate = new Date();

      // Resta un día
      const yesterdayDate = new Date(currentDate);
      yesterdayDate.setDate(currentDate.getDate() - 1);

      // Formatea la fecha de ayer en formato ISO (YYYY-MM-DD)
      return yesterdayDate.toISOString().split('T')[0];
    };

    const cotizacionData = {
      destino: {
        nombre: "",
        codigo: formData.destinationCity,
      },
      origen: {
        nombre: "",
        codigo: "",
      },
      IdTipoEntrega: formData.deliveryType === 'direccion' ? 1 : 2,
      IdServicio: formData.shipmentType === 'normal' ? 1 : 2,
      peso: pesoFinal,
      largo: parseInt(formData.length, 10),
      ancho: parseInt(formData.width, 10),
      alto: parseInt(formData.height, 10),
      fecha: "28-08-2024",
      AplicaContrapago: formData.cashOnDelivery,
      valorDeclarado: formData.declaredValue,
    };

    try {
      console.log("succursalId", sucursalId);
      const response = await fetch(`https://integration.99envios.app/api/sucursal/cotizar/${sucursalId}`, { // Usa el id desde la URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cotizacionData),
      });

      if (!response.ok) {
        throw new Error('Error en la cotizaciOn');
      }

      const result = await response.json();
      console.log('CotizaciOn:', result);

      setCotizacionResult(result);
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un error al realizar la cotización');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setCotizarDisabled(false);
      }, 3000);
    }
  };

  const copyFormDataToGuia = () => {
    setFormDataGuia({
      ...formDataGuia,
      Peso: formData.grossWeight,
      Largo: formData.length,
      Ancho: formData.width,
      Alto: formData.height,
      DiceContener: formData.contentDescription,
      ValorDeclarado: formData.declaredValue,
      AplicaContrapago: formData.cashOnDelivery,
      idLocalidad: formData.destinationCity
    });
  };

  const handleSelectTransportadora = (transportadora) => {
    setSelectedTransportadora(transportadora);
    openModal();
  };

  const openModal = () => {
    const pesoBruto = parseFloat(formData.grossWeight);
    const pesoVolumetrico = parseFloat(volumetricWeight);
    const pesoFinal = pesoVolumetrico > pesoBruto ? pesoVolumetrico : pesoBruto;
    setFinalWeight(pesoFinal.toFixed(2));
    copyFormDataToGuia();
    setModalIsOpen(true);
  };

  const [availableBalance, setAvailableBalance] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAvailableBalance = async () => {
      try {
        const user_id = localStorage.getItem('usuario_id');
        const token = localStorage.getItem('token');

        const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
          method: 'GET',
          headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) throw new Error('Failed to fetch balance');

        const data = await response.json();
        setAvailableBalance(data.saldo_disponible);
      } catch (err) {
        setError('Error al obtener el saldo disponible');
        console.error(err);
      }
    };

    fetchAvailableBalance();
  }, []);

  const updateAvailableBalance = async () => {
    const user_id = localStorage.getItem('usuario_id');
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`https://99envios.app/api/online/consultar-saldo/${user_id}`, {
        method: 'GET',
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) throw new Error('Failed to fetch balance');

      const data = await response.json();
      setAvailableBalance(data.saldo_disponible);
    } catch (err) {
      console.error('Error al actualizar el saldo disponible:', err);
    }
  };

  useEffect(() => {
    if (isResponsive && cotizacionResult) {
      setModalIsOpen(true);
    }
  }, [isResponsive, cotizacionResult]);

  const formatBalance = (balance) => {
    if (balance === null) return '99';
    const absBalance = Math.abs(balance);
    const formattedBalance = absBalance.toLocaleString('es-CO', {
      style: 'currency',
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });
    return balance < 0 ? `-${formattedBalance}` : formattedBalance;
  };

  useEffect(() => {
    if (formData.deliveryType === 'centroAcopio' && formData.destinationCity) {
      const fetchOffices = async () => {
        try {
          const response = await fetch(`https://integration.99envios.app/api/sucursal/oficinas/${formData.destinationCity}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ city: formData.destinationCity }),
          });
          if (!response.ok) {
            throw new Error('Error obteniendo las oficinas');
          }
          const data = await response.json();
          setOffices(data);
        } catch (error) {
          console.error('Error obteniendo las oficinas:', error);
        }
      };

      fetchOffices();
    }
  }, [formData.deliveryType, formData.destinationCity]);

  const handleOfficeChange = (value) => {
    setSelectedOffice(value);
    const selectedOffice = offices.find(office => office.CentroServicio.IdCentroServicio === value);
    if (selectedOffice) {
      setFormDataGuia(prevState => ({
        ...prevState,
        direccion: `${selectedOffice.CentroServicio.Direccion} (OFC: ${selectedOffice.CentroServicio.IdCentroServicio})`
      }));
    }
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <NavbarMex title="Guías Unitarias" />
      <div
        className={`shipping-form-saldo-disponible ${availableBalance <= 0 ? 'red-background' : ''}`}
      >
        <p className="saldo-text palpitating">
          <span style={{ color: "#4c45b1" }}>Saldo Disponible:</span>{' '}
          {formatBalance(availableBalance)}
        </p>
      </div>

      <div className="shipping-form-main-container">
        <div className="shipping-form-container">
          <form onSubmit={handleSubmit} className="shipping-form" id="myForm">
            <h2>InformaciOn del destino</h2>

            {/* Reemplazar el Alert con un Modal */}
            <Modal
              title="Error"
              visible={isErrorModalVisible}
              onCancel={handleErrorModalCancel}
              footer={null}
              className="error-modal"
              destroyOnClose={true}
              style={{ top: 20, zIndex: 10000000 }} // Asegurar que el modal de error esté por encima de todo
            >
              <p>{errorModalMessage}</p>
              <p>Saldo Disponible: {formatBalance(availableBalance)}</p>
            </Modal>

            <Modal
              title="Instructivo de Guías Unitarias"
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              destroyOnClose={true}
              width="100vh"
              style={{ top: 20 }}
              bodyStyle={{ padding: 0 }}
            >
              <div style={{ position: 'relative', paddingBottom: '65%', height: 0, overflow: 'hidden' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/teZ8zhbWOKA?si=9YEGdL4vKEfcrOdY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                ></iframe>
              </div>
            </Modal>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="destinationCity">Ciudad destino</label>
                <Select
                  id="destinationCity"
                  name="destinationCity"
                  value={formData.destinationCity}
                  onChange={(value) => handleChange({ target: { name: 'destinationCity', value } })}
                  style={{
                    width: '100%',
                    padding: '3px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    fontSize: '14px',
                    height: '44px', // Increase the height
                    marginBottom: '10px',
                  }}
                  required
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  <option value="" disabled>
                    Seleccione una ciudad
                  </option>
                  {Ciudades.map((ciudad) => (
                    <Option key={ciudad.value} value={ciudad.value}>
                      {ciudad.label}
                    </Option>
                  ))}


                </Select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="deliveryType">Tipo de Entrega</label>
                <select
                  id="deliveryType"
                  name="deliveryType"
                  value={formData.deliveryType}
                  onChange={handleChange}
                  required
                  className="input-field"
                >
                  <option value="" disabled>
                    Seleccione un tipo de entrega
                  </option>
                  <option value="direccion">ENTREGA EN DIRECCION</option>
                  <option value="centroAcopio">ENTREGA EN OFICINA</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="declaredValue">Valor declarado</label>
                <input
                  type="text"
                  id="declaredValue"
                  name="declaredValue"
                  value={formattedDeclaredValue}
                  onChange={handleChange}
                  required
                  className="input-field"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="grossWeight">Peso Bruto</label>
                <input
                  type="number"
                  id="grossWeight"
                  name="grossWeight"
                  value={formData.grossWeight}
                  onChange={handleChange}
                  placeholder="Ingrese el peso bruto"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="length">Largo</label>
                <input
                  type="number"
                  id="length"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  placeholder="Ingrese el largo"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="width">Ancho</label>
                <input
                  type="number"
                  id="width"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  placeholder="Ingrese el ancho"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="height">Alto</label>
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  placeholder="Ingrese el alto"
                  required
                  className="input-field"
                />
              </div>
              <div className="form-group">
                <label htmlFor="volumetricWeight">Peso Volumétrico</label>
                <input
                  type="number"
                  id="volumetricWeight"
                  name="volumetricWeight"
                  value={volumetricWeight}
                  readOnly
                  className="input-field"
                />
              </div>
            </div>
            <div class="form-group checkbox-wrapper">
              <div class="checkbox-row">
                <label class="checkbox-label" style={{ width: '220px' }}>
                  <input
                    type="checkbox"
                    id="cashOnDelivery"
                    name="cashOnDelivery"
                    checked={formData.cashOnDelivery}
                    onChange={handleChange}
                    class="custom-checkbox"

                  />
                  <span class="checkbox-custom"></span>
                  <span class="checkbox-text">Contra Entrega</span>
                </label>
                {/*<label class="checkbox-label">
                  <input
                    type="checkbox"
                    id="needsPickup"
                    name="needsPickup"
                    checked={formData.needsPickup}
                    onChange={handleChange}
                    class="custom-checkbox"
                  />
                  <span class="checkbox-custom"></span>
                  <span class="checkbox-text">Necesita Recolección</span>
                </label>*/}
              </div>
            </div>
            <button
              type="submit"
              className="submit-button"
              style={{ marginLeft: '0px' }}
              disabled={!!errorMessage || cotizarDisabled}
            >
              {cotizarDisabled ? 'Cotizando...' : 'Cotizar'}
            </button>
            {loading && <div className="loading-spinner">99</div>}
          </form>
        </div>

        {cotizacionResult && (
          isResponsive ? (
            <ReactModal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              className="custom-modal2"
              style={{ zIndex: 8000 }}
              overlayClassName="custom-overlay"
            >
              <h2>Cotización</h2>
              <table className="result-table">
                <thead>
                  <tr>
                    <th>Transportadora</th>
                    <th>Total Flete</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.deliveryType === 'centroAcopio' ? (
                    // Mostrar solo Interrapidísimo para centroAcopio
                    cotizacionResult.interrapidisimo && (
                      <tr>
                        <td>
                          <img src="/Images/inter.png" alt="Interrapidisimo" />
                        </td>
                        <td>
                          ${(cotizacionResult.interrapidisimo.valor +
                            cotizacionResult.interrapidisimo.sobreflete +
                            cotizacionResult.interrapidisimo.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                        </td>
                        <td>
                          <button onClick={() => handleSelectTransportadora('interrapidisimo')}>Seleccionar</button>
                        </td>
                      </tr>
                    )
                  ) : (
                    // Mostrar todas las opciones para dirección
                    <>
                      {cotizacionResult.interrapidisimo && (
                        <tr>
                          <td>
                            <img src="/Images/inter.png" alt="Interrapidisimo" />
                          </td>
                          <td>
                            ${(cotizacionResult.interrapidisimo.valor +
                              cotizacionResult.interrapidisimo.sobreflete +
                              cotizacionResult.interrapidisimo.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('interrapidisimo')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.tcc && (
                        <tr>
                          <td>
                            <img src="/Images/tcc.svg" alt="TCC" />
                          </td>
                          <td>
                            ${(cotizacionResult.tcc.valor +
                              cotizacionResult.tcc.sobreflete +
                              cotizacionResult.tcc.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('tcc')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.servientrega && (
                        <tr>
                          <td>
                            <img src="/Images/servi.png" alt="Servientrega" />
                          </td>
                          <td>
                            ${(cotizacionResult.servientrega.valor +
                              cotizacionResult.servientrega.sobreflete +
                              cotizacionResult.servientrega.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('servientrega')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.coordinadora && (
                        <tr>
                          <td>
                            <img src="/Images/coordi.png" alt="Coordinadora" />
                          </td>
                          <td>
                            ${(cotizacionResult.coordinadora.valor +
                              cotizacionResult.coordinadora.sobreflete +
                              (cotizacionResult.coordinadora.valor_contrapago || 0))?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('coordinadora')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
              <button onClick={() => setModalIsOpen(false)} className="close-modal-button">Cerrar</button>
            </ReactModal>
          ) : (
            <div className="cotizacion-result-container">
              <h2>Costos y Fecha de Entrega</h2>
              <table className="result-table">
                <thead>
                  <tr>
                    <th>Transportadora</th>
                    <th>Total Flete</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.deliveryType === 'centroAcopio' ? (
                    cotizacionResult.interrapidisimo && (
                      <tr>
                        <td>
                          <img src="/Images/inter.png" alt="Interrapidisimo" />
                        </td>
                        <td>
                          ${(cotizacionResult.interrapidisimo.valor +
                            cotizacionResult.interrapidisimo.sobreflete +
                            cotizacionResult.interrapidisimo.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                        </td>
                        <td>
                          <button onClick={() => handleSelectTransportadora('interrapidisimo')}>Seleccionar</button>
                        </td>
                      </tr>
                    )
                  ) : (
                    <>
                      {cotizacionResult.interrapidisimo && (
                        <tr>
                          <td>
                            <img src="/Images/inter.png" alt="Interrapidisimo" />
                          </td>
                          <td>
                            ${(cotizacionResult.interrapidisimo.valor +
                              cotizacionResult.interrapidisimo.sobreflete +
                              cotizacionResult.interrapidisimo.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('interrapidisimo')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.tcc && (
                        <tr>
                          <td>
                            <img src="/Images/tcc.svg" alt="TCC" />
                          </td>
                          <td>
                            ${(cotizacionResult.tcc.valor +
                              cotizacionResult.tcc.sobreflete +
                              cotizacionResult.tcc.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('tcc')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.servientrega && (
                        <tr>
                          <td>
                            <img src="/Images/servi.png" alt="Servientrega" />
                          </td>
                          <td>
                            ${(cotizacionResult.servientrega.valor +
                              cotizacionResult.servientrega.sobreflete +
                              cotizacionResult.servientrega.valor_contrapago)?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('servientrega')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                      {cotizacionResult.coordinadora && (
                        <tr>
                          <td>
                            <img src="/Images/coordi.png" alt="Coordinadora" />
                          </td>

                          <td>
                            ${(cotizacionResult.coordinadora.valor +
                              cotizacionResult.coordinadora.sobreflete +
                              (cotizacionResult.coordinadora.valor_contrapago || 0))?.toLocaleString() ?? 'N/A'}
                          </td>
                          <td>
                            <button onClick={() => handleSelectTransportadora('coordinadora')}>Seleccionar</button>
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          )
        )}




      </div>
      <ReactModal isOpen={modalIsOpen && selectedTransportadora} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-overlay">
        <div className="modal-header">
          <button type="button" className="close-button" onClick={closeModal}>
            &times;
          </button>
          <h2>Completar Información para Generar la Guía</h2>
        </div>
        <form>
          {formData.deliveryType === 'centroAcopio' && (
            <div className="modal-form-row">
              <div className="modal-form-group">
                <label htmlFor="numeroDocumento" className="required-label">Número de Documento (Destinatario) <span className="required-asterisk">*</span></label>
                <input
                  type="text"
                  id="numeroDocumento"
                  name="numeroDocumento"
                  value={formDataGuia.numeroDocumento}
                  onChange={handleInputChange}
                  required
                  className="input-field"
                />
              </div>
            </div>
          )}
          <div className="modal-form-row">
            <div className="modal-form-group">
              <label htmlFor="nombre" className="required-label">Nombre (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="nombre"
                name="nombre"
                value={formDataGuia.nombre}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
            <div className="modal-form-group">
              <label htmlFor="primerApellido" className="required-label">Primer Apellido (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="primerApellido"
                name="primerApellido"
                value={formDataGuia.primerApellido}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-row">
            <div className="modal-form-group">
              <label htmlFor="telefono" className="required-label">Teléfono (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="text"
                id="telefono"
                name="telefono"
                value={formDataGuia.telefono}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-row">
            <div className="modal-form-group">
              {formData.deliveryType === 'centroAcopio' ? (
                <>
                  <label htmlFor="office" className="required-label">Oficina de Entrega <span className="required-asterisk">*</span></label>
                  <Select
                    id="office"
                    name="office"
                    value={selectedOffice}
                    onChange={handleOfficeChange}
                    required
                    className="input-field"
                    style={{ width: '100%' }}
                    dropdownStyle={{ zIndex: 10000 }} // Ensure dropdown is visible
                  >
                    <option value="" disabled>Seleccione una oficina</option>
                    {offices.map(office => (
                      <option key={office.CentroServicio.IdCentroServicio} value={office.CentroServicio.IdCentroServicio}>
                        {office.CentroServicio.Direccion}
                      </option>
                    ))}
                  </Select>
                </>
              ) : (
                <>
                  <label htmlFor="direccion" className="required-label">Dirección de entrega (Lo más detallada posible, indicando si es Casa/Torre/Apto/Barrio) <span className="required-asterisk">*</span></label>
                  <input
                    type="text"
                    id="direccion"
                    name="direccion"
                    value={formDataGuia.direccion}
                    onChange={handleInputChange}
                    required
                    className="input-field"
                  />
                </>
              )}
            </div>
            <div className="modal-form-group">
              <label htmlFor="correo" className="required-label">Correo Electrónico (Destinatario) <span className="required-asterisk">*</span></label>
              <input
                type="email"
                id="correo"
                name="correo"
                value={formDataGuia.correo}
                onChange={handleInputChange}
                required
                className="input-field"
              />
            </div>
          </div>

          <div className="modal-form-group">
            <label htmlFor="DiceContener" className="required-label">Dice contener: <span className="required-asterisk">*</span></label>
            <input
              type="text"
              id="DiceContener"
              name="DiceContener"
              value={formDataGuia.DiceContener}
              onChange={handleInputChange}
              required
              className="input-field"
            />
          </div>

          <button
            type="button"
            className="generate-button"
            onClick={handleGuiaSubmit}
            disabled={generarGuiaDisabled}
          >
            {generarGuiaDisabled ? 'Generando Guía...' : 'Generar Guía'}
          </button>
        </form>
      </ReactModal>
      {confirmationMessage && (
        <div
          className="confirmation-message"
          ref={confirmationRef}
          onClick={() => setConfirmationMessage('')}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#ffffff',
            padding: '30px',
            borderRadius: '10px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 2)',
            textAlign: 'center',
            zIndex: 1000,
            maxWidth: '500px',
            fontSize: '100px',
            cursor: 'pointer'
          }}
        >
          <p style={{ fontSize: '18px', lineHeight: '1.5', whiteSpace: 'pre-line' }}>
            {confirmationMessage}
          </p>
          <p style={{ fontSize: '14px', marginTop: '10px' }}>
            Para cerrar haz click aquí o fuera de este cuadro.
          </p>
        </div>
      )}

      <Modal
        title="Confirmación de WhatsApp"
        visible={isWhatsappModalVisible}
        onCancel={() => setIsWhatsappModalVisible(false)}
        footer={[
          <Button key="ok" type="primary" onClick={() => setIsWhatsappModalVisible(false)}>
            OK
          </Button>
        ]}
      >
        <p>Se ha enviado un mensaje de WhatsApp de confirmación al número: {whatsappNumber}</p>
      </Modal>

      <BotonWhatsapp />

    </>
  );
};

export default ShippingForm;