import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NavbarMex from '../../newInterface/components/navbarMex';
import './shopifyOrdenes.css';

const { Option } = Select;

const ShopifyOrdenes = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    estadoOrden: '',
    transportadora: '',
    estadoEnvio: '',
  });
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.99envios.app/api/listar_ordenes/${usuario_id}`);
        const filteredData = response.data.filter(item => item.origen_creacion === 6);
        const formattedData = filteredData.map((item, index) => ({
          key: index,
          id: index + 1,
          estado_orden: item.estado_orden,
          nombre_producto: item.nombre_producto,
          valor_venta: item.valor_venta,
          valor_proveedor: item.valor_proveedor,
          valor_utilidad: item.valor_utilidad,
          transportadora: item.transportadora,
          estado_envio: item.estado_envio,
          fecha_estado: item.fecha_estado,
          numero_guia: item.preeenvio?.numero_preenvio || '',
          valor_flete: item.preeenvio?.valor_flete || '0',
          valor_flete_99: item.preeenvio?.valor_flete_99 || '0',
          valor_declarado: item.preeenvio?.valor_declarado || '0',
          destinatario: item.preeenvio?.destinatario || {},
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: 60,
    },
    {
      title: 'Producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
      width: 200,
    },
    {
      title: 'Fecha',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
      width: 150,
    },
    {
      title: 'Estado',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
      width: 120,
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
      width: 120,
    },
    {
      title: 'Guía',
      dataIndex: 'numero_guia',
      key: 'numero_guia',
      width: 120,
    },
    {
      title: 'Valor Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      width: 120,
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_venta',
      key: 'valor_venta',
      width: 120,
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      width: 120,
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Destinatario',
      children: [
        {
          title: 'Nombre',
          dataIndex: ['destinatario', 'nombre'],
          key: 'nombre_destinatario',
          width: 150,
          render: (_, record) => `${record.destinatario.nombre} ${record.destinatario.primer_apellido || ''}`,
        },
        {
          title: 'Teléfono',
          dataIndex: ['destinatario', 'telefono'],
          key: 'telefono_destinatario',
          width: 120,
        },
        {
          title: 'Ciudad',
          dataIndex: ['destinatario', 'ciudad'],
          key: 'ciudad_destinatario',
          width: 150,
        },
        {
          title: 'Dirección',
          dataIndex: ['destinatario', 'direccion'],
          key: 'direccion_destinatario',
          width: 200,
        },
      ],
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.estadoOrden) {
      result = result.filter((item) => item.estado_orden === parseInt(filters.estadoOrden));
    }
    if (filters.transportadora) {
      result = result.filter((item) => item.transportadora.toLowerCase() === filters.transportadora.toLowerCase());
    }
    if (filters.estadoEnvio) {
      result = result.filter((item) => item.estado_envio.toLowerCase() === filters.estadoEnvio.toLowerCase());
    }
    setFilteredData(result);
  }, [searchText, filters, data]);

  return (
    <>
      <NavbarMex title="Órdenes Shopify" />
      <div className='shopify-misPedidos'>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Select
            style={{ width: 200, marginRight: '8px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('transportadora', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
          <Select
            style={{ width: 200 }}
            placeholder="Filtrar por Estado de Envío"
            onChange={(value) => handleFilterChange('estadoEnvio', value)}
          >
            <Option value="">Todos</Option>
            <Option value="creado">Creado</Option>
            <Option value="en_transito">En tránsito</Option>
            <Option value="entregado">Entregado</Option>
          </Select>
        </div>
        <div className="shopify-misPedidos-tabla" style={{ overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
    </>
  );
};

export default ShopifyOrdenes;
