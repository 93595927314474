import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Col, message, Modal, Form, Select, Upload, Row, Checkbox, Card } from 'antd';
import { PlusOutlined, MinusCircleOutlined, InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './inventarioBodega.css';
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';

const { Option } = Select;
const { Dragger } = Upload;

const InventarioBodega = () => {
    const [bodegas, setBodegas] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [isNewProductModalVisible, setIsNewProductModalVisible] = useState(false);
    const [newProductData, setNewProductData] = useState({
        id_producto_externo_dropi: '', // Cambiado de id_producto_externo
        id_producto_externo_effi: '', // Nuevo campo
        id_categoria: '',
        nombre_producto: '',
        descripcion_producto: '',
        cantidad_disponible: '',
        precio_proveedor: '',
        precio_sugerido: '',
        sku: '',
        imagenes: [],
        variaciones: [],
        estado_variacion: '',
        peso_producto: ''
    });

    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalBarcodeVisible, setIsModalBarcodeVisible] = useState(false);
    const [inputCode, setInputCode] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [isVariationsModalVisible, setIsVariationsModalVisible] = useState(false);
    const [selectedVariations, setSelectedVariations] = useState([]);
    const [stockThreshold] = useState(1); // Puedes ajustar este valor según necesites
    const [editingRecordId, setEditingRecordId] = useState(null);
    const [newQuantity, setNewQuantity] = useState('');
    const [isBulkUploadModalVisible, setIsBulkUploadModalVisible] = useState(false);
    const [bulkFileToUpload, setBulkFileToUpload] = useState(null);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [uploadResults, setUploadResults] = useState(null);
    const [isResultsModalVisible, setIsResultsModalVisible] = useState(false);
    const [withoutBarcodeCount, setWithoutBarcodeCount] = useState(0);

    const fetchBodegas = async () => {
        try {
            setLoading(true);
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/picking/bodega/listar-inventario-bodega-externo/${userId}`);
            
            if (!response.ok) {
                throw new Error('Error al cargar las bodegas');
            }
            
            const data = await response.json();
            setBodegas(data.map(bodega => {
                const productosExternos = bodega.inventario.productos_externos || [];
                return {
                    ...bodega,
                    id_producto_externo_dropi: productosExternos.find(pe => pe.tipo_producto_externo === 1)?.id_producto_externo || '',
                    id_producto_externo_effi: productosExternos.find(pe => pe.tipo_producto_externo === 2)?.id_producto_externo || ''
                };
            }));
        } catch (error) {
            console.error('Error fetching bodegas:', error);
            message.error('No se pudieron cargar las bodegas');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBodegas();
    }, []);

    useEffect(() => {
        // Update the count whenever bodegas changes
        const count = bodegas.filter(item => !item.codigo_barras).length;
        setWithoutBarcodeCount(count);
    }, [bodegas]);

    const handleNewProductChange = (e) => {
        const { name, value } = e.target;
        setNewProductData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = ({ fileList }) => {
        setNewProductData(prev => ({
            ...prev,
            imagenes: fileList.map(file => {
                if (file.originFileObj) {
                    return {
                        ...file,
                        status: 'done',
                        url: URL.createObjectURL(file.originFileObj)
                    };
                }
                return file;
            })
        }));
    };

    const handleVariationsChange = (field, value, index) => {
        const updatedVariations = [...newProductData.variaciones];
        updatedVariations[index][field] = value;
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleAddVariation = () => {
        setNewProductData(prev => ({
            ...prev,
            variaciones: [...prev.variaciones, { talla: '', color: '', cantidad_disponible: '', sku: '' }]
        }));
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = newProductData.variaciones.filter((_, i) => i !== index);
        setNewProductData(prev => ({
            ...prev,
            variaciones: updatedVariations
        }));
    };

    const handleNewProductSave = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();

            // Convertir el peso de gramos a kilogramos y redondear a 2 decimales
            const pesoEnKilogramos = values.peso_producto ? Number((values.peso_producto / 1000).toFixed(2)) : 0;

            // Agregar campos básicos, excluyendo los IDs externos
            Object.keys(values).forEach(key => {
                if (key !== 'imagenes' && 
                    key !== 'variaciones' && 
                    key !== 'id_producto_externo_dropi' && 
                    key !== 'id_producto_externo_effi') {
                    // Si es el campo de peso, usar el valor convertido
                    const value = key === 'peso_producto' ? pesoEnKilogramos : values[key];
                    formData.append(key, value);
                }
            });

            // Agregar los IDs externos como elementos de array
            if (values.id_producto_externo_dropi) {
                formData.append('id_producto_externo[1]', values.id_producto_externo_dropi);
            }
            if (values.id_producto_externo_effi) {
                formData.append('id_producto_externo[2]', values.id_producto_externo_effi);
            }

            // Verificar y agregar imágenes como binarios
            if (newProductData.imagenes.length > 0) {
                newProductData.imagenes.forEach((file, index) => {
                    if (file.originFileObj) {
                        formData.append(`imagenes[${index}]`, file.originFileObj);
                    }
                });
            }

            // Agregar variaciones si existen
            if (newProductData.estado_variacion === 1) {
                newProductData.variaciones.forEach((variation, index) => {
                    Object.keys(variation).forEach(key => {
                        if (key !== 'id_variacion_externo_dropi' && key !== 'id_variacion_externo_effi') {
                            formData.append(`variaciones[${index}][${key}]`, variation[key]);
                        }
                    });
                    if (variation.id_variacion_externo_dropi) {
                        formData.append(`variaciones[${index}][id_variacion_externa][1]`, variation.id_variacion_externo_dropi);
                    }
                    if (variation.id_variacion_externo_effi) {
                        formData.append(`variaciones[${index}][id_variacion_externa][2]`, variation.id_variacion_externo_effi);
                    }
                });
            }

            const id = localStorage.getItem('usuario_id');
            const url = `https://api.99envios.app/api/inventarios/${id}`;
            const response = await axios.post(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });

            setBodegas(prev => [...prev, response.data]);
            message.success('Nuevo producto creado con éxito');
            setIsNewProductModalVisible(false);

            resetForm();
            fetchBodegas(); // Update the table data

            // Volver a abrir el modal con campos vacíos
            setIsNewProductModalVisible(true);
        } catch (error) {
            console.error('Error:', error);
            if (error.response) {
                // Si es un error específico sobre SKU y código de barras duplicado
                if (error.response.data.error === "ya hay un codigo de barras asociado con este sku y sucursal asociada, favor cambiar el sku.") {
                    message.error('Ya existe un código de barras asociado con este SKU en esta sucursal. Por favor, cambie el SKU.');
                }
                // Si es un error específico sobre SKU duplicado
                else if (typeof error.response.data === 'string' && 
                    error.response.data.includes('sucursal') && 
                    error.response.data.includes('ya existe')) {
                    message.error(error.response.data);
                }
                // Si es un error de validación de SKU en variaciones
                else if (error.response.data.errors?.['variaciones.0.sku']) {
                    message.error('El campo SKU de la variación es obligatorio.');
                }
                // Si hay un mensaje de error general
                else if (error.response.data.message) {
                    message.error(error.response.data.message);
                }
                // Si hay un array de errores
                else if (error.response.data.errors?.error) {
                    message.error(error.response.data.errors.error[0]);
                }
                // Para cualquier otro tipo de error de respuesta
                else {
                    message.error('Error al crear el producto: ' + JSON.stringify(error.response.data));
                }
            } else {
                message.error('Error al crear el producto: ' + error.message);
            }
        }
    };

    const handleCheckboxChange = (e) => {
        setNewProductData(prev => ({
            ...prev,
            estado_variacion: e.target.checked ? 1 : "" // Cambia a 1 o 0
        }));
    };

    const resetNewProductData = () => {
        setNewProductData({
            id_producto_externo_dropi: '', // Cambiado aquí
            id_producto_externo_effi: '', // Nuevo campo
            id_categoria: '',
            nombre_producto: '',
            descripcion_producto: '',
            cantidad_disponible: '',
            precio_proveedor: '',
            precio_sugerido: '',
            sku: '',
            imagenes: [],
            variaciones: [],
            estado_variacion: '',
            peso_producto: ''
        });
    };

    const resetForm = () => {
        form.resetFields();
        resetNewProductData();
    };

    const filteredBodegas = bodegas
        .filter((bodega) => 
            bodega.nombre_producto?.toLowerCase().includes(searchText.toLowerCase()) &&
            bodega.cantidad_disponible >= 1 // Filter out products with cantidad_disponible less than 1
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by latest created_at

    const lowStockProducts = bodegas
        .filter(product => 
            product.cantidad_disponible < stockThreshold
        )
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by latest created_at

    const handleGenerateSku = (record) => {
        setSelectedRecord(record);
        setIsModalVisible(true);
    };

    const handleGenerateBarcode = (record) => {
        setSelectedRecord(record);
        setIsModalBarcodeVisible(true);
        
        setTimeout(() => {
            const canvas = document.getElementById('barcodeCanvas');
            if (canvas) {
                const scale = 4;
                canvas.style.width = '320px';
                canvas.style.height = '150px';
                canvas.width = 320 * scale;
                canvas.height = 150 * scale;
    
                JsBarcode(canvas, record.codigo_barras, {
                    format: "CODE128",
                    width: 1,
                    height: 30 * scale,
                    displayValue: true,
                    fontSize: 16,          // Tamaño de fuente fijo
                    marginTop: 5 * scale,
                    marginBottom: 5 * scale,
                    textMargin: 2 * scale,
                    font: "monospace",     // Fuente monoespaciada
                    lineColor: "#000",
                    background: "#FFFFFF"
                });
            }
        }, 100);
    };
    
    const handleDownloadBarcode = () => {
        const canvas = document.getElementById('barcodeCanvas');
        if (canvas) {
            // Convertir 32mm x 15mm a puntos (1mm = 2.83465 puntos)
            const WIDTH_IN_POINTS = 32 * 2.83465;  // ≈ 90.7088 puntos
            const HEIGHT_IN_POINTS = 15 * 2.83465; // ≈ 42.51975 puntos
    
            // Crear PDF con las dimensiones exactas
            const pdf = new jsPDF({
                orientation: 'landscape',
                unit: 'pt',
                format: [WIDTH_IN_POINTS, HEIGHT_IN_POINTS]
            });
    
            const imgData = canvas.toDataURL("image/png", 1.0);
            
            // Calcular dimensiones para centrar el código de barras
            const barcodeWidth = WIDTH_IN_POINTS - 10;  // Dejar 5pt de margen a cada lado
            const barcodeHeight = HEIGHT_IN_POINTS - 6; // Dejar 3pt de margen arriba y abajo
            
            // Centrar el código de barras
            const xOffset = (WIDTH_IN_POINTS - barcodeWidth) / 2;
            const yOffset = (HEIGHT_IN_POINTS - barcodeHeight) / 2;
    
            // Agregar el código de barras al PDF
            pdf.addImage(imgData, 'PNG', xOffset, yOffset, barcodeWidth, barcodeHeight);
    
            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
        }
        setIsModalBarcodeVisible(false);
    };

    const handleDownloadBarcodeSheet = () => {
        const canvas = document.getElementById('barcodeCanvas');
        if (canvas) {
            // A4 size in points (595.28 x 841.89)
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imgData = canvas.toDataURL("image/png", 1.0);

            // Convertir cm a puntos (1 cm = 28.3465 puntos)
            const barcodeWidth = 4 * 28.3465; // 4 cm
            const barcodeHeight = 3 * 28.3465; // 3 cm
            
            // Márgenes en puntos
            const marginLeft = 28.3465; // 1 cm
            const marginTop = 28.3465; // 1 cm
            const spacingX = 28.3465/2; // 0.5 cm entre códigos horizontalmente
            const spacingY = 28.3465/2; // 0.5 cm entre códigos verticalmente
            
            // Calcular cuántos códigos caben por fila y columna
            const codesPerRow = 4; // 4 columnas como solicitado
            const codesPerColumn = 8;
            
            // Calcular el desplazamiento X entre códigos para centrar en la página
            const totalWidthNeeded = (codesPerRow * barcodeWidth) + ((codesPerRow - 1) * spacingX);
            const startX = (595.28 - totalWidthNeeded) / 2;

            // Generar matriz de códigos
            for (let row = 0; row < codesPerColumn; row++) {
                for (let col = 0; col < codesPerRow; col++) {
                    const x = startX + (col * (barcodeWidth + spacingX));
                    const y = marginTop + (row * (barcodeHeight + spacingY));
                    
                    pdf.addImage(imgData, 'PNG', x, y, barcodeWidth, barcodeHeight);
                }
            }

            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            window.open(pdfUrl, '_blank');
        }
        setIsModalBarcodeVisible(false);
    };

    const handleOk = async () => {
        try {
            const generatedSku = inputCode; // Removida la concatenación con codigoSucursal
            console.log('Generated SKU:', generatedSku);

            const userId = parseInt(localStorage.getItem('usuario_id'));
            const response = await fetch(`https://99envios.app/api/picking/bodega/inventario-modificar-sku/${selectedRecord.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId, sku: generatedSku }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === "no se puede modificar el sku ya q este producto contiene un codigo de barras asociado.") {
                    throw new Error('No se puede modificar el SKU ya que este producto contiene un código de barras asociado.');
                } else {
                    throw new Error('Error al generar el SKU');
                }
            }

            message.success('SKU generado exitosamente');
            fetchBodegas(); // Update the table data
        } catch (error) {
            console.error('Error generando SKU:', error);
            message.error(error.message);
        } finally {
            setIsModalVisible(false);
            setInputCode('');
        }
    };

    const handleOkVariation = async () => {
        try {
            const userId = parseInt(localStorage.getItem('usuario_id'));
            const generatedSku = inputCode;
            const response = await fetch(`https://99envios.app/api/picking/bodega/variacion-modificar-sku/${selectedRecord.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId, sku: generatedSku }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error) {
                    message.error(errorData.error);
                } else {
                    console.error('Error generating SKU:', response.statusText);
                    message.error('No se pudo generar el SKU');
                }
            } else {
                message.success('SKU generado exitosamente');
                fetchBodegas(); // Update the table data
            }
        } catch (error) {
            console.error('Error generating SKU:', error);
            message.error('No se pudo generar el SKU');
        } finally {
            setIsModalVisible(false);
            setInputCode('');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setInputCode('');
    };

    const handleCancelBarcode = () => {
        setIsModalBarcodeVisible(false);
    };

    const handleGenerateBarcodeApi = async (record) => {
        console.log('Generar Código de Barras API called for record:', record);
        const userId = parseInt(localStorage.getItem('usuario_id'));
        try {
            const response = await fetch(`https://99envios.app/api/picking/bodega/inventario-bodega-codigo-barras/${record.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId }),
            });

            const data = await response.json();

            if (!response.ok) {
                if (data.error === "ya hay un codigo de barras asociado con este sku y sucursal asociada, favor cambiar el sku.") {
                    message.error('Ya existe un código de barras asociado con este SKU en esta sucursal. Por favor, cambie el SKU.');
                } else if (data.error === "Producto ya cuenta con codigo de barras.") {
                    message.error('Producto ya cuenta con código de barras.');
                } else {
                    console.error('Error generating barcode:', response.statusText);
                    message.error('No se pudo generar el código de barras');
                }
                return;
            }

            message.success('Código de barras generado exitosamente');
            await fetchBodegas();
        } catch (error) {
            console.error('Error:', error);
            message.error('Error al generar el código de barras');
        }
    };

    const handleViewVariations = (record) => {
        setSelectedRecord(record);
        setSelectedVariations(record.variaciones_bodega.map(variation => ({
            ...variation,
            nombre_producto: record.nombre_producto
        })));
        setIsVariationsModalVisible(true);
    };

    const handleGenerateSkuVariation = (variation) => {
        setSelectedRecord(variation);
        setIsModalVisible(true);
    };

    const handleGenerateBarcodeVariation = async (variation) => {
        try {
            const userId = parseInt(localStorage.getItem('usuario_id'));
            const response = await fetch(`https://99envios.app/api/picking/bodega/variacion-bodega-codigo-barras/${variation.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id_bodega: userId }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error === "Producto ya cuenta con codigo de barras.") {
                    message.error('Producto ya cuenta con código de barras.');
                } else {
                    console.error('Error generating barcode:', response.statusText);
                    message.error('No se pudo generar el código de barras');
                }
            } else {
                message.success('Código de barras generado exitosamente');
                fetchBodegas(); // Update the table data
                const withoutBarcodeCount = bodegas.filter(item => !item.codigo_barras).length - 1;
                setWithoutBarcodeCount(withoutBarcodeCount);
            }
        } catch (error) {
            console.error('Error generating barcode:', error);
            message.error('No se pudo generar el código de barras');
        }
    };

    const handleViewBarcodeVariation = (variation) => {
        setSelectedRecord(variation);
        setIsModalBarcodeVisible(true);
        
        setTimeout(() => {
            const canvas = document.getElementById('barcodeCanvas');
            if (canvas) {
                const scale = 4;
                canvas.style.width = '320px';
                canvas.style.height = '150px';
                canvas.width = 320 * scale;
                canvas.height = 150 * scale;

                JsBarcode(canvas, variation.codigo_barras, {
                    format: "CODE128",
                    width: 1,
                    height: 30 * scale,
                    displayValue: true,
                    fontSize: 16,          // Tamaño de fuente fijo
                    marginTop: 5 * scale,
                    marginBottom: 5 * scale,
                    textMargin: 2 * scale,
                    font: "monospace",     // Fuente monoespaciada
                    lineColor: "#000",
                    background: "#FFFFFF"
                });
            }
        }, 100);
    };

    const variationsColumns = [
        { 
            title: 'Nombre Producto', 
            dataIndex: 'nombre_producto', 
            key: 'nombre_producto'
        },
        { 
            title: 'SKU', 
            dataIndex: 'sku', 
            key: 'sku' 
        },
        { 
            title: 'Código de Barras', 
            dataIndex: 'codigo_barras', 
            key: 'codigo_barras',
            render: (codigo_barras) => codigo_barras ? codigo_barras : 'N/A',
            sorter: (a, b) => {
                // Manejar casos donde código_barras es null
                if (!a.codigo_barras && !b.codigo_barras) return 0;
                if (!a.codigo_barras) return 1;
                if (!b.codigo_barras) return -1;
                return a.codigo_barras.localeCompare(b.codigo_barras);
            },
            sortDirections: ['ascend', 'descend']
        },
        { 
            title: 'Cantidad Disponible', 
            dataIndex: 'cantidad_disponible', 
            key: 'cantidad_disponible' 
        },
        {
            title: 'Acciones',
            key: 'acciones',
            render: (_, variation) => (
                <>
                    {variation.sku === null && (
                        <Button onClick={() => handleGenerateSkuVariation(variation)}>
                            Generar SKU
                        </Button>
                    )}
                    {variation.codigo_barras === null && (
                        <Button 
                            onClick={() => handleGenerateBarcodeVariation(variation)} 
                            disabled={!variation.sku}
                            style={{ marginLeft: 8 }}
                        >
                            Generar Código de Barras
                        </Button>
                    )}
                    {variation.codigo_barras !== null && (
                        <Button 
                            onClick={() => handleViewBarcodeVariation(variation)} 
                            disabled={!variation.sku}
                            style={{ marginLeft: 8 }}
                        >
                            Ver Código de Barras
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const columns = [
        { 
            title: 'ID Producto Externo Dropi', // Cambiado aquí
            dataIndex: 'id_producto_externo_dropi', // Cambiado aquí
            key: 'id_producto_externo_dropi' // Cambiado aquí
        },
        { 
            title: 'ID Producto Externo Effi', 
            dataIndex: 'id_producto_externo_effi', 
            key: 'id_producto_externo_effi' 
        },
        { 
            title: 'Nombre Producto', 
            dataIndex: 'nombre_producto', 
            key: 'nombre_producto' 
        },
        { 
            title: 'SKU', 
            dataIndex: 'sku', 
            key: 'sku',
            render: (sku) => sku ? sku : 'N/A'
        },
        { 
            title: 'Código de Barras', 
            dataIndex: 'codigo_barras', 
            key: 'codigo_barras',
            render: (codigo_barras) => codigo_barras ? codigo_barras : 'N/A',
            sorter: (a, b) => {
                // Manejar casos donde código_barras es null
                if (!a.codigo_barras && !b.codigo_barras) return 0;
                if (!a.codigo_barras) return 1;
                if (!b.codigo_barras) return -1;
                return a.codigo_barras.localeCompare(b.codigo_barras);
            },
            sortDirections: ['ascend', 'descend']
        },
        { 
            title: 'Confirmación Bodega', 
            dataIndex: 'confirmacion_bodega', 
            key: 'confirmacion_bodega',
            render: (confirmacion_bodega) => `${confirmacion_bodega}`
        },
        { 
            title: 'Estado Variación', 
            dataIndex: 'estado_variacion', 
            key: 'estado_variacion',
            render: (estado_variacion) => `${estado_variacion}`
        },
        { 
            title: 'Cantidad Disponible', 
            dataIndex: 'cantidad_disponible', 
            key: 'cantidad_disponible',
            render: (cantidad_disponible) => `${cantidad_disponible} unidades`
        },
        { 
            title: 'Fecha de Creación', 
            dataIndex: 'created_at', 
            key: 'created_at',
            render: (created_at) => new Date(created_at).toLocaleString()
        },
        { 
            title: 'Fecha de Actualización', 
            dataIndex: 'updated_at', 
            key: 'updated_at',
            render: (updated_at) => new Date(updated_at).toLocaleString()
        },
        {
            title: 'Variaciones',
            key: 'variaciones_bodega',
            render: (_, record) => (
                record.variaciones_bodega.length > 0 ? (
                    <Button onClick={() => handleViewVariations(record)}>
                        Ver Variaciones
                    </Button>
                ) : (
                    <span>Sin Variaciones</span>
                )
            ),
        },
        {
            title: 'Código de Barras',
            key: 'acciones_qr',
            dataIndex: 'codigo_barras', // Añadido para el ordenamiento
            sorter: (a, b) => {
                // Manejar casos donde código_barras es null
                if (!a.codigo_barras && !b.codigo_barras) return 0;
                if (!a.codigo_barras) return 1;
                if (!b.codigo_barras) return -1;
                return a.codigo_barras.localeCompare(b.codigo_barras);
            },
            sortDirections: ['ascend', 'descend'],
            render: (codigo_barras, record) => (
                <>
                    {record.sku === null && (
                        <Button onClick={() => handleGenerateSku(record)}>
                            Generar SKU
                        </Button>
                    )}
                    {record.codigo_barras === null && record.sku !== null && (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                            <Button 
                                onClick={() => handleGenerateBarcodeApi(record)} 
                                disabled={!record.sku}
                                style={{ backgroundColor: '#ff9933', borderColor: '#ff9933', color: 'white' }}
                            >
                                Generar Código de Barras
                            </Button>
                            <Button
                                onClick={() => handleGenerateSku(record)}
                                style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white' }}
                            >
                                Modificar SKU
                            </Button>
                        </div>
                    )}
                    {record.codigo_barras !== null && (
                        <Button 
                            onClick={() => handleGenerateBarcode(record)} 
                            disabled={!record.sku}
                            style={{ backgroundColor: '#4CAF50', borderColor: '#4CAF50', color: 'white' }}
                        >
                            Ver Código Barras
                        </Button>
                    )}
                </>
            ),
        },
    ];

    const lowStockColumns = [
        ...columns,
        {
            title: 'Acciones',
            key: 'acciones',
            render: (_, record) => (
                editingRecordId === record.id ? (
                    <>
                        <Input
                            type="number"
                            value={newQuantity}
                            onChange={(e) => setNewQuantity(e.target.value)}
                            style={{ width: '100px', marginRight: '8px' }}
                        />
                        <Button onClick={() => handleSaveQuantity(record)}>
                            OK
                        </Button>
                    </>
                ) : (
                    <Button onClick={() => handleAddQuantity(record)}>
                        Agregar Cantidad
                    </Button>
                )
            ),
        },
    ];

    const handleAddQuantity = (record) => {
        setEditingRecordId(record.id);
        setNewQuantity('');
    };

    const handleSaveQuantity = async (record) => {
        try {
            const userId = localStorage.getItem('usuario_id');
            const response = await axios.post(`https://api.99envios.app/api/picking/bodega/sumar-cantidad-entrante/${record.id}`, {
                id_bodega: userId,
                cantidad: newQuantity
            });

            if (response.status === 200) {
                message.success('Cantidad agregada exitosamente');
                fetchBodegas(); // Update the table data
            } else {
                throw new Error('Error al agregar la cantidad');
            }
        } catch (error) {
            console.error('Error adding quantity:', error);
            message.error('No se pudo agregar la cantidad');
        } finally {
            setEditingRecordId(null);
            setNewQuantity('');
        }
    };

    const handleBulkUpload = (info) => {
        setBulkFileToUpload({
            file: info.file,
            name: info.file.name,
            originFileObj: info.file
        });
        setIsConfirmModalVisible(true);
    };

    const handleConfirmBulkUpload = async () => {
        if (!bulkFileToUpload || !bulkFileToUpload.file) {
            message.error('No hay archivo para cargar');
            return;
        }

        const formData = new FormData();
        formData.append('archivo', bulkFileToUpload.file);

        try {
            const userId = localStorage.getItem('usuario_id');
            const response = await fetch(`https://99envios.app/api/inventarios_guardarMasivo/${userId}`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (response.ok) {
                setUploadResults(data);
                setIsResultsModalVisible(true);
                await fetchBodegas(); // Recargar los datos
            } else {
                message.error(`Error al subir ${bulkFileToUpload.name}: ${data.message || 'Error desconocido'}`);
            }
        } catch (error) {
            console.error('Error en la carga:', error);
            message.error('Error en la carga del archivo');
        }

        // Limpiar el archivo después de la carga
        setBulkFileToUpload(null);
        setIsConfirmModalVisible(false);
        setIsBulkUploadModalVisible(false);
    };

    const bulkUploadProps = {
        name: 'file',
        multiple: false,
        accept: '.xlsx, .xls',
        fileList: bulkFileToUpload ? [bulkFileToUpload] : [], // Agregar esta línea
        beforeUpload: (file) => {
            handleBulkUpload({ 
                file: file,
                name: file.name 
            });
            return false;
        },
        onRemove: () => {
            setBulkFileToUpload(null);
            return true;
        }
    };

    const handleDownloadTemplate = () => {
        const link = document.createElement('a');
        link.href = require('./enviosMasivoBodega.xlsx'); // Usando require para importar el archivo local
        link.setAttribute('download', 'enviosMasivoBodega.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const CustomCheckbox = ({ checked, onChange }) => (
        <div 
            className="custom-checkbox-container"
            onClick={() => onChange({ target: { checked: !checked } })}
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                userSelect: 'none',
                padding: '8px 16px',
                border: '2px solid #1890ff',
                borderRadius: '6px',
                backgroundColor: checked ? '#1890ff' : 'white',
                color: checked ? 'white' : '#1890ff',
                transition: 'all 0.3s',
                fontWeight: '500',
                marginBottom: '20px'
            }}
        >
            <div style={{
                width: '20px',
                height: '20px',
                border: checked ? '2px solid white' : '2px solid #1890ff',
                borderRadius: '4px',
                marginRight: '8px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: checked ? '#1890ff' : 'white'
            }}>
                {checked && (
                    <svg 
                        viewBox="0 0 24 24" 
                        width="14" 
                        height="14"
                        style={{ fill: 'white' }}
                    >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
                    </svg>
                )}
            </div>
            Añadir variaciones
        </div>
    );

    return (
        <>
            <NavbarUsuarioBodega title="Inventario Bodega" />
            <div className="GB-table-container" style={{ fontSize: "13px" }}>
                <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                    <Col>
                        <Input
                            placeholder="Buscar producto"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: 200, marginRight: 10 }}
                        />
                    </Col>
                    <Col>
                        <Button 
                            type="primary" 
                            style={{ height: "44px", marginRight: 8 }} 
                            onClick={() => { setIsNewProductModalVisible(true); resetNewProductData(); }}
                        >
                            Añadir Inventario Individual
                        </Button>
                        <Button 
                            type="primary" 
                            style={{ height: "44px" }} 
                            onClick={() => setIsBulkUploadModalVisible(true)}
                        >
                            Añadir Inventario Masivo
                        </Button>
                    </Col>
                </Row>

                <h2 style={{ marginBottom: '16px' }}>Inventario Total</h2>
                <Table 
                    dataSource={filteredBodegas} 
                    columns={columns} 
                    rowKey="id" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                    loading={loading}
                />

                <h2 style={{ marginTop: '32px', marginBottom: '16px' }}>Stock Faltante</h2>
                <Table 
                    dataSource={lowStockProducts} 
                    columns={lowStockColumns} 
                    rowKey="id" 
                    pagination={{ pageSize: 5 }} 
                    scroll={{ x: '450' }}
                    loading={loading}
                />
            </div>

            <Modal
                title="Agregar Nuevo Inventario"
                visible={isNewProductModalVisible}
                onOk={handleNewProductSave}
                onCancel={() => setIsNewProductModalVisible(false)}
                centered
                width={1100}
                style={{ fontSize: "13px" }}
                footer={[
                    <Button style={{height: "44px"}} key="back" onClick={() => setIsNewProductModalVisible(false)}>
                        Cancelar
                    </Button>,
                    <Button style={{height: "44px"}} key="submit" type="primary" onClick={handleNewProductSave}>
                        Agregar
                    </Button>,
                ]}
            >
                <div className="ant-modal-body" style={{ fontSize: "13px" }}>
                    <Form form={form} layout="vertical" style={{ width: '100%', fontSize: "13px" }}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    label="ID Producto Externo Dropi" // Cambiado aquí
                                    name="id_producto_externo_dropi" // Cambiado aquí
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const effiValue = getFieldValue('id_producto_externo_effi');
                                                if (!value && !effiValue) {
                                                    return Promise.reject('Debe ingresar al menos un ID de Producto Externo');
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="ID Producto Externo Dropi" // Cambiado aquí
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="ID Producto Externo Effi"
                                    name="id_producto_externo_effi"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const dropiValue = getFieldValue('id_producto_externo_dropi');
                                                if (!value && !dropiValue) {
                                                    return Promise.reject('Debe ingresar al menos un ID de Producto Externo');
                                                }
                                                return Promise.resolve();
                                            }
                                        })
                                    ]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="ID Producto Externo Effi"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Categoría del producto" 
                                    name="id_categoria" 
                                    rules={[{ required: true, message: 'Por favor seleccione una categoría' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Select
                                        onChange={(value) => handleNewProductChange({ target: { name: 'id_categoria', value } })}
                                        value={newProductData.id_categoria}
                                        placeholder="Escoge una categoría"
                                        style={{ width: '100%' }}
                                    >
                                        <Option value="1">Bisuteria</Option>
                                        <Option value="2">Ropa Deportiva</Option>
                                        <Option value="3">Vaporizadores</Option>
                                        <Option value="4">Mascotas</Option>
                                        <Option value="5">Moda</Option>
                                        <Option value="6">Tecnologia</Option>
                                        <Option value="7">Cocina</Option>
                                        <Option value="8">Belleza</Option>
                                        <Option value="9">Salud</Option>
                                        <Option value="10">Hogar</Option>
                                        <Option value="11">Natural Home</Option>
                                        <Option value="12">Deportes</Option>
                                        <Option value="13">Juguetes</Option>
                                        <Option value="14">Herramientas</Option>
                                        <Option value="15">Jardinería</Option>
                                        <Option value="16">Automotriz</Option>
                                        <Option value="17">Electrodomésticos</Option>
                                        <Option value="18">Muebles</Option>
                                        <Option value="19">Oficina</Option>
                                        <Option value="20">Papelería</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Nombre del producto" 
                                    name="nombre_producto" 
                                    rules={[{ required: true, message: 'Por favor ingrese el nombre del producto' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="Nombre del producto"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label="Cantidad disponible" 
                                    name="cantidad_disponible" 
                                    rules={[{ required: true, message: 'Por favor ingrese la cantidad disponible' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Cantidad"
                                        min={1}
                                        style={{ maxWidth: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Precio Proveedor" 
                                    name="precio_proveedor" 
                                    rules={[{ required: true, message: 'Por favor ingrese el precio del proveedor' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio Proveedor"
                                        min={1}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="SKU"
                                    name="sku"
                                    rules={[{ required: true, message: 'Por favor ingrese el SKU' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="text"
                                        onChange={handleNewProductChange}
                                        placeholder="SKU"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Precio Sugerido" 
                                    name="precio_sugerido" 
                                    rules={[{ required: true, message: 'Por favor ingrese el precio sugerido' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Precio sugerido"
                                        min={1}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label="Peso (en gramos)" 
                                    name="peso_producto" 
                                    rules={[{ required: true, message: 'Por favor ingrese el peso del producto' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input
                                        type="number"
                                        onChange={handleNewProductChange}
                                        placeholder="Peso (en gramos)"
                                        min={1}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Descripción del producto" 
                                    name="descripcion_producto" 
                                    rules={[{ required: true, message: 'Por favor ingrese la descripción del producto' }]}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Input.TextArea
                                        onChange={handleNewProductChange}
                                        placeholder="Descripción"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Fotografía del producto" name="imagenes" rules={[{ required: true, message: 'Por favor suba una fotografía del producto' }]}>
                                    <Upload
                                        listType="picture-card"
                                        fileList={newProductData.imagenes}
                                        onChange={handleFileChange}
                                        beforeUpload={() => false}
                                        multiple={true}
                                    >
                                        {newProductData.imagenes.length >= 8 ? null : (
                                            <div>
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        )}
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Form.Item name="estado_variacion" valuePropName="checked" initialValue={newProductData.estado_variacion}>
                                    <CustomCheckbox
                                        checked={newProductData.estado_variacion === 1}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {newProductData.estado_variacion && (
                            <>
                                {newProductData.variaciones.map((variation, index) => (
                                    <Row gutter={[16, 16]} key={index}>
                                        <Col span={6}>
                                            <Form.Item
                                                label="ID Variación Externo Dropi"
                                                name={['variaciones', index, 'id_variacion_externo_dropi']}
                                                initialValue={variation.id_variacion_externo_dropi}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const idProductoExternoDropi = getFieldValue('id_producto_externo_dropi');
                                                            if (value && !idProductoExternoDropi) {
                                                                return Promise.reject('Debe ingresar ID Producto Externo Dropi primero');
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    value={variation.id_variacion_externo_dropi}
                                                    onChange={(e) => handleVariationsChange('id_variacion_externo_dropi', e.target.value, index)}
                                                    placeholder="ID Variación Externo Dropi"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="ID Variación Externo Effi"
                                                name={['variaciones', index, 'id_variacion_externo_effi']}
                                                initialValue={variation.id_variacion_externo_effi}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            const idProductoExternoEffi = getFieldValue('id_producto_externo_effi');
                                                            if (value && !idProductoExternoEffi) {
                                                                return Promise.reject('Debe ingresar ID Producto Externo Effi primero');
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <Input
                                                    type="text"
                                                    value={variation.id_variacion_externo_effi}
                                                    onChange={(e) => handleVariationsChange('id_variacion_externo_effi', e.target.value, index)}
                                                    placeholder="ID Variación Externo Effi"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Talla"
                                                name={['variaciones', index, 'talla']}
                                                initialValue={variation.talla}
                                            >
                                                <Select
                                                    value={variation.color}
                                                    onChange={(value) => handleVariationsChange('talla', value, index)}
                                                    placeholder="Selecciona una talla"
                                                    style={{ width: '80px' }}
                                                >
                                                    <Option value="3M">3M</Option>
                                                    <Option value="6M">6M</Option>
                                                    <Option value="9M">9M</Option>
                                                    <Option value="12M">12M</Option>
                                                    <Option value="18M">18M</Option>
                                                    <Option value="24M">24M</Option>
                                                    <Option value="XS">XS</Option>
                                                    <Option value="S">S</Option>
                                                    <Option value="M">M</Option>
                                                    <Option value="L">L</Option>
                                                    <Option value="XL">XL</Option>
                                                    <Option value="XXL">XXL</Option>
                                                    <Option value="XXXL">XXXL</Option>
                                                    <Option value="4XL">4XL</Option>
                                                    <Option value="5XL">5XL</Option>
                                                    <Option value="6XL">6XL</Option>
                                                    <Option value="6">6</Option>
                                                    <Option value="7">7</Option>
                                                    <Option value="8">8</Option>
                                                    <Option value="10">10</Option>
                                                    <Option value="12">12</Option>
                                                    <Option value="14">14</Option>
                                                    <Option value="16">16</Option>
                                                    <Option value="18">18</Option>
                                                    <Option value="19">19</Option>
                                                    <Option value="20">20</Option>
                                                    <Option value="21">21</Option>
                                                    <Option value="22">22</Option>
                                                    <Option value="23">23</Option>
                                                    <Option value="24">24</Option>
                                                    <Option value="25">25</Option>
                                                    <Option value="26">26</Option>
                                                    <Option value="27">27</Option>
                                                    <Option value="28">28</Option>
                                                    <Option value="29">29</Option>
                                                    <Option value="30">30</Option>
                                                    <Option value="31">31</Option>
                                                    <Option value="32">32</Option>
                                                    <Option value="33">33</Option>
                                                    <Option value="34">34</Option>
                                                    <Option value="35">35</Option>
                                                    <Option value="36">36</Option>
                                                    <Option value="37">37</Option>
                                                    <Option value="38">38</Option>
                                                    <Option value="39">39</Option>
                                                    <Option value="40">40</Option>
                                                    <Option value="41">41</Option>
                                                    <Option value="42">42</Option>
                                                    <Option value="43">43</Option>
                                                    <Option value="44">44</Option>
                                                    <Option value="45">45</Option>
                                                    <Option value="46">46</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Color"
                                                name={['variaciones', index, 'color']}
                                                initialValue={variation.color}
                                            >
                                                <Select
                                                    value={variation.talla}
                                                    onChange={(value) => handleVariationsChange('color', value, index)}
                                                    placeholder="Selecciona un color"
                                                    style={{ width: '120px' }}
                                                >
                                                    <Option value="Negro">Negro</Option>
                                                    <Option value="Blanco">Blanco</Option>
                                                    <Option value="Gris">Gris</Option>
                                                    <Option value="Azul">Azul</Option>
                                                    <Option value="Rojo">Rojo</Option>
                                                    <Option value="Verde">Verde</Option>
                                                    <Option value="Amarillo">Amarillo</Option>
                                                    <Option value="Marrón">Marrón</Option>
                                                    <Option value="Rosa">Rosa</Option>
                                                    <Option value="Naranja">Naranja</Option>
                                                    <Option value="Violeta">Violeta</Option>
                                                    <Option value="Beige">Beige</Option>
                                                    <Option value="Celeste">Celeste</Option>
                                                    <Option value="Turquesa">Turquesa</Option>
                                                    <Option value="Dorado">Dorado</Option>
                                                    <Option value="Plateado">Plateado</Option>
                                                    <Option value="Coral">Coral</Option>
                                                    <Option value="Borgoña">Borgoña</Option>
                                                    <Option value="Lila">Lila</Option>
                                                    <Option value="Fucsia">Fucsia</Option>
                                                    <Option value="Oliva">Oliva</Option>
                                                    <Option value="Caqui">Caqui</Option>
                                                    <Option value="Índigo">Índigo</Option>
                                                    <Option value="Aqua">Aqua</Option>
                                                    <Option value="Lavanda">Lavanda</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Cantidad Disponible"
                                                name={['variaciones', index, 'cantidad_disponible']}
                                                initialValue={variation.cantidad_disponible}
                                            >
                                                <Input
                                                    type="number"
                                                    value={variation.cantidad_disponible}
                                                    onChange={(e) => handleVariationsChange('cantidad_disponible', e.target.value, index)}
                                                    placeholder="Cantidad"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="SKU"
                                                name={['variaciones', index, 'sku']}
                                                initialValue={variation.sku}
                                            >
                                                <Input
                                                    type="text"
                                                    value={variation.sku}
                                                    onChange={(e) => handleVariationsChange('sku', e.target.value, index)}
                                                    placeholder="SKU"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                onClick={() => handleRemoveVariation(index)}
                                                style={{ marginTop: '40px', fontSize: '20px', color: 'red' }}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col span={24}>
                                        <Button
                                            type="dashed"
                                            onClick={handleAddVariation}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Añadir variación
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form>
                </div>
            </Modal>

            <Modal
                title="Generar Código Barras"
                visible={isModalVisible}
                onOk={selectedRecord?.id_variacion ? handleOkVariation : handleOk}
                onCancel={handleCancel}
                okButtonProps={{ style: { height: '44px' } }}
                cancelButtonProps={{ style: { height: '44px' } }}
            >
                <Input
                    placeholder="Ingrese código"
                    value={inputCode}
                    onChange={(e) => setInputCode(e.target.value)}
                    style={{ width: 200 }}
                />
            </Modal>
            <Modal
                title="Código de Barras"
                visible={isModalBarcodeVisible}
                onCancel={handleCancelBarcode}
                zIndex={1001} // Añadir esta línea
                footer={[
                    <Button key="download-sheet" type="primary" onClick={handleDownloadBarcodeSheet} style={{ height: '44px' }}>
                        Descargar Hoja
                    </Button>,
                    <Button key="download-single" type="primary" onClick={handleDownloadBarcode} style={{ height: '44px' }}>
                        Descargar Individual
                    </Button>,
                    <Button key="cancel" onClick={handleCancelBarcode} style={{ height: '44px' }}>
                        Cerrar
                    </Button>
                ]}
            >
                <div style={{ textAlign: 'center' }}>
                    <canvas id="barcodeCanvas"></canvas>
                </div>
            </Modal>
            <Modal
                title="Variaciones"
                visible={isVariationsModalVisible}
                onCancel={() => setIsVariationsModalVisible(false)}
                footer={null}
                width={800}
                zIndex={1000} // Añadir esta línea
            >
                <Table 
                    dataSource={selectedVariations} 
                    columns={variationsColumns} 
                    rowKey="id_variacion" 
                    pagination={{ pageSize: 5 }} 
                />
            </Modal>
            <Modal
                title="Cargar Inventario Masivo"
                visible={isBulkUploadModalVisible}
                onCancel={() => {
                    setIsBulkUploadModalVisible(false);
                    setBulkFileToUpload(null);
                }}
                footer={null}
                width={600}
            >
                <div style={{ padding: '20px' }}>
                    <Card title="Cargar Archivo de Inventario" className="upload-card">
                        <Dragger {...bulkUploadProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click o arrastra el archivo para cargarlo</p>
                        </Dragger>
                        <Button 
                            icon={<DownloadOutlined />} 
                            style={{ marginTop: '16px' }}
                            onClick={handleDownloadTemplate}
                        >
                            Descargar Plantilla
                        </Button>
                    </Card>
                </div>
            </Modal>

            <Modal
                title="Confirmar carga de archivo"
                visible={isConfirmModalVisible}
                onOk={handleConfirmBulkUpload}
                onCancel={() => {
                    setIsConfirmModalVisible(false);
                    setBulkFileToUpload(null);
                }}
                okText="Confirmar"
                cancelText="Cancelar"
            >
                <p>¿Está seguro de cargar el archivo {bulkFileToUpload?.name}?</p>
            </Modal>

            <Modal
                title="Resultados de la carga masiva"
                visible={isResultsModalVisible}
                onOk={() => setIsResultsModalVisible(false)}
                onCancel={() => setIsResultsModalVisible(false)}
                width={1000}
            >
                {uploadResults && (
                    <div>
                        <h3>Productos Almacenados ({uploadResults.productos_almacenados?.length || 0})</h3>
                        {uploadResults.productos_almacenados?.length > 0 ? (
                            <Table
                                dataSource={uploadResults.productos_almacenados
                                    .map(item => ({
                                        ...item,
                                        nombre_producto: item.inventario.nombre_producto || 'No disponible',
                                        sku: item.inventario.sku || 'No disponible'
                                    }))
                                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                                }
                                columns={[
                                    {
                                        title: 'Nombre del Producto',
                                        dataIndex: 'nombre_producto',
                                        key: 'nombre_producto',
                                        render: (text) => text || 'No disponible'
                                    },
                                    {
                                        title: 'SKU',
                                        dataIndex: 'sku',
                                        key: 'sku'
                                    },
                                    {
                                        title: 'Estado',
                                        dataIndex: 'message',
                                        key: 'message'
                                    },
                                    {
                                        title: 'Fecha',
                                        dataIndex: 'created_at',
                                        key: 'created_at',
                                        render: (date) => new Date(date).toLocaleString()
                                    }
                                ]}
                                pagination={false}
                                size="small"
                                scroll={{ x: 'max-content' }}
                            />
                        ) : (
                            <p>No se almacenaron productos</p>
                        )}

                        <h3 style={{ marginTop: '20px' }}>Productos Rechazados ({uploadResults.productos_rechazados?.length || 0})</h3>
                        {uploadResults.productos_rechazados?.length > 0 ? (
                            <Table
                                dataSource={uploadResults.productos_rechazados}
                                columns={[
                                    {
                                        title: 'Producto',
                                        dataIndex: 'producto',
                                        key: 'producto'
                                    },
                                    {
                                        title: 'Fila',
                                        dataIndex: 'de_la_fila',
                                        key: 'de_la_fila'
                                    },
                                    {
                                        title: 'Mensaje de Error',
                                        dataIndex: 'mensaje',
                                        key: 'mensaje',
                                        width: '50%'
                                    }
                                ]}
                                pagination={false}
                                size="small"
                            />
                        ) : (
                            <p>No hubo productos rechazados</p>
                        )}
                    </div>
                )}
            </Modal>
        </>
    );
};

export default InventarioBodega;