import React, { useEffect, useState } from 'react'
import confetti from 'canvas-confetti';
import { Form, Input, Button, Select, Typography, message, Modal, Radio, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import Ciudades from '../../ciudades/ciudades';
import axios from 'axios';
import './SendClient.css';

const { Option } = Select;
const { Text } = Typography;

const SendClientPage = (props) => {
  const [form] = Form.useForm();
  const [costo, setCosto] = useState(null);
  const [precioProveedor, setPrecioProveedor] = useState(null);
  const [transportadora, setTransportadora] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState(1);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para controlar la rueda de carga
  const [subtotal, setSubtotal] = useState(0);
  const [idsucursal, setIdsucursal] = useState('');
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [selectedBodegaStock, setSelectedBodegaStock] = useState(null);
  const [statusWhatsapp, setStatusWhatsapp] = useState(false);
  const [showMultiProviderAlert, setShowMultiProviderAlert] = useState(false);
  const [totalProviders, setTotalProviders] = useState(0);

  const { 
    estadoBodega, 
    inventarioBodega, 
    cart = [], // Add default value
    isCartCheckout = false // Add default value
  } = props;

  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  useEffect(() => {
    const fetchSucursalData = async () => {
      try {
        const sucursalResponse = await axios.get(`https://99envios.app/api/online/sucursal/${idsucursal}`);
        setStatusWhatsapp(sucursalResponse.data.status_whatsapp === 1);
        console.log('Status WhatsApp:', sucursalResponse.data.status_whatsapp);
      } catch (error) {
        console.error('Error fetching sucursal data:', error);
      }
    };

    if (idsucursal) {
      fetchSucursalData();
    }
  }, [idsucursal]);

  useEffect(() => {
    // Solo mostrar la alerta de múltiples proveedores si es checkout de carrito
    // y hay más de un proveedor
    if (isCartCheckout && cart && cart.length > 0) {
      const uniqueProviders = [...new Set(cart.map(item => item.provider))];
      setTotalProviders(uniqueProviders.length);
      if (uniqueProviders.length > 1) {
        setShowMultiProviderAlert(true);
      }
    }
  }, [isCartCheckout, cart]);

  const [cotizaciones, setCotizaciones] = useState({
    coordinadora: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    interrapidisimo: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    servientrega: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 },
    tcc: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0, sobreflete: 0 }
  });
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    notes: '',
    payment: '',
    product: '',
    quantity: 1,
    salePrice: 0,
    store: '',
    aplicaContrapago: true  // Nuevo campo para AplicaContrapago
  });
  const [producto, setProducto] = useState({
    id_producto: '',
    id_sucursal: '',
    id_categoria: '',
    precio_proveedor: '',
    precio_sugerido: '',
    cantidad_disponible: '',
    created_at: '',
    descripcion_producto: '',
    estado_variacion: '',
    imagenes: [],
    informacion_adicional: '',
    nombre_producto: '',
    peso_producto: '',
    sku: '',
    updated_at: '',
    variaciones: []
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);

  const cotizacionData = {
    destino: {
      nombre: "",
      codigo: formValues.city,
    },
    origen: {
      nombre: "",
      codigo: "",
    },
    IdTipoEntrega: 1,
    IdServicio: 1,
    peso: isCartCheckout 
      ? 1 // Default weight for cart checkout - you can adjust this as needed
      : (producto?.peso_producto || 1), // Default to 1 if peso_producto is undefined
    largo: 1,
    ancho: 1,
    alto: 1,
    fecha: "28-06-2024",
    AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
    valorDeclarado: isCartCheckout
      ? cart.reduce((total, item) => total + (item.suggestedPrice * item.quantity), 0)
      : 15000,
  };

  const images = [
    '/Images/coordi.png',
    '/Images/inter.png',
    '/Images/servi.png',
    '/Images/tcc.svg'
  ];

  const envios = [
    'coordinadora',
    'interrapidisimo',
    'servientrega',
    'tcc'
  ];

  const handleChange = (value) => {
    // Skip validation for cart checkout
    if (isCartCheckout) {
      setInputValue(value);
      form.setFieldsValue({ quantity: value });
      return;
    }

    // Original validation logic for single product with safe checks
    const maxStock = estadoBodega ? selectedBodegaStock : producto?.cantidad_disponible;
    if (!maxStock && maxStock !== 0) {
      message.error('Seleccione una bodega o revise el stock disponible');
      return;
    }

    if (value < 1 || value > maxStock) {
      message.error(`La cantidad debe estar entre 1 y ${maxStock}`);
      return;
    }

    setInputValue(value);
    form.setFieldsValue({ quantity: value });
  };


  function formatCurrency(value, locale = 'es-CO', currency = 'COP') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }

  useEffect(() => {
    const fetchProducts = async () => {
      if (!isCartCheckout) { // Solo cargar producto si no es checkout de carrito
        setLoading(true);
        try {
          const { data } = await axios.get('https://api.99envios.app/api/inventarios');
          const foundProduct = data.find(prod => prod.id_producto === props.prodID) || {
            precio_sugerido: 0,
            precio_proveedor: 0,
            nombre_producto: '',
            descripcion_producto: '',
          };
          
          setProducto(foundProduct);
          setCosto(foundProduct.precio_sugerido);
          setPrecioProveedor(foundProduct.precio_proveedor);
          form.setFieldsValue({
            salePrice: foundProduct.precio_sugerido,
            product: foundProduct.nombre_producto,
            notes: foundProduct.descripcion_producto
          });
        } catch (error) {
          console.error('Error al cargar productos:', error);
          setProducto({});
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProducts();
  }, [isCartCheckout, props.prodID, form]);

  const onValuesChangePedido = (changedValues, allValues) => {
    if (changedValues.salePrice || changedValues.quantity) {
      const newSalePrice = Number(changedValues.salePrice || allValues.salePrice);
      const newQuantity = Number(changedValues.quantity || allValues.quantity);
      const newSubtotal = newSalePrice * newQuantity;
      setSubtotal(newSubtotal);
      setFormValues({ ...allValues, salePrice: newSalePrice, quantity: newQuantity });
      handleAutomaticRequote(newSubtotal, allValues);
    } else {
      setFormValues(allValues);
    }
    calculateTotal(allValues);
  };

  const handleAutomaticRequote = async (newSubtotal, currentFormValues) => {
    setLoading(true);
    try {
      const pesoTotal = isCartCheckout 
        ? 1 // Default weight for cart checkout
        : (producto?.peso_producto || 1) * Number(currentFormValues.quantity);

      const reqCotizacionData = {
        destino: {
          nombre: "",
          codigo: currentFormValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: currentFormValues.aplicaContrapago,
        valorDeclarado: newSubtotal,
      };

      console.log('Datos para la nueva cotización:', reqCotizacionData);

      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        reqCotizacionData
      );
      console.log('Respuesta de la API (requote):', response.data);
      setCotizaciones(response.data);
    } catch (error) {
      console.error('Error al generar la nueva cotización:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotal = (values) => {
    const subtotal = values.salePrice * values.quantity;
    const shippingCost = transportadora; // Este es el costo de envío seleccionado
    const supplierCost = producto.precio_proveedor * values.quantity;
    const total = subtotal - supplierCost - shippingCost;
    return total;
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
    const cotizacion = cotizaciones[envios[index]] || {};
    const valor = cotizacion.valor || 0;
    const valorContrapago = cotizacion.valor_contrapago || cotizacion.valorContrapago || 0;
    const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
    const sobreflete = cotizacion.sobreflete || 0;
    const totalCotizacion = valor + valorContrapago + comisionInterna + sobreflete;

    setTransportadora(totalCotizacion);
    form.setFieldsValue({ total: calculateTotal({ ...formValues, salePrice: formValues.salePrice, quantity: inputValue }) });
  };

  const handleCotizarClick = async () => {
    setLoading(true);
    try {
      // Mostrar valores antes de la validación
      console.log('Valores antes de la validación:', form.getFieldsValue());

      // Convertir valores a números antes de validar
      form.setFieldsValue({
        salePrice: Number(formValues.salePrice),
        quantity: Number(formValues.quantity),
      });

      // Mostrar valores después de convertir
      console.log('Valores después de la conversión:', form.getFieldsValue());

      // Validar todos los campos antes de cotizar
      await form.validateFields();

      // Calcular el subtotal con valores numéricos
      const subtotal = Number(formValues.salePrice) * Number(formValues.quantity);
      console.log('Subtotal calculado:', subtotal);

      const pesoTotal = producto.peso_producto * Number(formValues.quantity);

      // Actualizar cotizacionData con el valor del subtotal
      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: formValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: formValues.aplicaContrapago, // Usar el valor del formulario
        valorDeclarado: subtotal, // Usar el valor del subtotal
      };

      // Mostrar datos que se enviarán en la petición
      console.log('Datos para la cotización:', cotizacionData);

      // Intentar la petición POST a la API
      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        cotizacionData
      );
      console.log('Respuesta de la API:', response.data);
      setCotizaciones(response.data);
      message.success('Cotización generada con éxito');
    } catch (error) {
      // Mostrar mensaje específico si la validación es la que falla
      if (error.errorFields) {
        message.error('Error de validación: revise los campos del formulario.');
        console.error('Error de validación:', error.errorFields);
        console.log('Campos que fallaron la validación:', error.errorFields);
      } else {
        message.error('Error al generar la cotización');
        console.error('Error al generar la cotización:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isModalVisible) {
      confetti({
        particleCount: 150,
        spread: 70,
        origin: { y: 0.5 }, // Ajustar la altura para que el confeti aparezca en el modal
        zIndex: 9999, // Asegúrate de que el confeti esté por encima del modal
      });
    }
  }, [isModalVisible]);

  // Reemplazar la función openPDF existente con esta nueva versión
  const openPDF = (pdfUrl) => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
    } else {
      message.error('No se encontró la URL del PDF');
    }
  };

  const handleConfirmarClick = async () => {
    setLoading(true);
    try {
      const totalGanar = calculateTotal(formValues);
      if (totalGanar < 0) {
        message.error('El monto a ganar es negativo. No puedes confirmar y enviar el pedido.');
        return;
      }

      await form.validateFields();

      if (isCartCheckout) {
        // Agrupar productos por proveedor
        const providerGroups = cart.reduce((acc, item) => {
          if (!acc[item.provider]) {
            acc[item.provider] = [];
          }
          acc[item.provider].push(item);
          return acc;
        }, {});

        const completedOrders = [];

        // Procesar cada grupo de proveedor
        for (const [provider, items] of Object.entries(providerGroups)) {
          const orderData = {
            productos: items.map(item => ({
              IdProducto: item.id,
              IdVariacion: '',
              valorDeclarado: item.suggestedPrice,
              Cantidad: item.quantity
            })),
            AplicaContrapago: formValues.aplicaContrapago,
            Destinatario: {
              nombre: formValues.firstName,
              primerApellido: formValues.lastName.split(' ')[0] || '',
              segundoApellido: formValues.lastName.split(' ')[1] || '',
              telefono: formValues.phone,
              direccion: formValues.address,
              idLocalidad: formValues.city,
              correo: formValues.email
            },
            Observaciones: `${formValues.notes} - Pedido del proveedor: ${provider}`,
            transportadora: {
              pais: 'colombia',
              nombre: envios[selectedImage] || ''
            }
          };

          const id = localStorage.getItem('usuario_id');
          const token = localStorage.getItem('token');

          const config = {
            headers: {
              'Authorization': token ? `Bearer ${token}` : '',
              'Content-Type': 'application/json'
            }
          };

          try {
            const response = await axios.post(
              `https://99envios.app/api/orden_save/${id}`,
              orderData,
              config
            );

            if (response.status === 200) {
              completedOrders.push({
                provider,
                orderResponse: response.data
              });

              if (statusWhatsapp && response.data.pdf) {
                const numeroPreenvio = response.data.pdf.split('_').pop().split('.')[0];
                try {
                  await axios.post(
                    `https://99envios.app/api/online/whatsapp/message_template_envios/${numeroPreenvio}`,
                    {}
                  );
                } catch (whatsAppError) {
                  console.error('Error enviando WhatsApp:', whatsAppError);
                }
              }

              message.success(`Pedido para ${provider} procesado correctamente`);
            }
          } catch (orderError) {
            message.error(`Error al procesar el pedido para ${provider}`);
            console.error('Error en pedido:', orderError);
          }
        }

        if (completedOrders.length > 0) {
          setOrderResponse({
            completedOrders,
            totalOrders: Object.keys(providerGroups).length
          });
          setIsModalVisible(true);
          confetti({
            particleCount: 150,
            spread: 70,
            origin: { y: 0.5 }
          });
        }

      } else {
        // Lógica original para un solo producto
        const orderData = {
          productos: [{
            IdProducto: producto.id_producto,
            IdVariacion: selectedVariation || '',
            valorDeclarado: formValues.salePrice,
            Cantidad: formValues.quantity
          }],
          AplicaContrapago: formValues.aplicaContrapago,
          Destinatario: {
            nombre: formValues.firstName,
            primerApellido: formValues.lastName.split(' ')[0] || '',
            segundoApellido: formValues.lastName.split(' ')[1] || '',
            telefono: formValues.phone,
            direccion: formValues.address,
            idLocalidad: formValues.city,
            correo: formValues.email
          },
          Observaciones: formValues.notes,
          transportadora: {
            pais: 'colombia',
            nombre: envios[selectedImage] || ''
          }
        };

        const id = localStorage.getItem('usuario_id');
        const token = localStorage.getItem('token');

        const response = await axios.post(
          `https://99envios.app/api/orden_save/${id}`,
          orderData,
          {
            headers: {
              'Authorization': token ? `Bearer ${token}` : '',
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.status === 200) {
          setOrderResponse(response.data);
          setIsModalVisible(true);

          if (statusWhatsapp && response.data.pdf) {
            const numeroPreenvio = response.data.pdf.split('_').pop().split('.')[0];
            try {
              await axios.post(
                `https://99envios.app/api/online/whatsapp/message_template_envios/${numeroPreenvio}`,
                {}
              );
            } catch (whatsAppError) {
              console.error('Error enviando WhatsApp:', whatsAppError);
            }
          }

          message.success('Pedido confirmado y enviado con éxito');
        }
      }
    } catch (error) {
      message.error('Error al procesar el pedido');
      console.error('Error general:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleVariationChange = (value) => {
    setSelectedVariation(value);
    const selectedVar = producto.variaciones.find(variation => variation.id === value);
    setInputValue(1);  // Reinicia la cantidad a 1 al cambiar de variación
    form.setFieldsValue({ quantity: 1, salePrice: producto.precio_sugerido });
    message.info(`Seleccionaste la variación con ID: ${value}, Talla: ${selectedVar.talla}, Color: ${selectedVar.color}`);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setOrderResponse(null); // Reseteamos la respuesta al cerrar el modal
  };


  // Determinar cantidad disponible con manejo seguro de undefined
  const cantidadDisponible = isCartCheckout
    ? null // No es necesario para checkout de carrito
    : (estadoBodega
      ? (selectedBodegaStock || (inventarioBodega?.reduce((acc, b) => acc + (b?.cantidad_disponible || 0), 0) || 0))
      : (producto?.cantidad_disponible || 0));

  const handleBodegaChange = (value) => {
    const bodegaSeleccionada = inventarioBodega.find((bodega) => bodega.id === parseInt(value, 10));
    setSelectedBodega(bodegaSeleccionada);
    setSelectedBodegaStock(bodegaSeleccionada?.cantidad_disponible || 0);
  };

  const MultiProviderAlert = () => (
    <div className="multi-provider-alert">
      <div className="alert-content">
        <h4>⚠️ Aviso Importante</h4>
        <p>Tu carrito contiene productos de {totalProviders} proveedores diferentes.</p>
        <p>Deberás completar {totalProviders} procesos de cotización y envío separados, uno por cada proveedor.</p>
        <p>Esto es necesario porque cada proveedor maneja sus envíos de manera independiente.</p>
        <button 
          className="alert-understand-btn" 
          onClick={() => setShowMultiProviderAlert(false)}
        >
          Entendido, continuar
        </button>
      </div>
    </div>
  );

  const renderOrderModal = () => {
    if (isCartCheckout && orderResponse?.completedOrders) {
      return (
        <Modal
          title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Órdenes</span>}
          visible={isModalVisible}
          onCancel={handleModalOk}
          footer={null}
          centered
          width={800}
        >
          <div style={{ maxHeight: '60vh', overflow: 'auto', padding: '20px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
              Se han procesado {orderResponse.completedOrders.length} de {orderResponse.totalOrders} pedidos
            </h2>
            {orderResponse.completedOrders.map((order, index) => (
              <div key={index} style={{
                border: '1px solid #e8e8e8',
                borderRadius: '8px',
                padding: '15px',
                marginBottom: '15px'
              }}>
                <h3>Pedido {index + 1} - Proveedor: {order.provider}</h3>
                <p><strong>Número de Orden:</strong> {order.orderResponse.numero_orden}</p>
                <Button
                  type="primary"
                  onClick={() => openPDF(order.orderResponse.pdf)}
                  style={{ marginTop: '10px' }}
                >
                  Ver Guía
                </Button>
              </div>
            ))}
          </div>
        </Modal>
      );
    }

    // Modal original para un solo pedido
    return (
      <Modal
        title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Orden</span>}
        visible={isModalVisible}
        onCancel={handleModalOk}
        footer={[
          <Button
            key="open"
            type="primary"
            onClick={() => openPDF(orderResponse?.pdf)}
          >
            Ver Guía
          </Button>,
        ]}
        centered
        width={600}
      >
        {orderResponse && (
          <div style={{ lineHeight: '1.6', fontSize: '16px' }}>
            <div style={{ marginBottom: '16px', textAlign: 'center' }}>
              <strong style={{ fontSize: '18px', textDecoration: 'underline' }}>Resumen de la Orden</strong>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <p><strong>Mensaje:</strong> {orderResponse.mensaje}</p>
              <p><strong>Número de Orden:</strong> {orderResponse.numero_orden}</p>
            </div>
            <div style={{
              padding: '10px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              marginBottom: '16px'
            }}>
              <p><strong>Valor de Venta:</strong> {formatCurrency(orderResponse.valor_venta)}</p>
              <p><strong>Valor de Proveedor:</strong> {formatCurrency(orderResponse.valor_proveedro)}</p>
              <p><strong>Valor de Envío:</strong> {formatCurrency(transportadora)}</p>
              <p><strong>Valor de Comisión:</strong> $ 0</p>
              <p><strong>Valor de Utilidad menos 4x1000:</strong> {formatCurrency(calculateTotal(formValues))}</p>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  // Modificar el renderizado del formulario
  const renderProductSection = () => {
    if (isCartCheckout) {
      // Calcular sumas totales
      const totalSuggestedPrice = cart.reduce((total, item) => 
        total + (item.suggestedPrice * item.quantity), 0);
      const totalSupplierPrice = cart.reduce((total, item) => 
        total + (item.supplierPrice * item.quantity), 0);

      return (
        <>
          <div>
            <h3>Productos en el carrito:</h3>
            {cart.map((item, index) => (
              <div key={index} className="cart-product-item">
                <p>{item.name}</p>
                <p>Cantidad: {item.quantity}</p>
                <p>Precio: ${item.suggestedPrice.toLocaleString()}</p>
              </div>
            ))}
          </div>

          <Text>Precio sugerido: ${totalSuggestedPrice.toLocaleString()}</Text>
          <Form.Item
            label="Precio de venta"
            name="salePrice"
            initialValue={totalSuggestedPrice}
            rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
          >
            <Input type="number" />
          </Form.Item>

          <div className="order-summary-total">
            <div className="total-label">Precio Proveedor :</div>
            <div className="total-amount">{formatCurrency(totalSupplierPrice)}</div>
          </div>
        </>
      );
    }

    // Para un solo producto mantener el código original
    return (
      <>
        <Form.Item
          label="Producto"
          name="product"
          initialValue={props.name} // Usar props.name
          rules={[{ required: true, message: 'Por favor selecciona un producto' }]}
        >
          <Input disabled />
        </Form.Item>

        <Text>Precio sugerido: ${props.suggestedPrice}</Text>
        <Form.Item
          label="Precio de venta"
          name="salePrice"
          initialValue={props.suggestedPrice}
          rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
        >
          <Input type="number" />
        </Form.Item>

        <Text>Stock disponible: {props.stock}</Text>
        <Form.Item
          label="Cantidad"
          name="quantity"
          initialValue={1}
          rules={[
            { required: true, message: 'Por favor ingresa la cantidad' },
            {
              validator: (_, value) => {
                if (value < 1 || value > props.stock) {
                  return Promise.reject(`La cantidad debe estar entre 1 y ${props.stock}`);
                }
                return Promise.resolve();
              }
            }
          ]}
        >
          <Input type="number" />
        </Form.Item>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      {showMultiProviderAlert && isCartCheckout && <MultiProviderAlert />}
      <div className="enviosDrop">
        <div className="formularioEnvio">
          <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
            <Form.Item
              name="firstName"
              label="Nombre(s) destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el nombre del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="lastName"
              label="Apellido(s) destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el apellido del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Teléfono destinatario"
              rules={[{ required: true, message: 'Por favor ingrese el teléfono del destinatario' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email destinatario (Opcional)"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label="Dirección destinatario"
              rules={[{ required: true, message: 'Por favor ingrese la dirección del destinatario' }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="city"
              label="Ciudad destinatario"
              rules={[{ required: true, message: 'Seleccione la ciudad del destinatario' }]}
            >
              <Select
                showSearch
                placeholder="Seleccione una ciudad"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                 {Ciudades.map((ciudad) => (
                  <Option key={ciudad.value} value={ciudad.value}>
                    {ciudad.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="notes"
              label="Notas para el Proveedor"
              rules={[{ required: true, message: 'Por favor ingrese alguna nota' }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Button type="primary" onClick={handleCotizarClick} block>
              Cotizar
            </Button>
          </Form>
        </div>

        <div className="transportadora">
          {images.map((image, index) => {
            const cotizacion = cotizaciones[envios[index]] || {};
            const valor = cotizacion.valor || 0;
            const valorContrapago = cotizacion.valor_contrapago || cotizacion.valorContrapago || 0;
            const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
            const sobreflete = cotizacion.sobreflete || 0;
            const totalCotizacion = valor + valorContrapago + comisionInterna + sobreflete;

            return (
              <div
                key={index}
                className="flete"
                style={{
                  border: selectedImage === index ? '1px solid rgba(247, 118, 67, 0.5)' : '1px solid rgba(128, 128, 128, 0.5)',
                  cursor: 'pointer'
                }}
                onClick={() => handleImageClick(index)}
              >
                <img src={image} alt={`Imagen ${index + 1}`} />
                <p>{formatCurrency(totalCotizacion)}</p>
              </div>
            );
          })}
        </div>

        <div className="pedidoEnvio">
          <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
            {renderProductSection()}
            {/* Select para Variaciones */}
            {producto.variaciones.length > 0 && (
              <Form.Item
                name="variation"
                label="Selecciona una variación"
                rules={[{ required: true, message: 'Por favor selecciona una variación' }]}
              >
                <Select placeholder="Selecciona una variación" onChange={handleVariationChange}>
                  {producto.variaciones.map(variation => (
                    <Option key={variation.id} value={variation.id}>
                      {`ID: ${variation.id}, Talla: ${variation.talla}, Color: ${variation.color}, Stock: ${variation.cantidad_disponible}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Text>Precio sugerido: ${costo}</Text>
            <Form.Item
              label="Precio de venta"
              name="salePrice"
              initialValue={producto.precio_sugerido}
              rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
            >
              <Input type="number" />
            </Form.Item>

            {!isCartCheckout && (
              <Text>
                Existencia: {estadoBodega
                  ? (selectedBodegaStock !== null ? selectedBodegaStock : 'Seleccione una bodega')
                  : (producto?.cantidad_disponible || 'No disponible')}
              </Text>
            )}

            <Form.Item
              label="Cantidad"
              name="quantity"
              initialValue={1}
              rules={[
                { required: true, message: 'Por favor ingresa la cantidad' },
                {
                  validator: (_, value) => {
                    // Skip validation for cart checkout
                    if (isCartCheckout) {
                      return Promise.resolve();
                    }

                    const maxStock = estadoBodega
                      ? selectedBodegaStock
                      : producto?.cantidad_disponible;

                    if (!maxStock && maxStock !== 0) {
                      return Promise.reject('Seleccione una bodega o revise el stock disponible');
                    }

                    return value < 1 || value > maxStock
                      ? Promise.reject(`La cantidad debe estar entre 1 y ${maxStock}`)
                      : Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                value={inputValue}
                onChange={(e) => handleChange(Number(e.target.value))}
                disabled={isCartCheckout} // Disable input for cart checkout
              />
            </Form.Item>


            <Form.Item
              name="aplicaContrapago"
              label="¿Aplica Contrapago?"
              initialValue={true}
            >
              <Radio.Group disabled>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {estadoBodega && (
              <div>
                <label name="bodega" rules={[{ required: estadoBodega, message: 'Seleccione una bodega' }]}>Bodega disponible:</label>
                <Select placeholder="Seleccione una bodega" onChange={handleBodegaChange}>
                  {inventarioBodega.map((bodega) => (
                    <Option key={bodega.id} value={bodega.id}>
                      {bodega.ciudad_bodega} (Stock: {bodega.cantidad_disponible})
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            {/* {estadoBodega && inventarioBodega.length > 0 && (
              <div>
                <label>Selecciona bodega:</label>
                <select onChange={handleBodegaChange} defaultValue="">
                  <option value="" disabled>-- Elige una bodega --</option>
                  {inventarioBodega.map((bodega) => (
                    <option key={bodega.id} value={bodega.id}>
                      {bodega.ciudad_bodega} (Stock: {bodega.cantidad_disponible})
                    </option>
                  ))}
                </select>
              </div>
            )} */}

          </Form>
          <div className="order-summary">
            <div className="order-summary-header">Resumen de la orden</div>
            <div className="order-summary-content">
              <div className="order-summary-item">
                <div className="item-description">{formValues.product}</div>
                <div className="item-price">{formatCurrency(formValues.salePrice)} x {formValues.quantity}</div>
              </div>
              <div className="order-summary-total">
                <div className="total-label">Sub Total :</div>
                <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
              </div>
              {/* precio provedor */}
              <div className="order-summary-total">
                <div className="total-label">Precio Proveedor :</div>
                <div className="total-amount">{formatCurrency(precioProveedor)}</div>
              </div>
              {/* precio de envio con las sumas */}

              <div className="order-summary-total">
                <div className="total-label">Precio de Envío :</div>
                <div className="total-amount">{formatCurrency(transportadora)}</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Comisión de la plataforma :</div>
                <div className="total-amount">$ 0</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Monto a ganar cuando esté entregado :</div>
                <div className="total-amount" style={{ color: calculateTotal(formValues) < 0 ? 'red' : 'black' }}>
                  {formatCurrency(calculateTotal(formValues))}
                </div>
              </div>
              {calculateTotal(formValues) < 0 && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  <strong>Advertencia:</strong> El monto a ganar es negativo. No puedes confirmar y enviar el pedido.
                </div>
              )}

              <div className="order-summary-total">
                <div className="total-label">Total:</div>
                <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
              </div>
              <div className="order-summary-footer">
                <Button
                  type="primary"
                  onClick={handleConfirmarClick}
                  block
                  disabled={calculateTotal(formValues) < 0} // Deshabilita el botón si el total es negativo
                >
                  Confirmar y Enviar Pedido
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Orden</span>}
          visible={isModalVisible}
          onCancel={handleModalOk}
          footer={[
            <Button
              key="open"
              type="primary"
              onClick={() => openPDF(orderResponse?.pdf)}
            >
              Ver Guía
            </Button>,
          ]}
          centered
          width={600}
          bodyStyle={{
            padding: '20px',
            backgroundColor: '#f7f7f7',
            borderRadius: '8px',
          }}
        >
          {orderResponse && (
            <div style={{ lineHeight: '1.6', fontSize: '16px' }}>
              <div style={{ marginBottom: '16px', textAlign: 'center' }}>
                <strong style={{ fontSize: '18px', textDecoration: 'underline' }}>Resumen de la Orden</strong>
              </div>
              <div style={{ marginBottom: '16px' }}>
                <p><strong>Mensaje:</strong> {orderResponse.mensaje}</p>
                <p><strong>Número de Orden:</strong> {orderResponse.numero_orden}</p>
              </div>
              <div style={{
                padding: '10px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                marginBottom: '16px'
              }}>
                <p><strong>Valor de Venta:</strong> {formatCurrency(orderResponse.valor_venta)}</p>
                <p><strong>Valor de Proveedor:</strong> {formatCurrency(orderResponse.valor_proveedro)}</p>
                <p><strong>Valor de Envío:</strong> {formatCurrency(transportadora)}</p>
                <p><strong>Valor de Comisión:</strong> $ 0</p>
                <p><strong>Valor de Utilidad menos 4x1000:</strong> {formatCurrency(calculateTotal(formValues))}</p>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </Spin>
  );
};

export default SendClientPage;
