import React, { useState, useEffect } from "react";
import NavbarAdminMex from '../components/navbar';
import FormPQR from './formpqr';
import { Table, Button, Input, Modal, Typography, message } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';
import './pqr.css';

const Pqr = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedBranchRows, setExpandedBranchRows] = useState(new Set());
  const [expandedResponse99Rows, setExpandedResponse99Rows] = useState(new Set());
  const [expandedPqrResponseRows, setExpandedPqrResponseRows] = useState(new Set());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/online/pqr');
      const formattedData = response.data.map(item => ({
        key: item.id,
        ticketNumber: item.id,
        branchCode: item.codigo_sucursal,
        requestStatus: item.estado_solicitud,
        guideNumber: item.numero_guia,
        carrier: item.transportadora,
        entryDate: formatDate(item.created_at),
        category: item.categoria,
        pqrDate: formatDate(item.fecha_entrega),
        branchData: `${item.nuevo_nombre} ${item.nuevo_telefono} ${item.nueva_direccion}`,
        returnReason: item.motivo_devolucion,
        dimensions: item.dimensiones,
        bankData: item.nombre_banco ? `${item.nombre_banco}, cuenta: ${item.numero_cuenta}` : 'N/A',
        additionalInfo: item.descripcion,
        response99: item.respuesta_auto,
        inProcessResponse: item.respuesta_proceso,
        pqrResponse: item.respuesta_manual,
        file: item.adjunto,
        file99: item.adjunto_99envios,
        packingAttachment: item.adjunto_embalaje,
        contentAttachment: item.adjunto_contenido,
        packingAttachmentEnd: item.adjunto_embalaje_fin,
        finish: 'Finalizar',
      }));

      const sortedData = formattedData.sort((a, b) => b.ticketNumber - a.ticketNumber);

      setData(sortedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Error al cargar los datos. Por favor, intente de nuevo.');
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // This will return YYYY-MM-DD
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const numericCompare = (a, b) => {
    const numA = parseFloat(a);
    const numB = parseFloat(b);
    if (isNaN(numA) && isNaN(numB)) return 0;
    if (isNaN(numA)) return -1;
    if (isNaN(numB)) return 1;
    return numA - numB;
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "Respuesta de PQR.xlsx");
  };

  const safeCompare = (a, b) => {
    if (a == null && b == null) return 0;
    if (a == null) return -1;
    if (b == null) return 1;
    return a.toString().localeCompare(b.toString());
  };

  const getColumnSearchProps = (title, dataIndex, isNumeric = false) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="pqr-filter-dropdown">
        <div className="pqr-filter-input-container">
          <Input
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="pqr-filter-input"
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            className="pqr-filter-search-button"
          />
        </div>
        <Button
          onClick={clearFilters}
          size="small"
          className="pqr-filter-reset-button"
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#3F51B5' : undefined }} />,
    onFilter: (value, record) => {
      if (isNumeric) {
        const recordValue = record[dataIndex]?.toString();
        return recordValue?.startsWith(value);
      } else {
        return record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '';
      }
    },
  });

  const handleFinish = async (ticketNumber) => {
    try {
      await axios.post(`https://99envios.app/api/online/pqr_finalizar/${ticketNumber}`);
      message.success('PQR finalizada exitosamente');
      fetchData(); // Recargar los datos
    } catch (error) {
      console.error('Error al finalizar PQR:', error);
      message.error('Error al finalizar la PQR. Por favor intente nuevamente');
    }
  };

  const toggleExpand = (record) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(record.key)) {
      newExpandedRows.delete(record.key);
    } else {
      newExpandedRows.add(record.key);
    }
    setExpandedRows(newExpandedRows);
  };

  const toggleBranchExpand = (record) => {
    const newExpandedRows = new Set(expandedBranchRows);
    if (newExpandedRows.has(record.key)) {
      newExpandedRows.delete(record.key);
    } else {
      newExpandedRows.add(record.key);
    }
    setExpandedBranchRows(newExpandedRows);
  };

  const toggleResponse99Expand = (record) => {
    const newExpandedRows = new Set(expandedResponse99Rows);
    if (newExpandedRows.has(record.key)) {
      newExpandedRows.delete(record.key);
    } else {
      newExpandedRows.add(record.key);
    }
    setExpandedResponse99Rows(newExpandedRows);
  };

  const togglePqrResponseExpand = (record) => {
    const newExpandedRows = new Set(expandedPqrResponseRows);
    if (newExpandedRows.has(record.key)) {
      newExpandedRows.delete(record.key);
    } else {
      newExpandedRows.add(record.key);
    }
    setExpandedPqrResponseRows(newExpandedRows);
  };

  const columns = [
    {
      title: "Número Ticket",
      dataIndex: "ticketNumber",
      key: "ticketNumber",
      width: 100,
      sorter: (a, b) => numericCompare(a.ticketNumber, b.ticketNumber),
      ...getColumnSearchProps("Número Ticket", "ticketNumber", true),
    },
    {
      title: "Código Sucursal",
      dataIndex: "branchCode",
      key: "branchCode",
      width: 100,
      sorter: (a, b) => numericCompare(a.branchCode, b.branchCode),
      ...getColumnSearchProps("Código Sucursal", "branchCode", true),
    },
    {
      title: "Estado Solicitud",
      dataIndex: "requestStatus",
      key: "requestStatus",
      width: 120,
      sorter: (a, b) => safeCompare(a.requestStatus, b.requestStatus),
      ...getColumnSearchProps("Estado Solicitud", "requestStatus"),
    },
    {
      title: "Número de Guía",
      dataIndex: "guideNumber",
      key: "guideNumber",
      width: 140,
      sorter: (a, b) => numericCompare(a.guideNumber, b.guideNumber),
      ...getColumnSearchProps("Número de Guía", "guideNumber", true),
    },
    {
      title: "Transportadora",
      dataIndex: "carrier",
      key: "carrier",
      width: 120,
      sorter: (a, b) => safeCompare(a.carrier, b.carrier),
      ...getColumnSearchProps("Transportadora", "carrier"),
    },
    {
      title: "Fecha Ingreso",
      dataIndex: "entryDate",
      key: "entryDate",
      width: 100,
      sorter: (a, b) => safeCompare(a.entryDate, b.entryDate),
      ...getColumnSearchProps("Fecha Ingreso", "entryDate"),
    },
    {
      title: "Categoría o Motivo",
      dataIndex: "category",
      key: "category",
      width: 260,
      sorter: (a, b) => safeCompare(a.category, b.category),
      ...getColumnSearchProps("Categoría o Motivo", "category"),
    },
    {
      title: "Fecha PQR",
      dataIndex: "pqrDate",
      key: "pqrDate",
      width: 120,
      sorter: (a, b) => safeCompare(a.pqrDate, b.pqrDate),
      ...getColumnSearchProps("Fecha PQR", "pqrDate"),
    },
    {
      title: "Datos Sucursal",
      dataIndex: "branchData",
      key: "branchData",
      width: 150,
      sorter: (a, b) => safeCompare(a.branchData, b.branchData),
      ...getColumnSearchProps("Datos Sucursal", "branchData"),
      render: (text, record) => {
        if (!text) return '';
        const isExpanded = expandedBranchRows.has(record.key);
        const truncatedText = text.slice(0, 30);
        
        if (text.length <= 30) return text;

        return (
          <span>
            {isExpanded ? text : `${truncatedText}... `}
            <Button 
              type="link" 
              onClick={(e) => {
                e.stopPropagation();
                toggleBranchExpand(record);
              }}
            >
              {isExpanded ? 'Ver menos' : 'Ver más'}
            </Button>
          </span>
        );
      },
    },
    {
      title: "Motivo devolución",
      dataIndex: "returnReason",
      key: "returnReason",
      width: 200,
      sorter: (a, b) => safeCompare(a.returnReason, b.returnReason),
      ...getColumnSearchProps("Motivo devolución", "returnReason"),
    },
    {
      title: "Dimensiones",
      dataIndex: "dimensions",
      key: "dimensions",
      width: 120,
      sorter: (a, b) => safeCompare(a.dimensions, b.dimensions),
      ...getColumnSearchProps("Dimensiones", "dimensions"),
    },
    {
      title: "Datos Banco",
      dataIndex: "bankData",
      key: "bankData",
      width: 150,
      sorter: (a, b) => safeCompare(a.bankData, b.bankData),
      ...getColumnSearchProps("Datos Banco", "bankData"),
    },
    {
      title: "Información adicional",
      dataIndex: "additionalInfo",
      key: "additionalInfo",
      width: 200,
      sorter: (a, b) => safeCompare(a.additionalInfo, b.additionalInfo),
      ...getColumnSearchProps("Información adicional", "additionalInfo"),
      render: (text, record) => {
        if (!text) return '';
        const isExpanded = expandedRows.has(record.key);
        const truncatedText = text.slice(0, 50);
        
        if (text.length <= 50) return text;

        return (
          <span>
            {isExpanded ? text : `${truncatedText}... `}
            <Button 
              type="link" 
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand(record);
              }}
            >
              {isExpanded ? 'Ver menos' : 'Ver más'}
            </Button>
          </span>
        );
      },
    },
    {
      title: "Respuesta 99",
      dataIndex: "response99",
      key: "response99",
      width: 200,
      sorter: (a, b) => safeCompare(a.response99, b.response99),
      ...getColumnSearchProps("Respuesta 99", "response99"),
      render: (text, record) => {
        if (!text) return '';
        const isExpanded = expandedResponse99Rows.has(record.key);
        const truncatedText = text.slice(0, 40);
        
        if (text.length <= 40) return text;

        return (
          <span>
            {isExpanded ? text : `${truncatedText}... `}
            <Button 
              type="link" 
              onClick={(e) => {
                e.stopPropagation();
                toggleResponse99Expand(record);
              }}
            >
              {isExpanded ? 'Ver menos' : 'Ver más'}
            </Button>
          </span>
        );
      },
    },
    {
      title: "Respuesta En proceso",
      dataIndex: "inProcessResponse",
      key: "inProcessResponse",
      width: 250,
      sorter: (a, b) => safeCompare(a.inProcessResponse, b.inProcessResponse),
      ...getColumnSearchProps("Respuesta En proceso", "inProcessResponse"),
    },
    {
      title: "Respuesta PQR",
      dataIndex: "pqrResponse",
      key: "pqrResponse",
      width: 200,
      sorter: (a, b) => safeCompare(a.pqrResponse, b.pqrResponse),
      ...getColumnSearchProps("Respuesta PQR", "pqrResponse"),
      render: (text, record) => {
        if (!text) return '';
        const isExpanded = expandedPqrResponseRows.has(record.key);
        const truncatedText = text.slice(0, 40);
        
        if (text.length <= 40) return text;

        return (
          <span>
            {isExpanded ? text : `${truncatedText}... `}
            <Button 
              type="link" 
              onClick={(e) => {
                e.stopPropagation();
                togglePqrResponseExpand(record);
              }}
            >
              {isExpanded ? 'Ver menos' : 'Ver más'}
            </Button>
          </span>
        );
      },
    },
    {
      title: "Adjunto 99",
      dataIndex: "file99",
      key: "file99",
      width: 130,
      sorter: (a, b) => a.file?.localeCompare(b.file),
      ...getColumnSearchProps("Adjunto", "file"),
      render: (text) => text ? (
        <a href={`${text}`} target="_blank" rel="noopener noreferrer">
          Ver adjunto 99
        </a>
      ) : "",
    },
    {
      title: "Adjunto",
      dataIndex: "file",
      key: "file",
      width: 130,
      sorter: (a, b) => a.file?.localeCompare(b.file),
      ...getColumnSearchProps("Adjunto", "file"),
      render: (text) => text ? (
        <a href={`${text}`} target="_blank" rel="noopener noreferrer">
          Ver adjunto
        </a>
      ) : "",
    },
    {
      title: "Adjunto embalaje",
      dataIndex: "packingAttachment",
      key: "packingAttachment",
      width: 130,
      sorter: (a, b) => a.packingAttachment?.localeCompare(b.packingAttachment),
      ...getColumnSearchProps("Adjunto embalaje", "packingAttachment"),
      render: (text) => text ? (
        <a href={`${text}`} target="_blank" rel="noopener noreferrer">
          Ver adjunto
        </a>
      ) : "",
    },
    {
      title: "Adjunto contenido",
      dataIndex: "contentAttachment",
      key: "contentAttachment",
      width: 130,
      sorter: (a, b) => a.contentAttachment?.localeCompare(b.contentAttachment),
      ...getColumnSearchProps("Adjunto contenido", "contentAttachment"),
      render: (text) => text ? (
        <a href={`${text}`} target="_blank" rel="noopener noreferrer">
          Ver adjunto
        </a>
      ) : "",
    },
    {
      title: "Adjunto embalaje fin",
      dataIndex: "packingAttachmentEnd",
      key: "packingAttachmentEnd",
      width: 130,
      sorter: (a, b) => a.packingAttachmentEnd?.localeCompare(b.packingAttachmentEnd),
      ...getColumnSearchProps("Adjunto embalaje fin", "packingAttachmentEnd"),
      render: (text) => text ? (
        <a href={`${text}`} target="_blank" rel="noopener noreferrer">
          Ver adjunto
        </a>
      ) : "",
    },
    {
      title: "Finalizar",
      dataIndex: "finish",
      key: "finish",
      width: 100,
      render: (text, record) => (
        <Button 
          type="primary"
          onClick={() => handleFinish(record.ticketNumber)} // Usando ticketNumber directamente
          style={{ 
            backgroundColor: '#1890ff',
            borderColor: '#1890ff'
          }}
        >
          Finalizar
        </Button>
      ),
    }
  ];

  return (
    <>
      <NavbarAdminMex title={"PQR"} />
      <div className="pqr-container">
        <Typography.Title level={2} className="pqr-title">
          Respuesta de PQR
        </Typography.Title>
        <Button 
          onClick={exportToExcel} 
          className="pqr-button pqr-export-button"
        >
          Exportar a Excel
        </Button>
        <Button 
          className="pqr-button pqr-manage-button"
          onClick={showModal}
        >
          Gestionar PQR
        </Button>
        <Typography.Title level={3} className="pqr-subtitle">
          Tabla PQR
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: 150, 
            position: ["bottomCenter"],
          }}
          locale={{
            sortTitle: "Ordenar",
            triggerAsc: "Orden ascendente",
            triggerDesc: "Orden descendente",
            cancelSort: "Cancelar orden",
          }}
          scroll={{ x: 'max-content' }}
          className="pqr-table"
        />
        <Modal
          title={<strong>Formulario de Respuesta</strong>}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={700}
          className="pqr-modal"
        >
          <FormPQR />
        </Modal>
      </div>
    </>
  );
};

export default Pqr;
