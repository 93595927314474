import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import { Button, message } from 'antd'; // Añadir estas importaciones
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import './LandingDrop.css';
import SendClientPage from "../SendClientPage/SendClientPage";
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import FilterListIcon from '@mui/icons-material/FilterList';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import { ArrowRightOutlined, ArrowLeftOutlined, ShopOutlined } from '@ant-design/icons';

const LandingDrop = () => {
  const [mostrarEnvios, setMostrarEnvios] = useState(false);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [name, setName] = useState(null);
  const [stock, setStock] = useState(null);
  const [prodID, setProdID] = useState(null);
  const [products, setProducts] = useState([]); // Todos los productos
  const [filteredProducts, setFilteredProducts] = useState([]); // Productos filtrados
  const [categories, setCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 20;
  const [loadingMessage, setLoadingMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [cart, setCart] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [mostrarEnviosCarrito, setMostrarEnviosCarrito] = useState(false);

  const loadingMessages = [
    "¡Ya casi! Solo nos falta un 1% para empezar a pedir",
    "Cargando los mejores productos para ti",
    "Preparando una experiencia increíble",
    "Organizando los productos más vendidos",
    "¡Los precios más competitivos están en camino!",
    "Casi todo listo para empezar",
  ];

  const rotateLoadingMessage = useCallback(() => {
    const interval = setInterval(() => {
      setLoadingMessage(prevMessage => {
        const currentIndex = loadingMessages.indexOf(prevMessage);
        const nextIndex = (currentIndex + 1) % loadingMessages.length;
        return loadingMessages[nextIndex];
      });
      setProgress(prev => (prev + 10) % 100);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setLoadingMessage(loadingMessages[0]);
      const cleanup = rotateLoadingMessage();
      return () => cleanup();
    }
  }, [isLoading, rotateLoadingMessage]);

  // Almacena todos los productos en la primera carga
  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('https://api.99envios.app/api/inventarios');
      const productPromises = data.map(async (product) => {
        const detailResponse = await axios.get(`https://api.99envios.app/api/inventarios_producto/${product.id_producto}`);
        const detailData = detailResponse.data;
        return {
          id: product.id_producto,
          name: product.nombre_producto,
          category: product.categoria?.nombre_categoria || 'Sin categoría',
          stock: product.cantidad_disponible,
          provider: detailData.sucursal.nombre_sucursal || 'Cargando...',
          supplierPrice: parseFloat(product.precio_proveedor),
          suggestedPrice: parseFloat(product.precio_sugerido),
          images: product.imagenes.map(img => img.url_imagen) || ['/logo.png'],
          link: `/product/${product.id_producto}`
        };
      });
      const mappedProducts = await Promise.all(productPromises);
      
      // Shuffle the products array
      const shuffledProducts = mappedProducts.sort(() => Math.random() - 0.5);
      
      setProducts(shuffledProducts); // Guardar todos los productos en el estado
      setFilteredProducts(shuffledProducts); // Inicialmente, todos los productos están filtrados
      const uniqueCategories = [...new Set(shuffledProducts.map(product => product.category))];
      const uniqueProviders = [...new Set(shuffledProducts.map(product => product.provider))];
      setCategories(uniqueCategories);
      setProviders(uniqueProviders);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProducts(); // Obtener los productos en el montaje inicial
  }, [fetchProducts]);

  // Función de filtrado
  useEffect(() => {
    const filterProducts = () => {
      const filtered = products.filter(product => {
        const categoryMatch = selectedCategory ? product.category === selectedCategory : true;
        const providerMatch = selectedProvider ? product.provider === selectedProvider : true;
        const searchTermMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
        const priceMatch = 
          (!minPrice || product.suggestedPrice >= parseFloat(minPrice)) &&
          (!maxPrice || product.suggestedPrice <= parseFloat(maxPrice));
        return categoryMatch && providerMatch && searchTermMatch && priceMatch;
      });
      setFilteredProducts(filtered);
      setCurrentPage(1); // Resetear a la primera página cuando se filtran los productos
    };

    filterProducts();
  }, [selectedCategory, selectedProvider, searchTerm, minPrice, maxPrice, products]);

  // Calcular productos para la página actual
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Función para cambiar de página
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Componente de paginación
  const Pagination = () => (
    <div className="pagination">
      <button 
        onClick={() => paginate(currentPage - 1)} 
        disabled={currentPage === 1}
        className="pagination-btn"
      >
        ← Anterior
      </button>
      <span className="page-info">
        Página {currentPage} de {totalPages}
      </span>
      <button 
        onClick={() => paginate(currentPage + 1)} 
        disabled={currentPage === totalPages}
        className="pagination-btn"
      >
        Siguiente →
      </button>
    </div>
  );

  // Manejo del botón para abrir la modal
  const handleButtonClick = (suggestedPrice, stock, name, prodID) => {
    setMostrarEnvios(true);
    setSuggestedPrice(suggestedPrice);
    setStock(stock);
    setName(name);
    setProdID(prodID);
  };

  const handleCloseModal = () => {
    setMostrarEnvios(false);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: 'ondemand',
  };

  const addToCart = (product) => {
    setCart(prevCart => {
      const existingProduct = prevCart.find(item => item.id === product.id);
      if (existingProduct) {
        return prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      return [...prevCart, { ...product, quantity: 1 }];
    });
    setShowCart(true);
  };

  const removeFromCart = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== productId));
  };

  const updateQuantity = (productId, newQuantity) => {
    if (newQuantity < 1) {
      removeFromCart(productId);
      return;
    }
    setCart(prevCart =>
      prevCart.map(item =>
        item.id === productId
          ? { ...item, quantity: newQuantity }
          : item
      )
    );
  };

  // const updateWeight = (productId, weight) => {
  //   setWeights(prev => ({
  //     ...prev,
  //     [productId]: parseFloat(weight) || 0
  //   }));
  // };

  const ProductCard = React.memo(({ product }) => (
    <div className="product-cardL">
      <div className="product-carousel">
        {product.images.length > 1 ? (
          <Slider {...settings}>
            {product.images.map((image, index) => (
              <div key={index}>
                <Link to={product.link}>
                  <img src={image} alt={product.name} loading="lazy" />
                </Link>
              </div>
            ))}
          </Slider>
        ) : (
          <Link to={product.link}>
            <img src={product.images[0] || "/logo.png"} alt={product.name} loading="lazy" />
          </Link>
        )}
      </div>
      <div className="product-details">
        {/* Aquí se muestra la categoría y el id del producto */}
        <p className="category">{product.category} - id: {product.id}</p>
        <p className="stock">Stock <span>{product.stock}</span></p>
        <h3>{product.name}</h3>
        <p className="provider">Proveedor: <span>{product.provider}</span></p>
        <p className="supplier-price">Precio proveedor: ${product.supplierPrice.toLocaleString()}</p>
        <p className="suggested-price">Precio sugerido: <strong>${product.suggestedPrice.toLocaleString()}</strong></p>
      </div>
      <div className="product-card-buttons">
        <button className="add-to-cart-btn" onClick={() => addToCart(product)}>
          Agregar al carrito
        </button>
        <button className="send-client-btn" onClick={() => handleButtonClick(product.suggestedPrice, product.stock, product.name, product.id)}>
          Enviar al cliente
        </button>
      </div>
    </div>
  ));

  const FiltersModal = () => (
    <div className="modal-overlay" onClick={() => setShowFiltersModal(false)}>
      <div className="filters-modal" onClick={e => e.stopPropagation()}>
        <div className="filters-modal-header">
          <h3>Filtros</h3>
          <button onClick={() => setShowFiltersModal(false)} className="close-modal-btn">
            <CloseIcon />
          </button>
        </div>
        <div className="filters-modal-content">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="🔍 Buscar productos..."
            className="search-input"
          />
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">📁 Todas las categorías</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
          <select value={selectedProvider} onChange={(e) => setSelectedProvider(e.target.value)}>
            <option value="">🏢 Todos los proveedores</option>
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </select>
          <input
            type="number"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            placeholder="$ Precio mínimo"
            className="price-input"
          />
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            placeholder="$ Precio máximo"
            className="price-input"
          />
        </div>
      </div>
    </div>
  );

  const Cart = () => (
    <div className={`floating-cart ${showCart ? 'show' : ''}`}>
      <div className="cart-header">
        <h3>Carrito <span>({cart.length})</span></h3>
        <button onClick={() => setShowCart(false)} className="close-cart-btn">
          <CloseIcon />
        </button>
      </div>
      <div className="cart-items">
        {cart.map(item => (
          <div key={item.id} className="cart-item">
            <img src={item.images[0]} alt={item.name} />
            <div className="cart-item-details">
              <h4>{item.name}</h4>
              <p>${item.suggestedPrice.toLocaleString()}</p>
              <div className="quantity-controls">
                <button onClick={() => updateQuantity(item.id, item.quantity - 1)}>
                  {item.quantity === 1 ? <DeleteIcon fontSize="small" /> : '-'}
                </button>
                <span>{item.quantity}</span>
                <button onClick={() => updateQuantity(item.id, item.quantity + 1)}>+</button>
              </div>
              {/* <div className="weight-input-container">
                <input
                  type="number"
                  value={weights[item.id] || ''}
                  onChange={(e) => updateWeight(item.id, e.target.value)}
                  placeholder="Peso (kg)"
                  className="weight-input"
                  step="0.1"
                  min="0"
                />
                <span>kg</span>
              </div> */}
            </div>
            <button onClick={() => removeFromCart(item.id)} className="remove-item">
              <CloseIcon />
            </button>
          </div>
        ))}
      </div>
      {cart.length > 0 ? (
        <div className="cart-footer">
          <div className="cart-total">
            <span>Total:</span>
            <span>${cart.reduce((total, item) => total + (item.suggestedPrice * item.quantity), 0).toLocaleString()}</span>
          </div>
          <button 
            className="checkout-btn" 
            onClick={() => {
              setMostrarEnviosCarrito(true);
              setShowCart(false);
            }}
          >
            Proceder al envío
          </button>
        </div>
      ) : (
        <p className="empty-cart">El carrito está vacío</p>
      )}
    </div>
  );

  const groupCartBySucursal = (cartItems) => {
    const groups = cartItems.reduce((acc, item) => {
      const sucursalId = item.provider; // Usamos el provider como identificador de sucursal
      if (!acc[sucursalId]) {
        acc[sucursalId] = {
          items: [],
          sucursal_nombre: item.provider
        };
      }
      acc[sucursalId].items.push(item);
      return acc;
    }, {});

    return Object.entries(groups).map(([sucursalId, group]) => ({
      sucursal_id: sucursalId,
      sucursal_nombre: group.sucursal_nombre,
      items: group.items
    }));
  };

  const CartCheckoutModals = () => {
    const [currentProviderIndex, setCurrentProviderIndex] = useState(0);
    const groupedCart = groupCartBySucursal(cart);
    const totalProviders = groupedCart.length;
  
    useEffect(() => {
      if (totalProviders > 1) {
        message.info(`Tienes productos de ${totalProviders} proveedores diferentes. Se procesarán por separado.`);
      }
    }, [totalProviders]);
  
    if (groupedCart.length === 0) return null;
  
    const currentProvider = groupedCart[currentProviderIndex];
  
    const handleNext = () => {
      if (currentProviderIndex < totalProviders - 1) {
        setCurrentProviderIndex(prev => prev + 1);
      }
    };
  
    const handlePrevious = () => {
      if (currentProviderIndex > 0) {
        setCurrentProviderIndex(prev => prev - 1);
      }
    };
  
    return (
      <div className="modal-overlay" onClick={() => setMostrarEnviosCarrito(false)}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {totalProviders > 1 && (
            <div className="provider-navigation">
              <div className="provider-nav-header">
                <h4>Pedido {currentProviderIndex + 1} de {totalProviders}</h4>
                <div className="provider-progress">
                  {groupedCart.map((_, index) => (
                    <span 
                      key={index} 
                      className={`progress-dot ${index === currentProviderIndex ? 'active' : ''}`}
                    />
                  ))}
                </div>
              </div>
              <div className="provider-info">
                <h3><ShopOutlined /> {currentProvider.sucursal_nombre}</h3>
              </div>
              <div className="provider-nav-buttons">
                <Button 
                  onClick={handlePrevious} 
                  disabled={currentProviderIndex === 0}
                  icon={<ArrowLeftOutlined />}
                >
                  Proveedor anterior
                </Button>
                <Button 
                  onClick={handleNext} 
                  disabled={currentProviderIndex === totalProviders - 1}
                  icon={<ArrowRightOutlined />}
                >
                  Siguiente proveedor
                </Button>
              </div>
            </div>
          )}
          
          <SendClientPage 
            cart={currentProvider.items}
            isCartCheckout={true}
            sucursalId={currentProvider.sucursal_id}
          />
          <button 
            className="close-modal-btn" 
            onClick={() => setMostrarEnviosCarrito(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    );
  };
  

  return (
    <>
      <NavbarDropshipping title="Dropshipping General" />
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-content">
            <CircularProgress 
              size={60}
              thickness={4}
              sx={{ color: '#4035df' }}
            />
            <p className="loading-message">{loadingMessage}</p>
          </div>
        </div>
      )}
      <div className="landing-drop-container">
        <div className="header-stats">
          <div className="stat-card">
            <h3>{filteredProducts.length}</h3>
            <p>Productos</p>
          </div>
          <div className="stat-card">
            <h3>{categories.length}</h3>
            <p>Categorías</p>
          </div>
          <div class="stat-card">
            <h3>{providers.length}</h3>
            <p>Proveedores</p>
          </div>
        </div>

        {/* Desktop filters */}
        <div className="filters desktop-only">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="🔍 Buscar productos..."
            className="search-input"
          />
          <select value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)}>
            <option value="">📁 Todas las categorías</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
          <select value={selectedProvider} onChange={(e) => setSelectedProvider(e.target.value)}>
            <option value="">🏢 Todos los proveedores</option>
            {providers.map((provider, index) => (
              <option key={index} value={provider}>{provider}</option>
            ))}
          </select>
          <input
            type="number"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
            placeholder="$ Precio mínimo"
            className="price-input"
          />
          <input
            type="number"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
            placeholder="$ Precio máximo"
            className="price-input"
          />
        </div>

        {/* Mobile filter button */}
        <button 
          className="filter-button-mobile"
          onClick={() => setShowFiltersModal(true)}
        >
          <FilterListIcon /> 
        </button>

        <div className="product-grid">
          {currentProducts.map((product, index) => (
            <ProductCard
              key={product.id}
              product={product}
            />
          ))}
        </div>
        {!isLoading && filteredProducts.length > productsPerPage && <Pagination />}
        {isLoading && <p>Cargando productos...</p>}
      </div>
      {mostrarEnvios && (
        <div className="modal-wrapper">
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <SendClientPage 
              suggestedPrice={suggestedPrice} 
              stock={stock} 
              name={name} 
              prodID={prodID} 
              isCartCheckout={false} 
              estadoBodega={false} 
              inventarioBodega={[]} 
            />
            <button className="close-modal-btn" onClick={handleCloseModal}>
              <CloseIcon />
            </button>
          </div>
        </div>
      )}
      {showFiltersModal && <FiltersModal />}
      <button 
        className="cart-toggle-btn"
        onClick={() => setShowCart(!showCart)}
      >
        <ShoppingCartIcon />
        <span className="cart-count">{cart.length}</span>
      </button>
      <Cart />
      
      {/* Reemplazar el modal existente del carrito con el nuevo componente */}
      {mostrarEnviosCarrito && <CartCheckoutModals />}
    </>
  );
};

export default LandingDrop;
