import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { message, Badge } from 'antd';
import './navbarUsuarioBodega.css';

const NavbarUsuarioBodega = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [inventarioEntranteCount, setInventarioEntranteCount] = useState(0);
  const [inventarioBodegaCount, setInventarioBodegaCount] = useState(0);
  const [unavailableOrdersCount, setUnavailableOrdersCount] = useState(0);

  const menuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const rol_id = localStorage.getItem('rol_id');

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }

    // Función para manejar el cambio de tamaño de la ventana
    const handleResize = () => {
      const mobileView = window.innerWidth < 768;
      setIsMobile(mobileView);

      // Si el ancho supera los 768px (vista normal), abrimos el menú automáticamente
      if (!mobileView) {
        setMenuOpen(true);
      }
    };

    // Detectar clic fuera de la barra de navegación solo en pantallas móviles
    const handleClickOutside = (event) => {
      if (isMobile && menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    // Añadir el evento de clic y redimensionamiento de la ventana
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    // Limpiar eventos al desmontar el componente
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  const fetchCounts = async () => {
    try {
      const userId = localStorage.getItem('usuario_id');
      
      // Fetch para inventario entrante
      const responseEntrante = await fetch(`https://99envios.app/api/picking/bodega/listar-inventario-bodega/${userId}`);
      const dataEntrante = await responseEntrante.json();
      setInventarioEntranteCount(dataEntrante.filter(item => !item.codigo_barras).length);

      // Fetch para inventario bodega
      const responseBodega = await fetch(`https://99envios.app/api/picking/bodega/listar-inventario-bodega-externo/${userId}`);
      const dataBodega = await responseBodega.json();
      setInventarioBodegaCount(dataBodega.filter(item => !item.codigo_barras).length);

      // Fetch para pedidos no disponibles
      const responseUnavailable = await fetch(`https://99envios.app/api/picking/bodega/listar-ordenes-externas/${userId}`);
      const dataUnavailable = await responseUnavailable.json();
      const unavailableCount = dataUnavailable.filter(item => item.estado_enlistment === 0 && item.no_disponible === 1).length;
      setUnavailableOrdersCount(unavailableCount);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  useEffect(() => {
    const fetchSucursal = async () => {
      const userId = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Asumiendo que necesitas el token para la autorización

      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener la sucursal');
        }
        const data = await response.json();
        setNombreSucursal(data.nombre_sucursal);
      } catch (error) {
        console.error('Error al obtener la sucursal:', error);
      }
    };

    fetchSucursal();
    fetchCounts(); // Fetch inicial

    // Solo suscribirse a eventos de actualización
    window.addEventListener('inventoryUpdated', fetchCounts);
    window.addEventListener('ordersUpdated', fetchCounts);

    return () => {
      window.removeEventListener('inventoryUpdated', fetchCounts);
      window.removeEventListener('ordersUpdated', fetchCounts);
    };
  }, []);

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-tittle">
          <p>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, {nombreSucursal}</p>
          <NavLink to="/panel-usuario" className="panel-link">
            <p className="panel-link">99Panel</p>
          </NavLink>
          <div className="user-avatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      {rol_id === '9' && (
        <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`} ref={menuRef}>
          <div className="close-btn" onClick={toggleMenu}>X</div>
          <div className="navbar-title">
            <img src="/Images/download.png" alt="Download" className="logo" />
          </div>
          <div className="navbar-menu">
            <ul>
              <li>
                <NavLink to="/picking/envios-entrantes" activeClassName="active">
                  <span className="material-icons">local_shipping</span>Envíos Entrantes
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/inventario-entrante" activeClassName="active">
                  <Badge count={inventarioEntranteCount} offset={[10, 0]}>
                    <span className="material-icons">inventory_2</span>Inventario Entrante
                  </Badge>
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/inventario-bodega" activeClassName="active">
                  <Badge count={inventarioBodegaCount} offset={[10, 0]}>
                    <span className="material-icons">warehouse</span>Inventario Bodega
                  </Badge>
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/personal-picking" activeClassName="active">
                  <span className="material-icons">group</span>Personal Picking
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/pedidos-picking" activeClassName="active">
                  <span className="material-icons">shopping_cart</span>Pedidos Picking 99
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/pedidos-externos" activeClassName="active">
                  <Badge count={unavailableOrdersCount} offset={[10, 0]}>
                    <span className="material-icons">assignment_returned</span>Pedidos Externos
                  </Badge>
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/estado-pedidos" activeClassName="active">
                  <span className="material-icons">assignment_turned_in</span>Estado Pedidos
                </NavLink>
              </li>
              <li>
                <NavLink to="/picking/lista-devoluciones" activeClassName="active">
                  <span className="material-icons">assignment_return</span>Lista Devoluciones
                </NavLink>
              </li>
            </ul>
            <a onClick={handleLogout} className="sidebar-item-nav">
              <span className="material-icons">exit_to_app</span>Logout
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarUsuarioBodega;