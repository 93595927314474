import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, Select, Checkbox, Row, Col, message, Modal, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import Navbar from '../../components/navbarOut';
import Footer from '../../components/footer';
import Ciudades from '../../ciudades/ciudades'; // Importar el archivo de ciudades

const { Option } = Select;

/**
 * Componente para el registro de repartidores/conductores.
 * Maneja el formulario de registro completo con validaciones y subida de archivos.
 * @component
 */
const FormularioRegistroConductor = () => {
    const [form] = Form.useForm();
    const [image, setImage] = useState(null);
    const [documentImage, setDocumentImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [documentImagePreviewUrl, setDocumentImagePreviewUrl] = useState('');
    const navigate = useNavigate();

    /**
     * Maneja los cambios en los archivos subidos (fotos y documentos).
     * @param {Object} info - Información del archivo subido
     * @param {string} type - Tipo de archivo ('profile' o 'document')
     */
    const handleFileChange = (info, type) => {
        const lastFile = info.fileList.slice(-1)[0];
        if (lastFile) {
            if (type === 'profile') {
                setImage(lastFile.originFileObj);
                setImagePreviewUrl(URL.createObjectURL(lastFile.originFileObj));
            } else if (type === 'document') {
                setDocumentImage(lastFile.originFileObj);
                setDocumentImagePreviewUrl(URL.createObjectURL(lastFile.originFileObj));
            }
        } else {
            if (type === 'profile') {
                setImage(null);
                setImagePreviewUrl('');
            } else if (type === 'document') {
                setDocumentImage(null);
                setDocumentImagePreviewUrl('');
            }
        }
    };

    /**
     * Maneja el envío del formulario y registro del repartidor.
     * Valida campos, prepara FormData y realiza la petición al servidor.
     */
    const handleSubmit = async () => {
        form.validateFields().then(async (values) => {
            if (values.pais === 'colombia') {
                values.pais = 1;
            } else if (values.pais === 'mexico') {
                values.pais = 2;
            }
            console.log('Valores del formulario:', values);
            let formData = new FormData();
            
            // Format dates before sending
            if (values.fecha_vencimiento_soat) {
                formData.append('fecha_vencimiento_soat', values.fecha_vencimiento_soat.format('YYYY-MM-DD'));
            }
            if (values.fecha_vencimiento_tecnomecanica) {
                formData.append('fecha_vencimiento_tecnomecanica', values.fecha_vencimiento_tecnomecanica.format('YYYY-MM-DD'));
            }

            // Add file validation
            if (!documentImage) {
                message.error('Por favor sube una imagen del documento');
                return;
            }
            
            formData.append('foto_documento', documentImage);
            formData.append('nombre_usuario', values.nombre);
            formData.append('telefono', values.celular);
            formData.append('direccion', values.direccion);
            formData.append('documento_identidad', values.documento_identidad);
            formData.append('email', values.email);
            formData.append('pais', values.pais);
            if (image instanceof File) {
                formData.append('foto_cara', image);
            }
            formData.append('capacidad_carga', values.cantidad);
            formData.append('tipo_vehiculo', values.tipo_vehiculo);
            formData.append('placa_vehiculo', values.placa_vehiculo);
            formData.append('ciudad', values.ciudad);
            formData.append('password', values.password);
            formData.append('password_confirmation', values.password_confirmation);
            formData.append('id_rol', 4);
            formData.append('estado', 0);
            formData.append('tipo_identificacion', values.tipo_identificacion);
            formData.append('num_identificacion', values.num_identificacion);
            formData.append('numero_soat', values.numero_soat);
            formData.append('numero_tecnomecanica', values.numero_tecnomecanica);
            formData.append('latitud', parseFloat(values.latitud).toFixed(1));
            formData.append('longitud', parseFloat(values.longitud).toFixed(1));

            try {
                const response = await axios.post('https://api.99envios.app/api/auth/register_repartidor', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                console.log('Respuesta del registro:', response);
                if (response.status === 200 || response.status === 201) {
                    Modal.success({
                        title: 'Registro Exitoso',
                        content: 'Tu cuenta ha sido creada exitosamente y está lista para usar.',
                        onOk() {
                            navigate('/');
                        }
                    });
                } else {
                    console.log('Registro no exitoso, estado:', response.status);
                    throw new Error('Registro no fue exitoso.');
                }
            } catch (error) {
                console.error('Error al registrar:', error);
                if (error.response && error.response.data && error.response.data.errors) {
                    const errors = error.response.data.errors;
                    if (errors.numero_tecnomecanica) {
                        message.error(errors.numero_tecnomecanica[0]);
                    }
                    if (errors.numero_soat) {
                        message.error(errors.numero_soat[0]);
                    }
                } else {
                    message.error('Error al registrar. Por favor, verifica tus datos y tu conexión.');
                }
            }
        }).catch(errorInfo => {
            console.error('Error en la validación del formulario:', errorInfo);
            message.error('Error en la validación del formulario. Revisa los errores.');
        }
        );

    };

    /**
     * Array de ciudades disponibles para el registro
     * @type {string[]}
     */
    const ciudades = [
        'Bogotá',
        'Medellín',
        'Cali',
        'Barranquilla',
        'Tunja',
        'Bucaramanga',
        'Manizales',
        'Pereira',
        'Popayan',
        'Armenia',
        'Cartagena',
        'Ibague',
        'Neiva',
        'Pasto',
        'Santa Marta',
        'Villavicencio',
        'Yopal',
        'Cucuta',
        'Monteria',
        'Valledupar',
    ];

    /**
     * Lista de productos/categorías disponibles
     * @type {Array<{name: string}>}
     */
    const products = [

        { name: '1. Bisuteria' },
        { name: '2. Ropa Deportiva' },
        { name: '3. Vaporizadores' },
        { name: '4. Mascotas' },
        { name: '5. Moda' },
        { name: '6. Tecnologia' },
        { name: '7. Cocina' },
        { name: '8. Belleza' },
        { name: '9. Salud' },
        { name: '10. Hogar' },
        { name: '11. Natural Home' },
        { name: '12. Deportes' },
        { name: '13. Sex Shop' },
        { name: '14. Bebé' },
        { name: '15. Aseo' },
        { name: '16. Bienestar' },
        { name: '17. Camping' },
        { name: '18. Pesca' },
        { name: '19. Defensa Personal' },
        { name: '20. Vehiculos' },
        { name: '21. Jugueteria' },
        { name: '22. Otros' },



    ];

    /**
     * Renderiza el formulario de registro con todos sus campos y validaciones.
     * @returns {JSX.Element} Formulario de registro de conductor.
     */
    return (
        <div className='contenedorConductor' style={{ height: '100vh', display: 'flex', flexDirection: 'column', background: 'rgb(241, 239, 239)' }}>
            <Navbar />
            <div style={{ flex: 1, backgroundColor: 'rgb(241, 239, 239)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '2%' }}>
                <h2 style={{ color: 'black', textAlign: 'center', marginBottom: '24px', fontWeight: 'bold' }}>Añadir nuevo conductor</h2>
                <Form form={form} name="registro" layout="vertical" onFinish={handleSubmit}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Por favor ingresa tu nombre!' }]}>
                                <Input placeholder="Ingresa tu nombre" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="celular" label="Celular" rules={[{ required: true, message: 'Por favor ingresa tu celular!' }, { pattern: /^[0-9]+$/, message: 'Por favor ingresa un número válido' }]}>
                                <Input placeholder="Ingresa el celular" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="direccion" label="Dirección" rules={[{ required: true, message: 'Por favor ingresa tu dirección!' }]}>
                                <Input placeholder="Ingresa tu dirección" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="documento_identidad" label="Documento de identidad" rules={[{ required: true, message: 'Por favor ingresa tu documento de identidad!' }]}>
                                <Input placeholder="Ingresa tu documento de identidad" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="email" label="Correo electrónico" rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico!' }, { type: 'email', message: 'Por favor ingresa un correo válido' }]}>
                                <Input placeholder="Ingresa tu correo electrónico" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item 
                                name="pais" 
                                label="País" 
                                rules={[{ required: true, message: 'Por favor selecciona tu país!' }]}
                            >
                                <Select placeholder="Selecciona tu país">
                                    <Option value={1}>Colombia</Option>
                                    <Option value={2}>México</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="ciudad" label="Ciudad" rules={[{ required: true, message: 'Por favor ingresa tu ciudad!' }]}>
                                <Select placeholder="Selecciona tu ciudad">
                                    {Ciudades.map(ciudad => (
                                        <Option key={ciudad.value} value={ciudad.value}>{ciudad.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="cantidad" label="Capacidad de carga" rules={[{ required: true, message: 'Por favor ingresa la capacidad de carga!' }]}>
                                <Input placeholder="Ingresa la capacidad de carga" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="tipo_vehiculo" label="Tipo de vehículo" rules={[{ required: true, message: 'Por favor ingresa el tipo de vehículo!' }]}>
                                <Select placeholder="Selecciona el tipo de vehículo">
                                    <Option value="moto">Moto</Option>
                                    <Option value="carro">Carro</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="placa_vehiculo" label="Placa del vehículo" rules={[{ required: true, message: 'Por favor ingresa la placa del vehículo!' }]}>
                                <Input placeholder="Ingresa la placa del vehículo" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="numero_soat" label="Número de Soat" rules={[{ required: true, message: 'Por favor ingresa el número de Soat!' }]}>
                                <Input placeholder="Ingresa el número de Soat" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<span class="titulo-input" style={{ color: 'black', fontWeight: 'bold' }}>Fecha Vencimiento Soat</span>}
                                name="fecha_vencimiento_soat"
                                rules={[{ required: true, message: 'Por favor ingresa la fecha de vencimiento del soat!' }]}
                            >
                                <DatePicker
                                    placeholder="Fecha"
                                    format="YYYY-MM-DD"
                                    style={{
                                        borderBottom: '1px solid black',
                                        borderRadius: '0',
                                        transition: 'border 0.3s',
                                    }}
                                    onFocus={(e) => e.target.style.border = 'none'}
                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="numero_tecnomecanica" label="Número de Tecnomecanica" rules={[{ required: true, message: 'Por favor ingresa el número de Tecnomecanica!' }]}>
                                <Input placeholder="Ingresa el número de Tecnomecanica" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<span class="titulo-input" style={{ color: 'black', fontWeight: 'bold' }}>Fecha Vencimiento Tecnomecanica</span>}
                                name="fecha_vencimiento_tecnomecanica"
                                rules={[{ required: true, message: 'Por favor ingresa la fecha de vencimiento de Tecnomecanica!' }]}
                            >
                                <DatePicker
                                    placeholder="Fecha"
                                    format="YYYY-MM-DD"
                                    style={{
                                        borderBottom: '1px solid black',
                                        borderRadius: '0',
                                        transition: 'border 0.3s',
                                    }}
                                    onFocus={(e) => e.target.style.border = 'none'}
                                    onBlur={(e) => e.target.style.borderRadius = '0'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}>
                                <Input.Password placeholder="Ingresa tu contraseña" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="password_confirmation" label="Confirmar contraseña" rules={[{ required: true, message: 'Por favor confirma tu contraseña!' }]}>
                                <Input.Password placeholder="Confirma tu contraseña" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de Identificación"
                                name="tipo_identificacion"
                                rules={[{ required: true, message: 'Por favor selecciona tu tipo de identificación!' }]}
                            >
                                <Select>
                                    <Option value="CC">Cédula de Ciudadanía</Option>
                                    <Option value="CE">Cédula de Extranjería</Option>
                                    <Option value="TI">Tarjeta de Identidad</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Número de Identificación"
                                name="num_identificacion"
                                rules={[{ required: true, message: 'Por favor ingresa tu número de identificación!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="latitud" label="Latitud" rules={[{ required: true, message: 'Por favor ingresa la latitud!' }, { pattern: /^-?\d+(\.\d+)?$/, message: 'Por favor ingresa un número válido' }]}>
                                <Input placeholder="Ingresa la latitud" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="longitud" label="Longitud" rules={[{ required: true, message: 'Por favor ingresa la longitud!' }, { pattern: /^-?\d+(\.\d+)?$/, message: 'Por favor ingresa un número válido' }]}>
                                <Input placeholder="Ingresa la longitud" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item 
                                name="foto_documento" 
                                label="Imagen del Documento"
                                rules={[{ required: true, message: 'Por favor sube una imagen del documento!' }]}
                            >
                                <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={(info) => handleFileChange(info, 'document')}
                                >
                                    {documentImagePreviewUrl ? <img src={documentImagePreviewUrl} alt="documento" style={{ width: '100%' }} /> : <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>}
                                </Upload>
                            </Form.Item>

                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name="foto_cara" label="Foto de perfil">
                                <Upload
                                    listType="picture-card"
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={(info) => handleFileChange(info, 'profile')}
                                >
                                    {imagePreviewUrl ? <img src={imagePreviewUrl} alt="avatar" style={{ width: '100%' }} /> : <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>}
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* Terminos y condiciones  */}
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="terminos"
                                valuePropName="checked"
                                rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Debe aceptar los términos y condiciones para continuar') }]}
                            >
                                <Checkbox>
                                    Acepto los <a href="https://99envios.com/politica-privacidad/" target='_blank'>términos y condiciones</a>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Registrar</Button>
                            </Form.Item>
                        </Col>
                    </Row>



                </Form>
            </div>
            <Footer />
        </div>
    );
};

export default FormularioRegistroConductor;