import React, { useState } from 'react';
import NavbarAdminMex from '../components/navbar';
import BotonWhatsapp from '../../newInterface/botonWhatsapp/botonWhatsapp';
import { Modal, message, Tabs } from 'antd';
import './eliminarGuia.css';

const { TabPane } = Tabs;

const DesactivarGuia = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('interrapidisimo');
  const [loading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [detailVisible, setDetailVisible] = useState(false);
  const [detailTrackingNumber, setDetailTrackingNumber] = useState('');
  const [verifyTrackingNumber, setVerifyTrackingNumber] = useState('');
  const [verifyCarrier, setVerifyCarrier] = useState('interrapidisimo');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState(null);
  const [rastreoNumber, setRastreoNumber] = useState('');
  const [rastreoCarrier, setRastreoCarrier] = useState('interrapidisimo');
  const [indemTrackingNumberPreenvio, setIndemTrackingNumberPreenvio] = useState('');
  const [indemCarrierPreenvio, setIndemCarrierPreenvio] = useState('interrapidisimo');
  const [indemTrackingNumberServicio, setIndemTrackingNumberServicio] = useState('');
  const [indemCarrierServicio, setIndemCarrierServicio] = useState('interrapidisimo');

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCarrierChange = (event) => {
    setCarrier(event.target.value);
  };

  const handleDetailInputChange = (event) => {
    setDetailTrackingNumber(event.target.value);
  };

  const handleVerifyInputChange = (event) => {
    setVerifyTrackingNumber(event.target.value);
  };

  const handleVerifyCarrierChange = (event) => {
    setVerifyCarrier(event.target.value);
  };

  const handleRastreoInputChange = (event) => {
    setRastreoNumber(event.target.value);
  };

  const handleRastreoCarrierChange = (event) => {
    setRastreoCarrier(event.target.value);
  };

  const handleDelete = async () => {
    if (!trackingNumber.trim()) {
      message.error('Por favor ingrese un número de guía');
      return;
    }

    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      }
    };

    Modal.confirm({
      title: 'Confirmación',
      content: '¿Estás seguro de que deseas desactivar esta guía?',
      okText: 'Sí, desactivar',
      cancelText: 'Cancelar',
      onOk: async () => {
        setLoading(true);
        try {
          const usuario_id = localStorage.getItem('usuario_id');
          const token = localStorage.getItem('token');

          const response = await fetch(`https://integration.99envios.app/api/desactivar-preenvio/${usuario_id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(requestBody)
          });

          const data = await response.json();

          // Mostrar siempre el mensaje devuelto por la API
          message.info(data.message || 'No se recibió ningún mensaje de la API');
        } catch (error) {
          // Mostrar error si ocurre algo inesperado
          message.error(`Error inesperado: ${error.message}`);
        } finally {
          setLoading(false);
        }
      }
    });
  };

  const handleFetchDetail = async () => {
    try {
      const response = await fetch(`https://99envios.app/api/online/detalle-guia/${detailTrackingNumber}`);
      if (!response.ok) throw new Error('Error al obtener detalle de la guía');
      const data = await response.json();
      setDetailData(data);
      setDetailVisible(true);
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleVerify = async () => {
    if (!verifyTrackingNumber.trim()) {
      message.error('Por favor ingrese un número de guía');
      return;
    }

    const requestBody = {
      guia: verifyTrackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: verifyCarrier
      }
    };

    setVerifyLoading(true);
    try {
      const usuario_id = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token');

      const response = await fetch(`https://integration.99envios.app/api/verificacion-preenvio/${usuario_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      });

      const data = await response.json();
      message.info(data.message || 'No se recibió ningún mensaje de la API');
    } catch (error) {
      message.error(`Error inesperado: ${error.message}`);
    } finally {
      setVerifyLoading(false);
    }
  };

  const handleTrackingSearch = async () => {
    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      },
      origenCreacion: 1,
    };

    try {
      const codigo_sucursal = localStorage.getItem('codigoSucursal');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/rastreo/${codigo_sucursal}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error('Error en la búsqueda de la guía');
      }

      const data = await response.json();
      setTrackingInfo(data);
      setError(null);
    } catch (error) {
      setError(error.message);
      setTrackingInfo(null);
    }
  };

  const handleRastreoSearch = async () => {
    const requestBody = {
      guia: rastreoNumber,
      transportadora: {
        pais: 'colombia',
        nombre: rastreoCarrier
      },
      origenCreacion: 1,
    };

    try {
      const codigo_sucursal = localStorage.getItem('codigoSucursal');
      const response = await fetch(`https://integration.99envios.app/api/sucursal/rastreo/${codigo_sucursal}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error('Error en la búsqueda de la guía');
      }

      const data = await response.json();
      setTrackingInfo(data);
      setError(null);
    } catch (error) {
      setError(error.message);
      setTrackingInfo(null);
    }
  };

  const handleIndemnizacionPreenvio = async () => {
    const requestBody = {
      guia: indemTrackingNumberPreenvio,
      transportadora: {
        pais: 'colombia',
        nombre: indemCarrierPreenvio
      }
    };
    try {
      const response = await fetch('https://integration.99envios.app/api/indemnizacion-preenvio/1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      message.info(data.message || 'No se recibió ningún mensaje de la API');
    } catch (error) {
      message.error(`Error inesperado: ${error.message}`);
    }
  };

  const handleIndemnizacionServicioPreenvio = async () => {
    const requestBody = {
      guia: indemTrackingNumberServicio,
      transportadora: {
        pais: 'colombia',
        nombre: indemCarrierServicio
      }
    };
    try {
      const response = await fetch('https://integration.99envios.app/api/indemnizacion-servicio-preenvio/1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
      const data = await response.json();
      message.info(data.message || 'No se recibió ningún mensaje de la API');
    } catch (error) {
      message.error(`Error inesperado: ${error.message}`);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Desactivar guía" />
      <div className="eliminar-guia-tracking-container-container-admin">
        <div className="eliminar-guia-tracking-container-admin">
          <h2>DESACTIVAR GUÍA</h2>
          <p>Coloca el número de guía que deseas desactivar</p>

          <div className="search-bar">
            <input
              type="text"
              value={trackingNumber}
              onChange={handleInputChange}
              placeholder="Ingrese guía"
              className="search-input"
            />
            <select value={carrier} onChange={handleCarrierChange} className="carrier-select">
              <option value="interrapidisimo">Interrapidisimo</option>
              <option value="servientrega">Servientrega</option>
              <option value="coordinadora">Coordinadora</option>
              <option value="tcc">TCC</option>
            </select>
            <button style={{ marginTop: "-20px", height: "45px" }} onClick={handleDelete} className="search-button">
              <span className="material-icons">delete</span>
            </button>
          </div>
          
        </div>
        <div className="eliminar-guia-tracking-container-admin">
          <div className="eliminar-guia-detail-container-admin">
            <h2 className="eliminar-guia-detail-title-admin">DETALLE DE LA GUÍA</h2>
            <p className="eliminar-guia-detail-subtitle-admin">
              Revisa la información detallada de la guía ingresada
            </p>
            <div className="eliminar-guia-search-bar-admin">
              <input
                type="text"
                value={detailTrackingNumber}
                onChange={handleDetailInputChange}
                placeholder="Ingrese guía"
                className="eliminar-guia-search-input-admin"
              />
              <button 
                onClick={handleFetchDetail} 
                className="eliminar-guia-search-button-admin"
              >
                Ver detalle
              </button>
            </div>
          </div>
        </div>
        <div className="eliminar-guia-tracking-container-admin">
          <div className="eliminar-guia-detail-container-admin">
            <h2 className="eliminar-guia-detail-title-admin">VERIFICAR PRE-ENVÍO</h2>
            <p className="eliminar-guia-detail-subtitle-admin">
              Verifica el estado de un pre-envío
            </p>
            <div className="eliminar-guia-search-bar-admin">
              <input
                type="text"
                value={verifyTrackingNumber}
                onChange={handleVerifyInputChange}
                placeholder="Ingrese guía"
                className="eliminar-guia-search-input-admin"
              />
              <select
                value={verifyCarrier}
                onChange={handleVerifyCarrierChange}
                className="eliminar-guia-carrier-select-admin"
              >
                <option value="interrapidisimo">Interrapidisimo</option>
                <option value="servientrega">Servientrega</option>
                <option value="coordinadora">Coordinadora</option>
                <option value="tcc">TCC</option>
              </select>
              <button 
                onClick={handleVerify}
                disabled={verifyLoading}
                className="eliminar-guia-search-button-admin"
              >
                {verifyLoading ? 'Verificando...' : 'Verificar'}
              </button>
            </div>
          </div>
        </div>
        <div className="eliminar-guia-tracking-container-admin">
          <div className="tracking-info">
            <img src="Images/finger.png" alt="Sigue tu envío" className="tracking-image" />
            <h2>Estado del envío</h2>
            <p>Información de rastreo de la guía</p>
            
            <div className="search-bar">
              <input
                type="text"
                value={rastreoNumber}
                onChange={handleRastreoInputChange}
                placeholder="Ingrese guía"
                className="search-input"
              />
              <select 
                value={rastreoCarrier} 
                onChange={handleRastreoCarrierChange} 
                className="carrier-select"
              >
                <option value="interrapidisimo">Interrapidisimo</option>
                <option value="servientrega">Servientrega</option>
                <option value="coordinadora">Coordinadora</option>
                <option value="tcc">TCC</option>
              </select>
              <button 
                onClick={handleRastreoSearch} 
                className="search-button"
                style={{ marginTop: "-20px", height: "45px" }}
              >
                <span className="material-icons">search</span>
              </button>
            </div>

            {error && <p className="error-message">{error}</p>}
            {trackingInfo && (
              <div className="tracking-results">
                <p><strong>Origen:</strong> {trackingInfo.origen}</p>
                <p><strong>Destino:</strong> {trackingInfo.destino}</p>
                <h3>Estados:</h3>
                <ul>
                  {Object.keys(trackingInfo.estados).map((key) => (
                    <li key={key}>
                      <p><strong>{trackingInfo.estados[key].nombre}</strong></p>
                      <p>{new Date(trackingInfo.estados[key].fecha).toLocaleString()}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="eliminar-guia-tracking-container-admin">
          <div className="eliminar-guia-detail-container-admin">
            <h2>INDEMNIZACIÓN PREENVÍO</h2>
           
            <div className="search-bar">
              <input
                type="text"
                value={indemTrackingNumberPreenvio}
                onChange={(e) => setIndemTrackingNumberPreenvio(e.target.value)}
                placeholder="Ingrese número de guía"
                className="search-input"
              />
              <select
                value={indemCarrierPreenvio}
                onChange={(e) => setIndemCarrierPreenvio(e.target.value)}
                className="carrier-select"
              >
                <option value="interrapidisimo">Interrapidisimo</option>
                <option value="servientrega">Servientrega</option>
                <option value="coordinadora">Coordinadora</option>
                <option value="tcc">TCC</option>
              </select>
              <button onClick={handleIndemnizacionPreenvio} className="search-button">
                Enviar
              </button>
            </div>
          </div>
        </div>

        <div className="eliminar-guia-tracking-container-admin">
          <div className="eliminar-guia-detail-container-admin">
            <h2>INDEMNIZACIÓN SERVICIO PREENVÍO</h2>
            <div className="search-bar">
              <input
                type="text"
                value={indemTrackingNumberServicio}
                onChange={(e) => setIndemTrackingNumberServicio(e.target.value)}
                placeholder="Ingrese número de guía"
                className="search-input"
              />
              <select
                value={indemCarrierServicio}
                onChange={(e) => setIndemCarrierServicio(e.target.value)}
                className="carrier-select"
              >
                <option value="interrapidisimo">Interrapidisimo</option>
                <option value="servientrega">Servientrega</option>
                <option value="coordinadora">Coordinadora</option>
                <option value="tcc">TCC</option>
              </select>
              <button onClick={handleIndemnizacionServicioPreenvio} className="search-button">
                Enviar
              </button>
            </div>
          </div>
        </div>
        
      </div>
      <Modal
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        footer={null}
        width={800}
      >
        {detailData && (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Preenvío" key="1">
              <div>
                <h3>Preenvío</h3>
                <p>ID: {detailData.preenvio.id}</p>
                <p>Estado: {detailData.preenvio.nombre_estado}</p>
                <p>ID estado: {detailData.preenvio.id_estado}</p>
                <p>Fecha de creación: {detailData.preenvio.fecha_creacion}</p>
                <p>Fecha de vencimiento: {detailData.preenvio.fecha_vencimiento}</p>
                <p>Valor flete: {detailData.preenvio.valor_flete}</p>
                <p>Valor declarado: {detailData.preenvio.valor_declarado}</p>
                <p>Valor consignado: {detailData.preenvio.valor_consignado}</p>
                <p>Observaciones: {detailData.preenvio.observaciones}</p>
                
              </div>
            </TabPane>
            <TabPane tab="Transportadora" key="2">
              <div>
                <h3>Transportadora</h3>
                <p>Nombre: {detailData.transportadora.nombre}</p>
                {/* ...muestra más detalles aquí... */}
              </div>
            </TabPane>
            <TabPane tab="Destinatario" key="3">
              <div>
                <h3>Destinatario</h3>
                <p>Nombre: {detailData.destinatario.primer_apellido} {detailData.destinatario.segundo_apellido}</p>
                {/* tipo_documento */}
                <p>Documento: {detailData.destinatario.tipo_documento}. {detailData.destinatario.numero_documento}</p>
                <p>Teléfono: {detailData.destinatario.telefono}</p>
                {/* correo */}
                <p>Correo: {detailData.destinatario.correo}</p>
                <p>Dirección: {detailData.destinatario.direccion}</p>
                <p>Ciudad: {detailData.destinatario.ciudad}</p>
                {/* id_ciudad */}
                <p>Id Ciudad: {detailData.destinatario.id_ciudad}</p>
               
                {/* ...muestra más detalles aquí... */}
              </div>
            </TabPane>
            <TabPane tab="Estado de Pago" key="4">
              <div>
                <h3>Estado de Pago</h3>
                <p>Estado: {detailData.estado_pago.nombre_pago}</p>
                <p>Fecha de pago: {detailData.estado_pago.fecha_pago}</p>
                {/* ...muestra más detalles aquí... */}
              </div>
            </TabPane>
            <TabPane tab="Sucursal" key="5">
              <div>
                <h3>Sucursal</h3>
                <p>ID Users: {detailData.sucursal.id_users}</p>
                <p>ID Administrador: {detailData.sucursal.id_administrador}</p>
                <p>Código Inter: {detailData.sucursal.codigo_inter}</p>
                <p>ID País: {detailData.sucursal.id_pais}</p>
                <p>Nombre: {detailData.sucursal.nombre_sucursal}</p>
                <p>Producto Sucursal: {detailData.sucursal.producto_sucursal}</p>
                <p>Cantidad: {detailData.sucursal.cantidad}</p>
                <p>Tipo Identificación: {detailData.sucursal.tipo_identificacion}</p>
                <p>Número Identificación: {detailData.sucursal.numero_identificacion}</p>
                <p>ID Nivel Crédito: {detailData.sucursal.id_nivel_credito}</p>
                <p>Recolección Activa: {detailData.sucursal.recoleccion_activa}</p>
                <p>Activo: {detailData.sucursal.activo}</p>
                <p>Fecha de Suscripción: {detailData.sucursal.fecha_suscripccion}</p>
                <p>Valor de Suscripción: {detailData.sucursal.valor_suscripccion}</p>
              </div>
            </TabPane>
          </Tabs>
        )}
      </Modal>
      <BotonWhatsapp />
    </>
  );
};

export default DesactivarGuia;