import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import axios from 'axios';

export const FormularioCreacionEnlistado = () => {
  const [form] = Form.useForm();
  const enlistmentTypes = [
    { id: 1, nombre: 'Pre Alistamiento' },
    { id: 2, nombre: 'Alistamiento' },
    { id: 3, nombre: 'Empaque' },
    { id: 4, nombre: 'Despacho' },
    { id: 6, nombre: 'Devoluciones' }
  ];

  useEffect(() => {
    // No need to fetch enlistment types from API anymore
  }, []);

  const onFinish = async (values) => {
    const userId = localStorage.getItem('usuario_id');
    try {
      const response = await axios.post(`https://99envios.app/api/picking/enlistment/save-enlistment/${userId}`, values);
      message.success('Enlistamiento guardado exitosamente');
      console.log('Response:', response.data);
    } catch (error) {
      message.error('Error al guardar el enlistamiento');
      console.error('Error saving enlistment:', error);
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', marginTop: '2%' }}
      >
        <Form.Item
          label="Tipo de Enlistamiento"
          name="tipo_enlistment"
          rules={[{ required: true, message: 'Por favor, selecciona el tipo de enlistamiento' }]}
        >
          <Select placeholder="Selecciona el tipo de enlistamiento">
            {enlistmentTypes.map(type => (
              <Select.Option key={type.id} value={type.id}>
                {type.nombre}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Correo"
          name="correo"
          rules={[{ required: true, message: 'Por favor, ingresa el correo electrónico', type: 'email' }]}
        >
          <Input placeholder="Ingresa tu correo electrónico" style={{ width: "290px" }} />
        </Form.Item>
        <Form.Item
          label="Contraseña"
          name="contrasena"
          rules={[{ required: true, message: 'Por favor, ingresa la contraseña' }]}
        >
          <Input.Password
            placeholder="Ingresa la contraseña"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#8c8cff', marginTop: '2%', height: '44px', width: '120px', marginLeft: '110px' }}>
            Registrar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormularioCreacionEnlistado;