import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Button, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import './estadoPedidos.css';
import moment from 'moment';

const EstadoPedidos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [enlistmentData, setEnlistmentData] = useState([]);
  const [multipleEnlistmentData, setMultipleEnlistmentData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [externalOrderData, setExternalOrderData] = useState(null);
  const usuario_id = localStorage.getItem('usuario_id');
  const [internalModalVisible, setInternalModalVisible] = useState(false);
  const [internalOrderData, setInternalOrderData] = useState(null);
  const [externalModalVisible, setExternalModalVisible] = useState(false);

  useEffect(() => {
    const fetchEnlistmentData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/picking/enlistment/listar-ordenes-enlistment/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: item.id,
          id_orden: item.id_orden,
          id_sucursal_bodega: item.id_sucursal_bodega,
          numero_preenvio: item.numero_preenvio,
          codigo_barras: item.codigo_barras,
          nombre_producto: item.nombre_producto,
          id_producto: item.id_producto,
          id_variacion: item.id_variacion,
          tipo_enlistment: item.tipo_enlistment,
          estado_enlistment: item.estado_enlistment,
          created_at: item.created_at,
          updated_at: item.updated_at,
          cantidad: item.cantidad,
          pdf: item.pdf,
          origen_externo: item.origen_externo,
          orden: item.orden
        }));
        setEnlistmentData(formattedData.filter(item => item.origen_externo === 0));
      } catch (error) {
        console.error('Error fetching enlistment data:', error);
      }
    };

    fetchEnlistmentData();
  }, [usuario_id]);

  useEffect(() => {
    const fetchMultipleEnlistmentData = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/picking/enlistment/listar-ordenes-enlistment/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: item.id,
          id_orden_externa: item.id_orden_externa,
          id_sucursal_bodega: item.id_sucursal_bodega,
          numero_preenvio: item.numero_preenvio,
          nombre_producto: item.nombre_producto,
          id_producto: item.id_producto,
          id_variacion: item.id_variacion,
          tipo_enlistment: item.tipo_enlistment,
          estado_enlistment: item.estado_enlistment,
          created_at: item.created_at,
          updated_at: item.updated_at,
          // Calculamos la suma total de cantidades de todos los productos en la orden
          cantidad: item.orden_externa?.detalles.reduce((sum, detalle) => sum + detalle.cantidad, 0) || 0,
          pdf: item.pdf,
          origen_externo: item.origen_externo,
          orden_externa: item.orden_externa,
        }));
        setMultipleEnlistmentData(formattedData.filter(item => item.origen_externo === 1));
      } catch (error) {
        console.error('Error fetching multiple enlistment data:', error);
      }
    };

    fetchMultipleEnlistmentData();
  }, [usuario_id]);

  const columnsEnlistment = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Orden',
      dataIndex: 'id_orden',
      key: 'id_orden',
    },
    {
      title: 'Número Preenvío',
      dataIndex: 'numero_preenvio',
      key: 'numero_preenvio',
    },
    {
      title: 'Tipo Enlistamiento',
      dataIndex: 'tipo_enlistment',
      key: 'tipo_enlistment',
      sorter: (a, b) => a.tipo_enlistment - b.tipo_enlistment,
      render: (value) => {
        switch (value) {
          case 1:
            return 'Pre Alistamiento';
          case 2:
            return 'Alistamiento';
          case 3:
            return 'Empaque';
          case 4:
            return 'Despacho';
          case 5:
            return 'Salida';
          case 6:
            return 'Devolución';
          case 7:
            return 'Devolución Aplicada';
          default:
            return value;
        }
      },
    },
    {
      title: 'Estado Enlistamiento',
      dataIndex: 'estado_enlistment',
      key: 'estado_enlistment',
      render: (value) => {
        switch (value) {
          case 0:
            return 'En Proceso';
          case 1:
            return 'Completado';
          default:
            return value;
        }
      },
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Fecha de Actualización',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      render: (value) => (
        value ? <Button onClick={() => window.open(value, '_blank')}>Abrir PDF</Button> : 'No disponible'
      ),
    },
    {
      title: 'Ver productos',
      key: 'ver_productos',
      render: (_, record) => (
        <Button onClick={() => showInternalModal(record)}>Ver productos</Button>
      ),
    },
  ];

  const columnsMultipleEnlistment = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'ID Orden Externa',
      dataIndex: 'id_orden_externa',
      key: 'id_orden_externa',
    },
    {
      title: 'Número Preenvío',
      dataIndex: 'numero_preenvio',
      key: 'numero_preenvio',
    },
    {
      title: 'Tipo Enlistamiento',
      dataIndex: 'tipo_enlistment',
      key: 'tipo_enlistment',
      sorter: (a, b) => a.tipo_enlistment - b.tipo_enlistment,
      render: (value) => {
        switch (value) {
          case 1:
            return 'Pre Alistamiento';
          case 2:
            return 'Alistamiento';
          case 3:
            return 'Empaque';
          case 4:
            return 'Despacho';
          case 5:
            return 'Salida';
          case 6:
            return 'Devolución';
          case 7:
            return 'Devolución Aplicada';
          default:
            return value;
        }
      },
    },
    {
      title: 'Estado Enlistamiento',
      dataIndex: 'estado_enlistment',
      key: 'estado_enlistment',
      render: (value) => {
        switch (value) {
          case 0:
            return 'En Proceso';
          case 1:
            return 'Completado';
          default:
            return value;
        }
      },
    },
    {
      title: 'Fecha de Creación',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Fecha de Actualización',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Ver productos',
      key: 'ver_productos',
      render: (_, record) => (
        <Button onClick={() => showExternalModal(record.orden_externa)}>Ver productos</Button>
      ),
    },
  ];

  const showInternalModal = (record) => {
    const formattedDetalles = record.orden?.detalles?.map((detalle, index) => {
      const matchingInventario = detalle.producto?.inventarios_bodega?.find(inv => inv.id_sucursal_bodega === record.id_sucursal_bodega) 
                                || detalle.producto?.inventarios_bodega?.[0];
      return {
        key: index,
        id: detalle.id_producto,
        nombre_producto: detalle.nombre_producto,
        cantidad: detalle.cantidad,
        precio_real: detalle.precio_real,
        precio_venta: detalle.precio_venta,
        sku: detalle.producto?.sku || 'No disponible',
        codigo_barras: matchingInventario?.codigo_barras || 'No disponible',
        cantidad_disponible: matchingInventario?.cantidad_disponible || 0,
      };
    }) || [];

    setInternalOrderData({ detalles: formattedDetalles });
    setInternalModalVisible(true);
  };

  const showExternalModal = (orden_externa) => {
    const formattedDetalles = orden_externa.detalles.map((detalle, index) => {
      const inventarioBodega = detalle.producto_externo.inventario.inventarios_bodega[0];
      return {
        key: index,
        id: detalle.id,
        nombre_producto: detalle.producto_externo.inventario.nombre_producto,
        cantidad: detalle.cantidad,
        sku: detalle.producto_externo.inventario.sku,
        codigo_barras: inventarioBodega?.codigo_barras || 'No disponible',
        precio_proveedor: detalle.producto_externo.inventario.precio_proveedor,
        precio_sugerido: detalle.producto_externo.inventario.precio_sugerido,
        cantidad_disponible: inventarioBodega?.cantidad_disponible || 0,
      };
    });
    setExternalOrderData({ detalles: formattedDetalles });
    setExternalModalVisible(true);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = enlistmentData.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    let result = enlistmentData;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredData(result);
  }, [searchText, enlistmentData]);

  return (
    <>
      <NavbarUsuarioBodega title="Estado Pedidos"/>
      <div className='misPedidos-picking'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="misPedidos-picking-tabla" style={{ overflowX: 'auto', marginTop: '16px' }}>
          <h2>Lista Órdenes Internas</h2>
          <Table
            columns={columnsEnlistment}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
        <div className="misPedidos-picking-tabla" style={{ overflowX: 'auto', marginTop: '16px' }}>
          <h2>Lista Órdenes Externas</h2>
          <Table
            columns={columnsMultipleEnlistment}
            dataSource={multipleEnlistmentData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            scroll={{ x: 1500 }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
      <Modal
        title="Productos de la Orden Interna"
        visible={internalModalVisible}
        onCancel={() => setInternalModalVisible(false)}
        footer={null}
        width={1000}
      >
        {internalOrderData && (
          <Table
            columns={[
              { title: 'ID Producto', dataIndex: 'id', key: 'id' },
              { title: 'Nombre Producto', dataIndex: 'nombre_producto', key: 'nombre_producto' },
              { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad' },
              { 
                title: 'Precio Real', 
                dataIndex: 'precio_real', 
                key: 'precio_real',
                render: value => `$${parseFloat(value).toLocaleString('es-CO')}`
              },
              { 
                title: 'Precio Venta', 
                dataIndex: 'precio_venta', 
                key: 'precio_venta',
                render: value => `$${parseFloat(value).toLocaleString('es-CO')}`
              },
              { title: 'SKU', dataIndex: 'sku', key: 'sku' },
              { title: 'Código de Barras', dataIndex: 'codigo_barras', key: 'codigo_barras' },
              { title: 'Stock Disponible', dataIndex: 'cantidad_disponible', key: 'cantidad_disponible' },
            ]}
            dataSource={internalOrderData.detalles}
            pagination={false}
          />
        )}
      </Modal>

      <Modal
        title="Productos de la Orden Externa"
        visible={externalModalVisible}
        onCancel={() => setExternalModalVisible(false)}
        footer={null}
        width={1000}
      >
        {externalOrderData && (
          <Table
            columns={[
              { title: 'ID', dataIndex: 'id', key: 'id' },
              { title: 'Nombre Producto', dataIndex: 'nombre_producto', key: 'nombre_producto' },
              { title: 'Cantidad', dataIndex: 'cantidad', key: 'cantidad' },
              { title: 'SKU', dataIndex: 'sku', key: 'sku' },
              { title: 'Código de Barras', dataIndex: 'codigo_barras', key: 'codigo_barras' },
              { 
                title: 'Precio Proveedor', 
                dataIndex: 'precio_proveedor', 
                key: 'precio_proveedor',
                render: value => `$${parseFloat(value).toLocaleString('es-CO')}`
              },
              { 
                title: 'Precio Sugerido', 
                dataIndex: 'precio_sugerido', 
                key: 'precio_sugerido',
                render: value => `$${parseFloat(value).toLocaleString('es-CO')}`
              },
              { title: 'Stock Disponible', dataIndex: 'cantidad_disponible', key: 'cantidad_disponible' },
            ]}
            dataSource={externalOrderData.detalles}
            pagination={false}
          />
        )}
      </Modal>
    </>
  );
};

export default EstadoPedidos;
