import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Card, message, Row, Col, Checkbox, Modal } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined, HomeOutlined, IdcardOutlined, UsergroupAddOutlined, ShopOutlined } from '@ant-design/icons';
import Ciudades from '../../ciudades/ciudades';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import "./login-top.css";

const { Option } = Select;

/**
 * Componente principal para gestionar el inicio de sesión y registro en la plataforma.
 * @component
 */
const LoginTopComponent = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nombreSucursal, setNombreSucursal] = useState('');
  const [buttonCooldown, setButtonCooldown] = useState(false);
  const location = useLocation(); // Añadir este hook
  const [form] = Form.useForm();

  /**
   * Hook de efecto para verificar si se debe mostrar el registro.
   * Se ejecuta una vez al montar el componente.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('showRegister') === 'true') {
      setIsLogin(false);
    }
  }, [location]);

  /**
   * Hook de efecto para obtener el nombre de la sucursal del usuario actual.
   * Se ejecuta una vez al montar el componente.
   */
  useEffect(() => {
    const fetchSucursal = async () => {
      const userId = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Asumiendo que necesitas el token para la autorización

      try {
        const response = await fetch(`https://99envios.app/api/online/sucursal/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Error al obtener la sucursal');
        }
        const data = await response.json();
        setNombreSucursal(data.nombre_sucursal);
      } catch (error) {
        console.error('Error al obtener la sucursal:', error);
      }
    };

    fetchSucursal();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const navigate = useNavigate();

  /**
   * Cambia entre el formulario de inicio de sesión y el de registro.
   */
  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  /**
   * Inicia el tiempo de espera para el botón.
   */
  const startButtonCooldown = () => {
    setButtonCooldown(true);
    setTimeout(() => {
      setButtonCooldown(false);
    }, 2000);
  };

  /**
   * Maneja el envío del formulario de inicio de sesión.
   * @param {Object} values - Valores del formulario.
   */
  const handleLogin = async (values) => {
    if (buttonCooldown) return;
    startButtonCooldown();

    try {
      const response = await axios.post('https://api.99envios.app/api/auth/login', {
        email: values.username,
        password: values.password,
      });

      const now = new Date();
      const expirationTime = new Date(now.getTime() + 6 * 3600 * 1000);

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('usuario_id', response.data.user.id);
      localStorage.setItem('rol_id', response.data.user.id_rol);
      localStorage.setItem('email', response.data.user.email);

      if (response.data.sucursales.length > 0) {
        localStorage.setItem('nombre', response.data.sucursales[0].nombre);
        localStorage.setItem('codigoSucursal', response.data.sucursales[0].codigo_sucursal);
      } else {
        message.warning('No se encontraron sucursales asociadas a este usuario.');
      }

      message.success(`¡Inicio de sesión exitoso para ${nombreSucursal}!`);

      // Redirección basada en id_rol
      if (response.data.user.id_rol === 1) {
        navigate('/dashboardAdmin');
      } else if (response.data.user.id_rol === 3) {
        navigate('/dashboardMex/');
      } else if (response.data.user.id_rol === 9) {
        navigate('/picking/envios-entrantes');
      } else if (response.data.user.id_rol === 10) {
        navigate('/picking/pedidos-enlistador');
      }
    } catch (error) {
      message.error('Error al iniciar sesión. Por favor, verifica tus credenciales.');
      console.error('Error al iniciar sesión:', error);
    }
  };

  /**
   * Formulario de inicio de sesión.
   */
  const LoginTopForm = () => (
    <Form name="login-top-form" onFinish={handleLogin} className="login-top-form">
      <p className='info-ingreso-datos'>Ingrese su correo y clave registrados en la plataforma online</p>
      <Form.Item name="username" rules={[{ required: true, message: 'Por favor ingrese su correo' }]}>
        <Input prefix={<UserOutlined />} placeholder="Correo" className="login-top-input" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Por favor ingrese su contraseña' }]}>
        <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" className="login-top-input" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block className="login-top-button">
          Entrar
        </Button>
      </Form.Item>
      <Form.Item>
        <a href="https://api.99envios.app/formulario-recuperar-contrasenia" className="login-top-link">¿Requiere una nueva contraseña?</a>
      </Form.Item>
    </Form>
  );

  /**
   * Maneja el envío del formulario de registro.
   * @param {Object} values - Valores del formulario.
   */
  const handleRegister = async (values) => {
    if (buttonCooldown) return;
    startButtonCooldown();
    // Verifica directamente el valor de "terms" desde los valores del formulario
    if (!values.terms) {
      message.error('Debe aceptar los términos y condiciones para registrarse.');
      return;
    }

    try {
      const registerData = {
        nombre_apellidos: values.fullName,
        tipo_identificacion: values.idType,
        num_identificacion: values.idNumber,
        direccion: values.address,
        pais: values.country,
        ciudad_id: values.city,
        telefono: values.phone,
        correo: values.email,
        contrasena: values.password,
        nombre_sucursal: values.branchName,
        terminos_condiciones: values.terms ? 1 : 0
      };

      const response = await axios.post('https://api.99envios.app/api/online/sucursal', registerData);

      if (response.data.success) {
        message.success('Registro exitoso. Por favor, inicia sesión.');
        setIsLogin(true);
      } else {
        message.error('Error en el registro. Por favor, intenta de nuevo.');
      }
    } catch (error) {
      message.error('Error en el registro. Por favor, verifica tus datos e intenta de nuevo.');
      console.error('Error al registrar:', error);
    }
  };

  const CustomCheckbox = ({ checked, onChange, showModal }) => (
    <div 
      className="custom-checkbox-container"
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        userSelect: 'none',
        padding: '8px 16px',
        border: '2px solid #1890ff',
        borderRadius: '6px',
        backgroundColor: checked ? '#1890ff' : 'white',
        color: checked ? 'white' : '#1890ff',
        transition: 'all 0.3s',
        fontWeight: '500',
        marginBottom: '20px',
        fontSize: '12px' // Reduce font size
      }}
    >
      <div 
        onClick={() => onChange({ target: { checked: !checked } })}
        style={{
          width: '20px',
          height: '20px',
          border: checked ? '2px solid white' : '2px solid #1890ff',
          borderRadius: '4px',
          marginRight: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: checked ? '#1890ff' : 'white',
          cursor: 'pointer' // Make only the box clickable
        }}
      >
        {checked && (
          <svg 
            viewBox="0 0 24 24" 
            width="14" 
            height="14"
            style={{ fill: 'white' }}
          >
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
          </svg>
        )}
      </div>
      <span>
        Aceptar{' '}
        <span onClick={showModal} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          términos y condiciones
        </span>
      </span>
    </div>
  );

  /**
   * Formulario de registro.
   */
  const RegisterTopForm = () => (
    <Form name="register-top-form" onFinish={handleRegister} className="register-top-form" form={form}>
      <Form.Item name="fullName" rules={[{ required: true, message: 'Por favor ingrese su nombre y apellidos' }]}>
        <Input prefix={<UserOutlined />} placeholder="Nombre y apellidos" className="login-top-input" />
      </Form.Item>
      <Row gutter={8} align="middle">
        <Col span={8}>
          <Form.Item name="idType" initialValue="CC" rules={[{ required: true, message: 'Por favor seleccione el tipo de identificación' }]}>
            <Select placeholder="Tipo ID">
              <Option value="CC">CC</Option>
              <Option value="CE">CE</Option>
              <Option value="RUT">RUT</Option>
              <Option value="NIT">NIT</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item name="idNumber" rules={[{ required: true, message: 'Por favor ingrese su número de identificación' }]}>
            <Input prefix={<IdcardOutlined />} placeholder="Número identificación" className="id-number-input" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="address" rules={[{ required: true, message: 'Por favor ingrese su dirección' }]}>
        <Input prefix={<HomeOutlined />} placeholder="Escribe tu dirección" className="login-top-input" />
      </Form.Item>
      <Form.Item name="country" initialValue="1" rules={[{ required: true, message: 'Por favor seleccione su país' }]}>
        <Select placeholder="Selecciona tu país">
          <Option value="1">Colombia</Option>
        </Select>
      </Form.Item>
      <Form.Item name="city" rules={[{ required: true, message: 'Por favor seleccione su ciudad' }]}>
        <Select showSearch
          placeholder="Selecciona una ciudad"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }>
          {Ciudades.map((ciudad) => (
            <Option key={ciudad.value} value={ciudad.value}>
              {ciudad.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="phone" rules={[{ required: true, message: 'Por favor ingrese su teléfono' }]}>
        <Input prefix={<PhoneOutlined />} placeholder="Teléfono" className="login-top-input" />
      </Form.Item>
      <Form.Item name="branchName" rules={[{ required: true, message: 'Por favor ingrese el nombre de la sucursal' }]}>
        <Input prefix={<HomeOutlined />} placeholder="Nombre Tienda" className="login-top-input" />
      </Form.Item>
      <Form.Item name="referralCode">
        <Input prefix={<UsergroupAddOutlined />} placeholder="Código de referido" className="login-top-input" />
      </Form.Item>
      <Form.Item name="sellerCode">
        <Input prefix={<ShopOutlined />} placeholder="Código de vendedor" className="login-top-input" />
      </Form.Item>
      <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Por favor ingrese un correo válido' }]}>
        <Input prefix={<MailOutlined />} placeholder="Correo" className="login-top-input" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Por favor ingrese su contraseña' },
          { min: 6, message: 'La contraseña debe tener al menos 6 caracteres' }
        ]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Contraseña"
          className="login-top-input"
        />
      </Form.Item>
      <Form.Item
        name="terms"
        valuePropName="checked"
        rules={[{ validator: (_, value) => value ? Promise.resolve() : Promise.reject('Debe aceptar los términos y condiciones para registrarse') }]}
      >
        <CustomCheckbox
          checked={form.getFieldValue('terms')}
          onChange={(e) => form.setFieldsValue({ terms: e.target.checked })}
          showModal={showModal}
        />
      </Form.Item>

      <Modal
        title="Términos y Condiciones"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <p><strong>TÉRMINOS Y CONDICIONES PROVEEDORES (SELLERS) EN USO DE LA PÁGINA WEB 99ENVIOS.COM</strong></p>

          <p><strong>GENERALES</strong></p>
          <p>Este documento describe los términos y condiciones generales del servicio prestado por I LOVE GROUP S.A.S., identificada con el número de NIT. 901.217.397-1, a través de su plataforma web 99envios.com, mediante el cual personas naturales o jurídicas, en adelante “Proveedores o Sellers”, podrán comercializar productos y servicios, a través del portal web en mención. Adicionalmente la señalada I LOVE GROUP S.A.S., prestará labores de MANDATO COMERCIAL (Artc 1263 del código de comercio), consistente en la GESTIÓN DE ENVIOS, RECAUDO Y PAGOS, derivados de la celebración de las relaciones de consumo, entre el usuario final (Consumidor) y el Proveedor (Seller). Además, CREACION DE CAMPAÑAS Y PAUTAS PUBLICITARIAS, los cuales se indicarán más adelante.</p>

          <p>Mediante el registro y uso de 99envios.com, el proveedor (Seller) manifiesta tener la capacidad legal para suscribir acuerdos comerciales, tanto con I LOVE GROUP S.A.S., como con terceros y para aceptar los términos y condiciones aquí descritos, su alcance legal y operativo. Quien registre un usuario como empresa, deberá tener capacidad para contratar o estar autorizado por esta. En el evento de no estar de acuerdo con todo o parte de estos términos y condiciones, el Proveedor (Seller), deberá abstenerse de generar el uso de la mentada plataforma web. Por lo tanto, I LOVE GROUP S.A.S., se reserva el derecho de admisión y permanencia de los proveedores (Sellers) registrados en 99envios.com.</p>

          <p><strong>CLÁUSULAS</strong></p>

          <p><strong>PRIMERA – DEFINICIONES.</strong></p>
          <p>1.1. Para efectos del presente contrato, los términos definidos a continuación tendrán el significado que aquí se describe, independientemente que se mencionen en mayúsculas fijas o simplemente con mayúscula inicial, con o sin negrilla:</p>

          <p><strong>Guía Nacional y/o Guía de Transporte:</strong> Es el documento con número único virtual que ampara cada uno de los envíos.</p>
          <p><strong>Consumidor final y/o Destinatario:</strong> significa la persona natural o jurídica que adquiera un producto para la satisfacción de una necesidad propia, privada, familiar o doméstica y empresarial cuando no esté ligada intrínsecamente a su actividad económica.</p>
          <p><strong>Contrato:</strong> Será el presente documento.</p>
          <p><strong>Formato de codificación de Producto:</strong> Documento en el cual se relacionará toda la información de los artículos de propiedad del proveedor (seller) que serán comercializados virtualmente a través de la página web 99envios.com/, tales como de manera enunciativa más no limitativa a: Nombre, Referencia, descripción del producto, nombre del Proveedor (seller), colores, precios, código de barras, líneas, peso, tamaño, etc.</p>
          <p><strong>Cobertura:</strong> Es el conjunto de ciudades donde El Operador, presta el servicio de entrega de envíos, con cobro de efectivo.</p>
          <p><strong>Valor a Cobrar o Recaudar:</strong> Valor de la mercancía y el flete indicado para cobrar al destinatario o comprador en el momento de la entrega.</p>
          <p><strong>Proveedor (Seller):</strong> Es la persona natural o jurídica que contrata los servicios de I LOVE GROUP S.A.S, para el uso de su plataforma web 99envios.com/, con el fin de encontrar, comparar, seleccionar y adquirir servicios de mensajería a nivel nacional, usando como intermediarios operadores logísticos aliados.</p>
          <p><strong>Operador Logístico:</strong> Entidad aliada que presta el servicio de logística y transporte.</p>
          <p><strong>Publicidad en Redes Sociales:</strong> Creación de publicidad en redes sociales, la cual permite crear anuncios en social media.</p>
          <p><strong>Garantía:</strong> Tiene el significado que se le asigna en la cláusula vigésima (20) del presente documento.</p>
          <p><strong>Ley Aplicable:</strong> Leyes, decretos, ordenanzas, normas, órdenes o regulaciones de nivel nacional, departamental, municipal y/o distrital o de cualquier otro nivel, incluyendo cualquier regulación expedida por una Autoridad Gubernamental.</p>
          <p><strong>Sitio Web:</strong> es 99envios.com</p>

          <p><strong>SEGUNDA – OBJETO</strong></p>
          <p>Mediante “99envios.com”, se desarrolla la comercialización de productos y/o servicios de diferentes Proveedores (Sellers) o proveedores nacionales y/o internacionales, los cuales los clientes podrán adquirir de manera electrónica y recibirlos mediante los distintos medios que dispone este portal web.</p>

          <p>La relación contractual que se establece en las presentes condiciones es equiparable a la compraventa y por ende se regirá por las disposiciones legales que rigen la materia, como las de protección al consumidor (ley 1480 de 2011).</p>

          <p><strong>TERCERA – DURACIÓN.</strong></p>
          <p>3.1. El presente contrato tendrá una duración indefinida, o hasta que el proveedor (seller) abandone permanentemente el uso de la plataforma web 99envios.com o en su defecto, por el bloqueo del usuario.</p>

          <p>3.3. En el evento de terminación del contrato por cualquier eventualidad, las partes procederán a realizar la liquidación del contrato, realizando la respectiva conciliación, de conformidad a lo dispuesto en la cláusula trigésima séptima, para establecer los montos a cancelar por cada una de las partes.</p>

          <p>3.4. Las condiciones de comercialización de los productos del Proveedor (Seller) por parte de I LOVE GROUP S.A.S., contenidas en el Acuerdo para el Suministro de Bienes (Anexo1), entran en vigor a partir del primero (1) de enero de cada año, independientemente de la fecha de suscripción del contrato.</p>

          <p>3.5. De llevarse a cabo la comercialización de los productos previo a la firma del acuerdo de suministro de bienes y el contrato, dichas ventas estarán sometidas a las condiciones que acuerden las partes tanto en el acuerdo como en el contrato.</p>

          <p><strong>CUARTA – ALCANCE DE LOS SERVICIOS</strong></p>
          <p>99envios.com, prestará los siguientes servicios:</p>

          <p>4.1. Publicación de los productos: 99envios.com publicará los productos precargados por el proveedor (Sellers), siempre que cumplan con las condiciones descritas en el acápite “Funcionamiento de 99envios.com”.</p>

          <p>4.2. Recaudo del valor de los productos: 99envios.com prestará el servicio a cuenta y nombre del Proveedor (Seller), quien desde ya acepta que I LOVE GROUP S.AS., reciba el pago de los productos mediante los mecanismos que este disponga (pasarelas de pago, PSE, NEQUI, DAVI PLATA, o efectivo mediante el sistema pago en casa).</p>

          <p>4.3. Transporte de los productos: El Proveedor (Seller), posterior a la comercialización de sus productos, en uso de sus propias estrategias comerciales o en uso de la plataforma web 99envios.com, este elegirá de nuestras empresas logísticas aliadas, siendo estas (INTER RAPIDISIMO, SERVIENTREGA, COORDINADORA Y ENVIA, la que prestará los servicios de transporte, entrega y cobro del dinero producto de la compra en línea realizada por el CONSUMIDOR FINAL en uso de la plataforma virtual 99envios.com.</p>

          <p>4.4. Postventa: El Proveedor (Seller) atenderá los clientes y/o consumidores que hayan realizado compras para efectos de cambios, devoluciones, retractos, garantías, etc.</p>

          <p>4.5. Logística Inversa: La empresa de logística elegida por el Proveedor (seller) que prestará el servicio de transporte, dispondrá de su red de agencias, transportadora y mensajeros, a fin de suplir las necesidades del proceso de devoluciones de productos que se generen en las ventas realizadas por medio de la plataforma virtual 99envios.com.</p>

          <p>4.6. Publicidad: 99envios.com dispondrá de espacios adicionales dentro de su plataforma, en los cuales los Proveedores (sellers) podrán publicitar sus productos, los cuales tendrán un costo adicional.</p>

        </div>
      </Modal>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          className="login-top-button"
          disabled={buttonCooldown}
        >
          {buttonCooldown ? 'Espere 2 segundos...' : 'Registrarse'}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <div className="login-top-wrapper">
      <div className={`login-top-container ${isLogin ? 'login-mode' : 'register-mode'}`}>
        <div className="login-top-section">
          <Card
            title={isLogin ? "Iniciar Sesión" : "Registrarse"}
            className="login-top-card"
          >
            {isLogin ? <LoginTopForm /> : <RegisterTopForm />}
          </Card>
        </div>
        <div className="register-top-section">
          <h2>{isLogin ? "¿Aún no tienes una cuenta?" : "¿Ya tienes una cuenta?"}</h2>
          <p>{isLogin ? "Regístrate para que puedas iniciar sesión" : "Inicia sesión para entrar en la página"}</p>
          <Button onClick={toggleForm} className="login-top-button">
            {isLogin ? "Registrarse" : "Iniciar Sesión"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginTopComponent;