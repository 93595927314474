import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './personalPicking.css';
import NavbarUsuarioBodega from '../navbarUsuarioBodega';
import { utils, writeFile } from 'xlsx';
import { Modal, Button, message } from 'antd';
import { FormularioCreacionEnlistado } from '../formularioCreacionEnlistador/formularioCreacionEnlistador';

const PersonalPicking = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const userId = localStorage.getItem('usuario_id');

  useEffect(() => {
    axios.get(`https://99envios.app/api/picking/enlistment/listar-enlistment/${userId}`)
      .then(response => {
        const enlistments = response.data.map(enlistment => ({
          id: enlistment.id,
          email: enlistment.email, // Changed from id_sucursal_bodega to email
          id_enlistment: enlistment.id_enlistment,
          tipo_enlistment: enlistment.tipo_enlistment,
          nombre_enlistment: enlistment.nombre_enlistment
        }));
        setData(enlistments);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, [userId]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    (item.id?.toString() || '').includes(search) ||
    (item.email?.toString() || '').includes(search) || // Changed from id_sucursal_bodega to email
    (item.id_enlistment?.toString() || '').includes(search) ||
    (item.tipo_enlistment?.toString() || '').includes(search) ||
    (item.nombre_enlistment?.toLowerCase() || '').includes(search.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  if (currentPage > totalPages && totalPages > 0) {
    setCurrentPage(totalPages);
  }

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginationRange = () => {
    const totalNumbers = 5;
    const totalBlocks = totalNumbers + 2;
  
    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - 2);
      const endPage = Math.min(totalPages - 1, currentPage + 2);
      let pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  
      if (startPage > 2) {
        pages = ['...', ...pages];
      }
      if (endPage < totalPages - 1) {
        pages = [...pages, '...'];
      }
      return [1, ...pages, totalPages];
    }
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return dateString; // Return original if invalid
    return date.toISOString().split('T')[0];
  };

  const exportToExcel = () => {
    // Crear una copia de los datos filtrados excluyendo la columna pdf
    const exportData = filteredData.map(item => {
      const { pdf, ...rest } = item;
      return rest;
    });

    const worksheet = utils.json_to_sheet(exportData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Envíos Completos");

    writeFile(workbook, `Tabla_Usuarios_${formatDateForDisplay(new Date())}.xlsx`);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const confirmDelete = () => {
    axios.post(`https://99envios.app/api/picking/enlistment/delete-enlistment/${userId}`, {
      id_enlistment: deleteId
    })
    .then(() => {
      setData(data.filter(item => item.id_enlistment !== deleteId));
      setShowModal(false);
      setDeleteId(null);
      message.success("Enlistment eliminado exitosamente.");
    })
    .catch(error => console.error('Error deleting data: ', error));
  };

  return (
    <>
      <NavbarUsuarioBodega title="Personal Picking" />
      <div className="table-container-novedades-admin">
        <div className="export-group">
          <Button type="primary" onClick={() => setShowCreateModal(true)} style={{ marginBottom: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>Crear Enlistador</Button>
          <button onClick={exportToExcel} className="export-button" style={{ marginLeft: '10px' }}>Exportar a Excel</button>
        </div>
        <div className="table-controls">
          <div className="controls-group">
            <label htmlFor="entries">Mostrar</label>
            <select
              id="entries"
              name="entries"
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>

            <input
              type="text"
              placeholder="Buscar"
              value={search}
              onChange={handleSearch}
              className="search-input"
            />
          </div>

          
        </div>
        <table className="custom-table-novedades-admin">
          <thead>
            <tr>
              <th onClick={() => handleSort('id')}>ID</th>
              <th onClick={() => handleSort('email')}>Correo</th> {/* Changed from id_sucursal_bodega to email */}
              <th onClick={() => handleSort('id_enlistment')}>ID Enlistment</th>
              <th onClick={() => handleSort('tipo_enlistment')}>Tipo Enlistment</th>
              <th onClick={() => handleSort('nombre_enlistment')}>Nombre Enlistment</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.email}</td> {/* Changed from id_sucursal_bodega to email */}
                <td>{item.id_enlistment}</td>
                <td>{item.tipo_enlistment}</td>
                <td>{item.nombre_enlistment}</td>
                <td>
                  <button onClick={() => handleDelete(item.id_enlistment)} className="delete-button">Eliminar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-novedades-admin">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {paginationRange().map((page, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === page ? 'active' : ''}`}
              onClick={() => typeof page === 'number' && setCurrentPage(page)}
              disabled={typeof page !== 'number'}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Siguiente
          </button>
        </div>
      </div>

      <Modal
        title="Crear Enlistador"
        visible={showCreateModal}
        onCancel={() => setShowCreateModal(false)}
        footer={null}
      >
        <FormularioCreacionEnlistado />
      </Modal>

      <Modal
        title="Confirmar Eliminación"
        visible={showModal}
        onOk={confirmDelete}
        onCancel={() => setShowModal(false)}
        okText="Confirmar"
        cancelText="Cancelar"
        okButtonProps={{ className: 'delete-button modal-button' }}
        cancelButtonProps={{ className: 'modal-button' }}
      >
        <p>¿Estás seguro de que deseas eliminar este enlistment?</p>
      </Modal>
    </>
  );
};

export default PersonalPicking;