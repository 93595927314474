import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import './whatsappAdmin.css';

const WhatsappAdmin = () => {
  const [messageValues, setMessageValues] = useState({
    valor_mensaje_envio: 0,
    valor_mensaje_respuesta: 0
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const fetchMessageValues = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://99envios.app/api/online/whatsapp/valor-mensaje-consultar/view');
      setMessageValues(response.data.data);
      setError(null);
    } catch (err) {
      setError('Error al cargar los valores de mensajes');
      console.error('Error fetching message values:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://99envios.app/api/online/whatsapp/valor-mensaje/update', messageValues);
      setSuccess('Valores actualizados correctamente');
      setError(null);
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError('Error al actualizar los valores');
      console.error('Error updating message values:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMessageValues(prev => ({
      ...prev,
      [name]: Number(value)
    }));
  };

  useEffect(() => {
    fetchMessageValues();
  }, []);

  return (
    <>
      <NavbarAdminMex title="Panel de Control WhatsApp" />
      <div className="whatsapp-admin-container">
        {loading && <div className="loading-spinner"></div>}
        {error && <div className="error-message">
          <i className="material-icons-sharp">error</i>
          {error}
        </div>}
        {success && <div className="success-message">
          <i className="material-icons-sharp">check_circle</i>
          {success}
        </div>}
        
        <div className="whatsapp-dashboard">
          <div className="whatsapp-stats-container">
            <div className="whatsapp-stat-card">
              <i className="material-icons-sharp">send</i>
              <div className="stat-info">
                <h3>Valor Mensaje Envío</h3>
                <p className="stat-value">${messageValues.valor_mensaje_envio}</p>
              </div>
            </div>
            <div className="whatsapp-stat-card">
              <i className="material-icons-sharp">question_answer</i>
              <div className="stat-info">
                <h3>Valor Mensaje Respuesta</h3>
                <p className="stat-value">${messageValues.valor_mensaje_respuesta}</p>
              </div>
            </div>
          </div>

          <div className="whatsapp-values-card">
            <div className="card-header">
              <i className="material-icons-sharp">settings</i>
              <h2>Configuración de Valores WhatsApp</h2>
            </div>
            
            <div className="input-groups-container">
              <div className="input-group">
                <label>
                  <i className="material-icons-sharp">send</i>
                  Valor Mensaje Envío
                </label>
                <div className="input-wrapper">
                  <span className="currency-symbol">$</span>
                  <input
                    type="number"
                    name="valor_mensaje_envio"
                    value={messageValues.valor_mensaje_envio}
                    onChange={handleInputChange}
                    placeholder="Ingrese el valor"
                  />
                </div>
              </div>

              <div className="input-group">
                <label>
                  <i className="material-icons-sharp">question_answer</i>
                  Valor Mensaje Respuesta
                </label>
                <div className="input-wrapper">
                  <span className="currency-symbol">$</span>
                  <input
                    type="number"
                    name="valor_mensaje_respuesta"
                    value={messageValues.valor_mensaje_respuesta}
                    onChange={handleInputChange}
                    placeholder="Ingrese el valor"
                  />
                </div>
              </div>
            </div>

            <button 
              className="update-button"
              onClick={handleUpdate}
              disabled={loading}
            >
              <i className="material-icons-sharp">save</i>
              {loading ? 'Actualizando...' : 'Actualizar Valores'}
            </button>
          </div>

          <div className="whatsapp-info-card">
            <div className="card-header">
              <i className="material-icons-sharp">info</i>
              <h3>Información Importante</h3>
            </div>
            <ul>
              <li><i className="material-icons-sharp">check_circle</i> Los valores se actualizan periodicamente</li>
              <li><i className="material-icons-sharp">security</i> Cambios seguros y respaldados</li>
              <li><i className="material-icons-sharp">history</i> Historial de cambios disponible</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatsappAdmin;
